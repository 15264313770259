import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
	palette: {
		background: {
			default: '#eee',
		},
		primary: {
			main: '#0288D1',
		},
		secondary: {
			main: '#8AC926',
		},
		error: {
			main: '#D32F2F',
		},
		warning: {
			main: '#ED6C02',
		},
		info: {
			main: '#6A4C93',
		},
		success: {
			main: '#2E7D32',
		},
	},
});

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#0288D1',
		},
		secondary: {
			main: '#8AC926',
		},
		error: {
			main: '#D32F2F',
		},
		warning: {
			main: '#ED6C02',
		},
		info: {
			main: '#6A4C93',
		},
		success: {
			main: '#2E7D32',
		},
	},
});
