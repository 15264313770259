import {
	Application,
	ApplicationRubric,
	InterviewRubric,
	InterviewSlot,
	Organization,
	OrganizationSettings,
	ReviewRubric,
	User,
} from 'API';
import { AlertState } from 'store/sub-states/general_state';
import { UserCategories } from 'types/user_types';
import { ActionStrings } from './action_strings';

export const Actions = {
	Application: {
		SetApplication: (payload: Application) => ({
			type: ActionStrings.Application.SetApplication,
			payload: payload,
		}),
	},
	General: {
		SetAlert: (payload: AlertState) => ({
			type: ActionStrings.General.SetAlert,
			payload: payload,
		}),
		SetDarkMode: (payload: boolean) => ({
			type: ActionStrings.General.SetDarkMode,
			payload: payload,
		}),
		SetGlobalLoading: (payload: boolean) => ({
			type: ActionStrings.General.SetGlobalLoading,
			payload: payload,
		}),
		SetRefreshing: (payload: boolean) => ({
			type: ActionStrings.General.SetRefreshing,
			payload: payload,
		}),
	},
	Interviews: {
		AddInterviewSlot: (interviewSlot: InterviewSlot) => ({
			type: ActionStrings.Interviews.AddInterviewSlot,
			payload: interviewSlot,
		}),
		RemoveInterviewSlot: (interviewSlot: InterviewSlot) => ({
			type: ActionStrings.Interviews.RemoveInterviewSlot,
			payload: interviewSlot,
		}),
		SetInterviewSlots: (interviewSlots: InterviewSlot[]) => ({
			type: ActionStrings.Interviews.SetInterviewSlots,
			payload: interviewSlots,
		}),
		ToggleHideOldInterviews: (value: boolean) => ({
			type: ActionStrings.Interviews.ToggleHideOldInterviews,
			payload: value,
		}),
		UpdateInterviewSlot: (interviewSlot: InterviewSlot) => ({
			type: ActionStrings.Interviews.UpdateInterviewSlot,
			payload: interviewSlot,
		}),
	},
	Organization: {
		SetApplicationRubric: (rubric: ApplicationRubric) => ({
			type: ActionStrings.Organization.SetApplicationRubric,
			payload: rubric,
		}),
		SetInterviewRubric: (rubric: InterviewRubric) => ({
			type: ActionStrings.Organization.SetInterviewRubric,
			payload: rubric,
		}),
		SetReviewRubric: (rubric: ReviewRubric) => ({
			type: ActionStrings.Organization.SetReviewRubric,
			payload: rubric,
		}),
		SetOrganizationSettings: (rubric: OrganizationSettings) => ({
			type: ActionStrings.Organization.SetReviewRubric,
			payload: rubric,
		}),
		SetOrganization: (org: Organization) => ({
			type: ActionStrings.Organization.SetOrganization,
			payload: org,
		}),
	},
	User: {
		Logout: () => ({ type: ActionStrings.User.Logout, payload: undefined }),
		SetCognitoID: (id: string) => ({ type: ActionStrings.User.SetCognitoID, payload: id }),
		SetEmail: (email: string) => ({ type: ActionStrings.User.SetEmail, payload: email }),
		SetName: (name: string) => ({ type: ActionStrings.User.SetName, payload: name }),
		SetOrganization: (id: string) => ({
			type: ActionStrings.User.SetOrganization,
			payload: id,
		}),
		SetType: (type: UserCategories) => ({ type: ActionStrings.User.SetType, payload: type }),
		SetUser: (user: User) => ({ type: ActionStrings.User.SetUser, payload: user }),
	},
};
