import { Button, Grid } from '@mui/material';
import { DisplayField } from 'components/form_builder/display_field';
import { FormBuilderAnswer, FormBuilderField } from 'components/form_builder/form_builder';
import React from 'react';
import { useState } from 'react';

interface GenericFormProps {
	template: string;
	current?: string;
	handleSave?: (form: string) => void;
	handleSubmit?: (form: string) => void;
}

const createFormFromTemplate = (
	template: FormBuilderField[],
	currentData: FormBuilderAnswer[]
): Record<number, FormBuilderAnswer> => {
	return template.reduce(
		(prev, curr) => ({
			...prev,
			[curr.order]: {
				id: curr.order,
				field: curr,
				response: currentData.find((answer) => answer.id === curr.order)?.response ?? '',
			},
		}),
		{}
	);
};

const GenericForm: React.FC<GenericFormProps> = ({
	template,
	current,
	handleSave,
	handleSubmit,
}) => {
	const formTemplate: FormBuilderField[] =
		template && template !== '' ? JSON.parse(template) : [];
	const currentData: FormBuilderAnswer[] = current && current !== '' ? JSON.parse(current) : [];
	const [formAnswers, setFormAnswers] = useState(
		createFormFromTemplate(formTemplate, currentData)
	);

	const saveFieldAnswer = (id: number, response: string) => {
		const field = formAnswers[id];
		setFormAnswers({ ...formAnswers, [id]: { ...field, response: response } });
	};

	// TODO: work on these form answers
	return (
		<>
			<Grid
				container
				style={{ width: '100%' }}
				direction="column"
				justifyContent="center"
				alignItems="center">
				{formTemplate
					.sort((field) => field.order)
					.map((field, i) => {
						return (
							<React.Fragment key={i}>
								<Grid sx={{ minWidth: '50em', m: 2 }}>
									<DisplayField
										field={field}
										current={currentData.find(
											(answer) => answer.id === field.order
										)}
										saveAnswer={saveFieldAnswer}
									/>
								</Grid>
							</React.Fragment>
						);
					})}
			</Grid>
			{(handleSave || handleSubmit) && (
				<Grid container direction="row" justifyContent="center" alignItems="center">
					{handleSave && (
						<Button
							sx={{ m: 3 }}
							variant="outlined"
							color="primary"
							onClick={() => handleSave(JSON.stringify(Object.values(formAnswers)))}>
							Save
						</Button>
					)}
					{handleSubmit && (
						<Button
							sx={{ m: 3 }}
							variant="contained"
							color="primary"
							onClick={() =>
								handleSubmit(JSON.stringify(Object.values(formAnswers)))
							}>
							Submit
						</Button>
					)}
				</Grid>
			)}
		</>
	);
};

export { GenericForm };
