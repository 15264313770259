import React from 'react';
import { DesktopPublicNavBar } from './desktop_public_navbar';

const PublicNavBar: React.FC = () => {
	return (
		<>
			<DesktopPublicNavBar />
		</>
	);
};

export { PublicNavBar };
