import { Button } from '@mui/material';
import { Team } from 'API';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { Loading } from 'components/loading/loading';
import React, { useEffect, useState } from 'react';
import { getTeams } from 'services/team_service';

const TeamsPage: React.FC = () => {
	const [loadingTeams, setLoadingTeams] = useState(false);
	const [teams, setTeams] = useState<Team[]>([]);

	useEffect(() => {
		(async () => {
			setLoadingTeams(true);
			const currentTeams = await getTeams();
			setTeams(currentTeams);
			setLoadingTeams(false);
		})();
	}, []);

	return (
		<TravultContainer>
			<TopToolbar
				title="Teams"
				subtitle="Manage the all the teams in your organization"
				buttons={[
					<Button variant="contained" color="secondary">
						Add Team
					</Button>,
				]}
			/>
			<Loading loading={loadingTeams}>
				<ul>
					{teams.map((team) => (
						<li>{team.name}</li>
					))}
				</ul>
			</Loading>
		</TravultContainer>
	);
};

export { TeamsPage };
