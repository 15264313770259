import { Clear } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Popover, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
// import clsx from 'clsx';
import { lighten, Theme, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { RefObject, useState } from 'react';
import { Loading } from '../loading/loading';

interface WithID {
	id?: string;
	name?: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T extends WithID>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell<T> {
	disablePadding: boolean;
	field: keyof T;
	label: string;
	numeric: boolean;
}

interface EnhancedTableProps<T> {
	classes: ReturnType<typeof useStyles>;
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	headers: HeadCell<T>[];
}

interface AdditionalAction<P extends object> {
	icon: JSX.Element;
	tooltip: string;
	handleClick: (ids: string[]) => Promise<P>;
}

interface FormattedAdditionalAction {
	icon: JSX.Element;
	tooltip: string;
	handleClick: () => void;
}

interface BasicTableProps<T extends WithID, P extends object> {
	title: string;
	headers: HeadCell<T>[];
	rows: T[];
	deleteSelected: (ids: string[]) => Promise<P>;
	callback?: () => void;
	additionalSelectActions?: AdditionalAction<P>[];
}

interface EnhancedTableToolbarProps {
	title: string;
	numSelected: number;
	handleDelete: () => void;
	additionalActions: FormattedAdditionalAction[];
	filterText: string;
	setFilterText: (text: string) => void;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		highlight:
			theme.palette.mode === 'light'
				? {
						color: theme.palette.secondary.main,
						backgroundColor: lighten(theme.palette.secondary.light, 0.85),
				  }
				: {
						color: theme.palette.text.primary,
						backgroundColor: theme.palette.secondary.dark,
				  },
		title: {
			marginRight: 'auto',
		},
		popoverContent: {
			margin: theme.spacing(2),
		},
	})
);

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const classes = useToolbarStyles();
	const theme = useTheme();
	const { title, numSelected, handleDelete, additionalActions, filterText, setFilterText } =
		props;
	const filterRef = React.createRef<HTMLButtonElement>();
	const [anchorEl, setAnchorEl] = useState<RefObject<HTMLButtonElement> | null>(null);

	const open = Boolean(anchorEl);

	return (
		<Toolbar className={classes.root}>
			{numSelected > 0 ? (
				<Typography
					className={classes.title}
					color="inherit"
					variant="subtitle1"
					component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
					{title}
				</Typography>
			)}
			{numSelected > 0 ? (
				<>
					{additionalActions.map((action) => (
						<Tooltip title={action.tooltip}>
							<IconButton
								style={{ color: theme.palette.primary.contrastText }}
								onClick={action.handleClick}
								size="large">
								{action.icon}
							</IconButton>
						</Tooltip>
					))}
					<Tooltip title="Delete">
						<IconButton
							aria-label="delete"
							style={{ color: theme.palette.error.main }}
							onClick={handleDelete}
							size="large">
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</>
			) : (
				<>
					<Tooltip
						title="Filter list"
						style={{ color: theme.palette.primary.contrastText }}>
						<IconButton
							ref={filterRef}
							aria-label="filter list"
							onClick={() => setAnchorEl(filterRef)}
							size="large">
							<FilterListIcon />
						</IconButton>
					</Tooltip>
					<Popover
						open={open}
						anchorEl={anchorEl?.current}
						onClose={() => setAnchorEl(null)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}>
						<div className={classes.popoverContent}>
							<TextField
								label="Filter"
								value={filterText}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									setFilterText(event.target.value)
								}
							/>
							<Tooltip title="Clear Filter">
								<IconButton
									aria-label="filter list"
									onClick={() => setFilterText('')}
									size="large">
									<Clear />
								</IconButton>
							</Tooltip>
						</div>
					</Popover>
				</>
			)}
		</Toolbar>
	);
};

const EnhancedTableHead = <T extends object>(props: EnhancedTableProps<T>) => {
	const theme = useTheme();
	const {
		classes,
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		headers,
	} = props;

	const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead style={{ background: theme.palette.primary.main }}>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
						style={{ color: theme.palette.primary.contrastText }}
					/>
				</TableCell>
				{headers.map((headCell, index) => (
					<TableCell
						key={headCell.field as string}
						align={index !== 0 ? 'center' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.field ? order : false}
						style={{ color: theme.palette.primary.contrastText }}>
						<TableSortLabel
							style={{ color: theme.palette.primary.contrastText }}
							active={orderBy === headCell.field}
							direction={orderBy === headCell.field ? order : 'asc'}
							onClick={createSortHandler(headCell.field)}>
							{headCell.label}
							{orderBy === headCell.field ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
		table: {
			minWidth: 750,
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
	})
);

const BasicTable = <T extends WithID, P extends object>(props: BasicTableProps<T, P>) => {
	const { title, headers, rows, deleteSelected, callback, additionalSelectActions } = props;
	const classes = useStyles();
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof T>('name');
	const [selected, setSelected] = React.useState<string[]>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [loading, setLoading] = React.useState(false);
	const [filterText, setFilterText] = useState('');

	// DATA SETUP
	const createClickHandler = (func: (ids: string[]) => Promise<P>) => {
		const newFunc = () => {
			setLoading(true);
			func(selected).then(() => {
				if (callback) callback();
				setLoading(false);
			});
		};
		return newFunc;
	};

	let formattedAdditionalActions: FormattedAdditionalAction[] = [];
	if (additionalSelectActions) {
		formattedAdditionalActions = additionalSelectActions.map((action) => {
			return {
				icon: action.icon,
				tooltip: action.tooltip,
				handleClick: createClickHandler(action.handleClick),
			};
		});
	}

	// HANDLERS
	const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof T) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.id ?? '');
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (_: React.MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSetFilterText = (text: string) => {
		setFilterText(text);
	};

	// HELPERS
	const isTextInRow = (row: T, headers: HeadCell<T>[], text: string): boolean => {
		let exists = false;
		headers.forEach((header) => {
			if (!header.numeric) {
				const value = row[header.field];
				if (typeof value === 'string' || value instanceof String) {
					if (value.includes(text)) exists = true;
				}
			}
		});
		return exists;
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const filteredRows: T[] =
		filterText !== '' ? rows.filter((row) => isTextInRow(row, headers, filterText)) : rows;

	return (
		<Loading loading={loading}>
			<div className={classes.root}>
				<Paper className={classes.paper}>
					<EnhancedTableToolbar
						title={title}
						numSelected={selected.length}
						handleDelete={() => createClickHandler(deleteSelected)()}
						additionalActions={formattedAdditionalActions}
						filterText={filterText}
						setFilterText={handleSetFilterText}
					/>
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							size="medium"
							aria-label="enhanced table">
							<EnhancedTableHead
								classes={classes}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy as string}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={filteredRows.length}
								headers={headers}
							/>
							<TableBody>
								{stableSort(filteredRows, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(row.id ?? '');
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												onClick={(event) =>
													handleClick(event, row.id ?? '')
												}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}>
												<TableCell padding="checkbox">
													<Checkbox
														checked={isItemSelected}
														inputProps={{ 'aria-labelledby': labelId }}
													/>
												</TableCell>
												{headers.map((head, index) => {
													if (index === 0) {
														return (
															<TableCell
																key={row.id + `-index${index}`}
																component="th"
																id={labelId}
																scope="row"
																padding="none">
																{row[head.field]}
															</TableCell>
														);
													} else {
														return (
															<TableCell
																key={row.id + `-index${index}`}
																align="center">
																{row[head.field]}
															</TableCell>
														);
													}
												})}
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 20]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>
		</Loading>
	);
};

export { BasicTable };
