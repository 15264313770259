import { SeverityEnum } from 'enums/severity-enum';
import { UserCategories } from 'types/user_types';
import { GlobalState } from './global_state';
import { ApplicationState } from './sub-states/application_state';
import { GeneralState } from './sub-states/general_state';
import { InterviewsState } from './sub-states/interviews_state';
import { OrganizationState } from './sub-states/org_state';
import { UserState } from './sub-states/user_state';

export const InitialApplicationState: ApplicationState = {
	__typename: 'Application',
	id: '',
	submitted: false,
};

export const InitialGeneralState: GeneralState = {
	darkMode: false,
	globalLoading: false,
	refreshing: false,
	alert: {
		open: false,
		message: '',
		severity: SeverityEnum.Success,
	},
};

export const InitialInterviewsState: InterviewsState = {
	hideOld: true,
	slots: [],
};

export const InitialOrganizationState: OrganizationState = {
	__typename: 'Organization',
	id: '',
	name: '',
	invariantName: '',
	confirmed: false,
};

export const InitialUserState: UserState = {
	__typename: 'User',
	id: '',
	cognitoID: '',
	organizationID: '',
	name: '',
	email: '',
	type: UserCategories.Applicant,
	confirmed: false,
};

export const InitialState: GlobalState = {
	application: InitialApplicationState,
	general: InitialGeneralState,
	interviews: InitialInterviewsState,
	organization: InitialOrganizationState,
	user: InitialUserState,
};
