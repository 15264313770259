/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInterviewSlot = /* GraphQL */ `
  mutation CreateInterviewSlot(
    $input: CreateInterviewSlotInput!
    $condition: ModelInterviewSlotConditionInput
  ) {
    createInterviewSlot(input: $input, condition: $condition) {
      id
      name
      startTime
      endTime
      organizationID
      location
      createdAt
      updatedAt
      InterviewSlotUsers {
        items {
          id
          interviewslotID
          userID
          createdAt
          updatedAt
          interviewslot {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateInterviewSlot = /* GraphQL */ `
  mutation UpdateInterviewSlot(
    $input: UpdateInterviewSlotInput!
    $condition: ModelInterviewSlotConditionInput
  ) {
    updateInterviewSlot(input: $input, condition: $condition) {
      id
      name
      startTime
      endTime
      organizationID
      location
      createdAt
      updatedAt
      InterviewSlotUsers {
        items {
          id
          interviewslotID
          userID
          createdAt
          updatedAt
          interviewslot {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteInterviewSlot = /* GraphQL */ `
  mutation DeleteInterviewSlot(
    $input: DeleteInterviewSlotInput!
    $condition: ModelInterviewSlotConditionInput
  ) {
    deleteInterviewSlot(input: $input, condition: $condition) {
      id
      name
      startTime
      endTime
      organizationID
      location
      createdAt
      updatedAt
      InterviewSlotUsers {
        items {
          id
          interviewslotID
          userID
          createdAt
          updatedAt
          interviewslot {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createOrganizationSettings = /* GraphQL */ `
  mutation CreateOrganizationSettings(
    $input: CreateOrganizationSettingsInput!
    $condition: ModelOrganizationSettingsConditionInput
  ) {
    createOrganizationSettings(input: $input, condition: $condition) {
      id
      deadline
      blindReview
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateOrganizationSettings = /* GraphQL */ `
  mutation UpdateOrganizationSettings(
    $input: UpdateOrganizationSettingsInput!
    $condition: ModelOrganizationSettingsConditionInput
  ) {
    updateOrganizationSettings(input: $input, condition: $condition) {
      id
      deadline
      blindReview
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteOrganizationSettings = /* GraphQL */ `
  mutation DeleteOrganizationSettings(
    $input: DeleteOrganizationSettingsInput!
    $condition: ModelOrganizationSettingsConditionInput
  ) {
    deleteOrganizationSettings(input: $input, condition: $condition) {
      id
      deadline
      blindReview
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createReviewRubric = /* GraphQL */ `
  mutation CreateReviewRubric(
    $input: CreateReviewRubricInput!
    $condition: ModelReviewRubricConditionInput
  ) {
    createReviewRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateReviewRubric = /* GraphQL */ `
  mutation UpdateReviewRubric(
    $input: UpdateReviewRubricInput!
    $condition: ModelReviewRubricConditionInput
  ) {
    updateReviewRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteReviewRubric = /* GraphQL */ `
  mutation DeleteReviewRubric(
    $input: DeleteReviewRubricInput!
    $condition: ModelReviewRubricConditionInput
  ) {
    deleteReviewRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      submitted
      form
      organizationID
      createdAt
      updatedAt
      users {
        items {
          id
          userID
          reviewID
          createdAt
          updatedAt
          review {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      submitted
      form
      organizationID
      createdAt
      updatedAt
      users {
        items {
          id
          userID
          reviewID
          createdAt
          updatedAt
          review {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      submitted
      form
      organizationID
      createdAt
      updatedAt
      users {
        items {
          id
          userID
          reviewID
          createdAt
          updatedAt
          review {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createInterviewRubric = /* GraphQL */ `
  mutation CreateInterviewRubric(
    $input: CreateInterviewRubricInput!
    $condition: ModelInterviewRubricConditionInput
  ) {
    createInterviewRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateInterviewRubric = /* GraphQL */ `
  mutation UpdateInterviewRubric(
    $input: UpdateInterviewRubricInput!
    $condition: ModelInterviewRubricConditionInput
  ) {
    updateInterviewRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteInterviewRubric = /* GraphQL */ `
  mutation DeleteInterviewRubric(
    $input: DeleteInterviewRubricInput!
    $condition: ModelInterviewRubricConditionInput
  ) {
    deleteInterviewRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createInterview = /* GraphQL */ `
  mutation CreateInterview(
    $input: CreateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    createInterview(input: $input, condition: $condition) {
      id
      dateCreated
      submitted
      form
      organizationID
      createdAt
      updatedAt
      users {
        items {
          id
          userID
          interviewID
          createdAt
          updatedAt
          interview {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateInterview = /* GraphQL */ `
  mutation UpdateInterview(
    $input: UpdateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    updateInterview(input: $input, condition: $condition) {
      id
      dateCreated
      submitted
      form
      organizationID
      createdAt
      updatedAt
      users {
        items {
          id
          userID
          interviewID
          createdAt
          updatedAt
          interview {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteInterview = /* GraphQL */ `
  mutation DeleteInterview(
    $input: DeleteInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    deleteInterview(input: $input, condition: $condition) {
      id
      dateCreated
      submitted
      form
      organizationID
      createdAt
      updatedAt
      users {
        items {
          id
          userID
          interviewID
          createdAt
          updatedAt
          interview {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createApplicationRubric = /* GraphQL */ `
  mutation CreateApplicationRubric(
    $input: CreateApplicationRubricInput!
    $condition: ModelApplicationRubricConditionInput
  ) {
    createApplicationRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateApplicationRubric = /* GraphQL */ `
  mutation UpdateApplicationRubric(
    $input: UpdateApplicationRubricInput!
    $condition: ModelApplicationRubricConditionInput
  ) {
    updateApplicationRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteApplicationRubric = /* GraphQL */ `
  mutation DeleteApplicationRubric(
    $input: DeleteApplicationRubricInput!
    $condition: ModelApplicationRubricConditionInput
  ) {
    deleteApplicationRubric(input: $input, condition: $condition) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      submitted
      organizationID
      form
      createdAt
      updatedAt
      User {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      submitted
      organizationID
      form
      createdAt
      updatedAt
      User {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      submitted
      organizationID
      form
      createdAt
      updatedAt
      User {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      organizationID
      createdAt
      updatedAt
      Users {
        items {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      organizationID
      createdAt
      updatedAt
      Users {
        items {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      organizationID
      createdAt
      updatedAt
      Users {
        items {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      invariantName
      confirmed
      createdAt
      updatedAt
      InterviewSlots {
        items {
          id
          name
          startTime
          endTime
          organizationID
          location
          createdAt
          updatedAt
          InterviewSlotUsers {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Reviews {
        items {
          id
          submitted
          form
          organizationID
          createdAt
          updatedAt
          users {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Interviews {
        items {
          id
          dateCreated
          submitted
          form
          organizationID
          createdAt
          updatedAt
          users {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Applications {
        items {
          id
          submitted
          organizationID
          form
          createdAt
          updatedAt
          User {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      Teams {
        items {
          id
          name
          organizationID
          createdAt
          updatedAt
          Users {
            items {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Users {
        items {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      invariantName
      confirmed
      createdAt
      updatedAt
      InterviewSlots {
        items {
          id
          name
          startTime
          endTime
          organizationID
          location
          createdAt
          updatedAt
          InterviewSlotUsers {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Reviews {
        items {
          id
          submitted
          form
          organizationID
          createdAt
          updatedAt
          users {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Interviews {
        items {
          id
          dateCreated
          submitted
          form
          organizationID
          createdAt
          updatedAt
          users {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Applications {
        items {
          id
          submitted
          organizationID
          form
          createdAt
          updatedAt
          User {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      Teams {
        items {
          id
          name
          organizationID
          createdAt
          updatedAt
          Users {
            items {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Users {
        items {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      invariantName
      confirmed
      createdAt
      updatedAt
      InterviewSlots {
        items {
          id
          name
          startTime
          endTime
          organizationID
          location
          createdAt
          updatedAt
          InterviewSlotUsers {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Reviews {
        items {
          id
          submitted
          form
          organizationID
          createdAt
          updatedAt
          users {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Interviews {
        items {
          id
          dateCreated
          submitted
          form
          organizationID
          createdAt
          updatedAt
          users {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Applications {
        items {
          id
          submitted
          organizationID
          form
          createdAt
          updatedAt
          User {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      Teams {
        items {
          id
          name
          organizationID
          createdAt
          updatedAt
          Users {
            items {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Users {
        items {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoID
      name
      organizationID
      teamID
      type
      email
      confirmed
      createdAt
      updatedAt
      UserInterviews {
        items {
          id
          userID
          interviewID
          createdAt
          updatedAt
          interview {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      UserReviews {
        items {
          id
          userID
          reviewID
          createdAt
          updatedAt
          review {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      interviewslots {
        items {
          id
          interviewslotID
          userID
          createdAt
          updatedAt
          interviewslot {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoID
      name
      organizationID
      teamID
      type
      email
      confirmed
      createdAt
      updatedAt
      UserInterviews {
        items {
          id
          userID
          interviewID
          createdAt
          updatedAt
          interview {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      UserReviews {
        items {
          id
          userID
          reviewID
          createdAt
          updatedAt
          review {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      interviewslots {
        items {
          id
          interviewslotID
          userID
          createdAt
          updatedAt
          interviewslot {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoID
      name
      organizationID
      teamID
      type
      email
      confirmed
      createdAt
      updatedAt
      UserInterviews {
        items {
          id
          userID
          interviewID
          createdAt
          updatedAt
          interview {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      UserReviews {
        items {
          id
          userID
          reviewID
          createdAt
          updatedAt
          review {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      interviewslots {
        items {
          id
          interviewslotID
          userID
          createdAt
          updatedAt
          interviewslot {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createUserInterview = /* GraphQL */ `
  mutation CreateUserInterview(
    $input: CreateUserInterviewInput!
    $condition: ModelUserInterviewConditionInput
  ) {
    createUserInterview(input: $input, condition: $condition) {
      id
      userID
      interviewID
      createdAt
      updatedAt
      interview {
        id
        dateCreated
        submitted
        form
        organizationID
        createdAt
        updatedAt
        users {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUserInterview = /* GraphQL */ `
  mutation UpdateUserInterview(
    $input: UpdateUserInterviewInput!
    $condition: ModelUserInterviewConditionInput
  ) {
    updateUserInterview(input: $input, condition: $condition) {
      id
      userID
      interviewID
      createdAt
      updatedAt
      interview {
        id
        dateCreated
        submitted
        form
        organizationID
        createdAt
        updatedAt
        users {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUserInterview = /* GraphQL */ `
  mutation DeleteUserInterview(
    $input: DeleteUserInterviewInput!
    $condition: ModelUserInterviewConditionInput
  ) {
    deleteUserInterview(input: $input, condition: $condition) {
      id
      userID
      interviewID
      createdAt
      updatedAt
      interview {
        id
        dateCreated
        submitted
        form
        organizationID
        createdAt
        updatedAt
        users {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createUserReview = /* GraphQL */ `
  mutation CreateUserReview(
    $input: CreateUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    createUserReview(input: $input, condition: $condition) {
      id
      userID
      reviewID
      createdAt
      updatedAt
      review {
        id
        submitted
        form
        organizationID
        createdAt
        updatedAt
        users {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUserReview = /* GraphQL */ `
  mutation UpdateUserReview(
    $input: UpdateUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    updateUserReview(input: $input, condition: $condition) {
      id
      userID
      reviewID
      createdAt
      updatedAt
      review {
        id
        submitted
        form
        organizationID
        createdAt
        updatedAt
        users {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUserReview = /* GraphQL */ `
  mutation DeleteUserReview(
    $input: DeleteUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    deleteUserReview(input: $input, condition: $condition) {
      id
      userID
      reviewID
      createdAt
      updatedAt
      review {
        id
        submitted
        form
        organizationID
        createdAt
        updatedAt
        users {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createInterviewSlotUser = /* GraphQL */ `
  mutation CreateInterviewSlotUser(
    $input: CreateInterviewSlotUserInput!
    $condition: ModelInterviewSlotUserConditionInput
  ) {
    createInterviewSlotUser(input: $input, condition: $condition) {
      id
      interviewslotID
      userID
      createdAt
      updatedAt
      interviewslot {
        id
        name
        startTime
        endTime
        organizationID
        location
        createdAt
        updatedAt
        InterviewSlotUsers {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateInterviewSlotUser = /* GraphQL */ `
  mutation UpdateInterviewSlotUser(
    $input: UpdateInterviewSlotUserInput!
    $condition: ModelInterviewSlotUserConditionInput
  ) {
    updateInterviewSlotUser(input: $input, condition: $condition) {
      id
      interviewslotID
      userID
      createdAt
      updatedAt
      interviewslot {
        id
        name
        startTime
        endTime
        organizationID
        location
        createdAt
        updatedAt
        InterviewSlotUsers {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteInterviewSlotUser = /* GraphQL */ `
  mutation DeleteInterviewSlotUser(
    $input: DeleteInterviewSlotUserInput!
    $condition: ModelInterviewSlotUserConditionInput
  ) {
    deleteInterviewSlotUser(input: $input, condition: $condition) {
      id
      interviewslotID
      userID
      createdAt
      updatedAt
      interviewslot {
        id
        name
        startTime
        endTime
        organizationID
        location
        createdAt
        updatedAt
        InterviewSlotUsers {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      user {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
