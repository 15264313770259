import { Grid, Switch } from '@mui/material';

interface CustomSwitchProps {
	handleToggle: () => void;
	label: string;
	value: boolean;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ label, handleToggle, value }) => {
	return (
		<>
			<Grid container direction="row" justifyContent="center" alignItems="center">
				<Grid item>{label}</Grid>
				<Grid item>
					<Switch color="secondary" checked={value} onChange={handleToggle} />
				</Grid>
			</Grid>
		</>
	);
};

export { CustomSwitch };
