/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInterviewSlot = /* GraphQL */ `
  query GetInterviewSlot($id: ID!) {
    getInterviewSlot(id: $id) {
      id
      name
      startTime
      endTime
      organizationID
      location
      createdAt
      updatedAt
      InterviewSlotUsers {
        items {
          id
          interviewslotID
          userID
          createdAt
          updatedAt
          interviewslot {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listInterviewSlots = /* GraphQL */ `
  query ListInterviewSlots(
    $filter: ModelInterviewSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startTime
        endTime
        organizationID
        location
        createdAt
        updatedAt
        InterviewSlotUsers {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrganizationSettings = /* GraphQL */ `
  query GetOrganizationSettings($id: ID!) {
    getOrganizationSettings(id: $id) {
      id
      deadline
      blindReview
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listOrganizationSettingss = /* GraphQL */ `
  query ListOrganizationSettingss(
    $filter: ModelOrganizationSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationSettingss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deadline
        blindReview
        createdAt
        updatedAt
        Organization {
          id
          name
          invariantName
          confirmed
          createdAt
          updatedAt
          InterviewSlots {
            items {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            nextToken
          }
          Reviews {
            items {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Interviews {
            items {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Applications {
            items {
              id
              submitted
              organizationID
              form
              createdAt
              updatedAt
            }
            nextToken
          }
          Teams {
            items {
              id
              name
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Users {
            items {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getReviewRubric = /* GraphQL */ `
  query GetReviewRubric($id: ID!) {
    getReviewRubric(id: $id) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listReviewRubrics = /* GraphQL */ `
  query ListReviewRubrics(
    $filter: ModelReviewRubricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewRubrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        form
        createdAt
        updatedAt
        Organization {
          id
          name
          invariantName
          confirmed
          createdAt
          updatedAt
          InterviewSlots {
            items {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            nextToken
          }
          Reviews {
            items {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Interviews {
            items {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Applications {
            items {
              id
              submitted
              organizationID
              form
              createdAt
              updatedAt
            }
            nextToken
          }
          Teams {
            items {
              id
              name
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Users {
            items {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      submitted
      form
      organizationID
      createdAt
      updatedAt
      users {
        items {
          id
          userID
          reviewID
          createdAt
          updatedAt
          review {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        submitted
        form
        organizationID
        createdAt
        updatedAt
        users {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getInterviewRubric = /* GraphQL */ `
  query GetInterviewRubric($id: ID!) {
    getInterviewRubric(id: $id) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listInterviewRubrics = /* GraphQL */ `
  query ListInterviewRubrics(
    $filter: ModelInterviewRubricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviewRubrics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        form
        createdAt
        updatedAt
        Organization {
          id
          name
          invariantName
          confirmed
          createdAt
          updatedAt
          InterviewSlots {
            items {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            nextToken
          }
          Reviews {
            items {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Interviews {
            items {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Applications {
            items {
              id
              submitted
              organizationID
              form
              createdAt
              updatedAt
            }
            nextToken
          }
          Teams {
            items {
              id
              name
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Users {
            items {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getInterview = /* GraphQL */ `
  query GetInterview($id: ID!) {
    getInterview(id: $id) {
      id
      dateCreated
      submitted
      form
      organizationID
      createdAt
      updatedAt
      users {
        items {
          id
          userID
          interviewID
          createdAt
          updatedAt
          interview {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listInterviews = /* GraphQL */ `
  query ListInterviews(
    $filter: ModelInterviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateCreated
        submitted
        form
        organizationID
        createdAt
        updatedAt
        users {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getApplicationRubric = /* GraphQL */ `
  query GetApplicationRubric($id: ID!) {
    getApplicationRubric(id: $id) {
      id
      name
      form
      createdAt
      updatedAt
      Organization {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listApplicationRubrics = /* GraphQL */ `
  query ListApplicationRubrics(
    $filter: ModelApplicationRubricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationRubrics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        form
        createdAt
        updatedAt
        Organization {
          id
          name
          invariantName
          confirmed
          createdAt
          updatedAt
          InterviewSlots {
            items {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            nextToken
          }
          Reviews {
            items {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Interviews {
            items {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Applications {
            items {
              id
              submitted
              organizationID
              form
              createdAt
              updatedAt
            }
            nextToken
          }
          Teams {
            items {
              id
              name
              organizationID
              createdAt
              updatedAt
            }
            nextToken
          }
          Users {
            items {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      submitted
      organizationID
      form
      createdAt
      updatedAt
      User {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        submitted
        organizationID
        form
        createdAt
        updatedAt
        User {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      organizationID
      createdAt
      updatedAt
      Users {
        items {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organizationID
        createdAt
        updatedAt
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      invariantName
      confirmed
      createdAt
      updatedAt
      InterviewSlots {
        items {
          id
          name
          startTime
          endTime
          organizationID
          location
          createdAt
          updatedAt
          InterviewSlotUsers {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Reviews {
        items {
          id
          submitted
          form
          organizationID
          createdAt
          updatedAt
          users {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Interviews {
        items {
          id
          dateCreated
          submitted
          form
          organizationID
          createdAt
          updatedAt
          users {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Applications {
        items {
          id
          submitted
          organizationID
          form
          createdAt
          updatedAt
          User {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      Teams {
        items {
          id
          name
          organizationID
          createdAt
          updatedAt
          Users {
            items {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      Users {
        items {
          id
          cognitoID
          name
          organizationID
          teamID
          type
          email
          confirmed
          createdAt
          updatedAt
          UserInterviews {
            items {
              id
              userID
              interviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          UserReviews {
            items {
              id
              userID
              reviewID
              createdAt
              updatedAt
            }
            nextToken
          }
          interviewslots {
            items {
              id
              interviewslotID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        invariantName
        confirmed
        createdAt
        updatedAt
        InterviewSlots {
          items {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          nextToken
        }
        Reviews {
          items {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Interviews {
          items {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          nextToken
        }
        Applications {
          items {
            id
            submitted
            organizationID
            form
            createdAt
            updatedAt
            User {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        Teams {
          items {
            id
            name
            organizationID
            createdAt
            updatedAt
            Users {
              nextToken
            }
          }
          nextToken
        }
        Users {
          items {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoID
      name
      organizationID
      teamID
      type
      email
      confirmed
      createdAt
      updatedAt
      UserInterviews {
        items {
          id
          userID
          interviewID
          createdAt
          updatedAt
          interview {
            id
            dateCreated
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      UserReviews {
        items {
          id
          userID
          reviewID
          createdAt
          updatedAt
          review {
            id
            submitted
            form
            organizationID
            createdAt
            updatedAt
            users {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
      interviewslots {
        items {
          id
          interviewslotID
          userID
          createdAt
          updatedAt
          interviewslot {
            id
            name
            startTime
            endTime
            organizationID
            location
            createdAt
            updatedAt
            InterviewSlotUsers {
              nextToken
            }
          }
          user {
            id
            cognitoID
            name
            organizationID
            teamID
            type
            email
            confirmed
            createdAt
            updatedAt
            UserInterviews {
              nextToken
            }
            UserReviews {
              nextToken
            }
            interviewslots {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoID
        name
        organizationID
        teamID
        type
        email
        confirmed
        createdAt
        updatedAt
        UserInterviews {
          items {
            id
            userID
            interviewID
            createdAt
            updatedAt
            interview {
              id
              dateCreated
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        UserReviews {
          items {
            id
            userID
            reviewID
            createdAt
            updatedAt
            review {
              id
              submitted
              form
              organizationID
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        interviewslots {
          items {
            id
            interviewslotID
            userID
            createdAt
            updatedAt
            interviewslot {
              id
              name
              startTime
              endTime
              organizationID
              location
              createdAt
              updatedAt
            }
            user {
              id
              cognitoID
              name
              organizationID
              teamID
              type
              email
              confirmed
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
