import { GraphQLResult } from '@aws-amplify/api';
import { InterviewRubric } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { InterviewRubricQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/
export const createInterviewRubric = async (organizationId: string): Promise<InterviewRubric> => {
	// api call
	const interviewRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.createInterviewRubric, {
			input: { name: 'Default', interviewRubricOrganizationId: organizationId },
		})
	)) as GraphQLResult<InterviewRubricQueries.CreateInterviewRubricMutation>;

	// parse response
	const interviewRubric = parseGraphQLResponse(interviewRubricResponse).createInterviewRubric;

	return interviewRubric;
};

/**************************** RETRIEVE ****************************/
export const getInterviewRubric = async (id: string): Promise<InterviewRubric> => {
	// api call
	const interviewRubricResponse = (await API.graphql(
		graphqlOperation(Queries.getInterviewRubric, { id: id })
	)) as GraphQLResult<InterviewRubricQueries.GetInterviewRubricQuery>;

	// parse response
	const interviewRubric = parseGraphQLResponse(interviewRubricResponse).getInterviewRubric;

	return interviewRubric;
};

export const getInterviewRubrics = async (): Promise<InterviewRubric[]> => {
	// api call
	const interviewRubricResponse = (await API.graphql(
		graphqlOperation(Queries.listInterviewRubrics)
	)) as GraphQLResult<InterviewRubricQueries.ListInterviewRubricsQuery>;

	// parse response
	const interviewRubrics =
		parseGraphQLResponse(interviewRubricResponse).listInterviewRubrics.items;

	return interviewRubrics;
};
/**************************** Update ****************************/
export const updateInterviewRubric = async (
	id: string,
	name: string,
	form: string
): Promise<InterviewRubric> => {
	// api call
	const interviewRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.updateInterviewRubric, {
			input: { id: id, name: name, form: form },
		})
	)) as GraphQLResult<InterviewRubricQueries.UpdateInterviewRubricMutation>;

	// parse response
	const interviewRubric = parseGraphQLResponse(interviewRubricResponse).updateInterviewRubric;

	return interviewRubric;
};

/**************************** DELETE ****************************/
export const deleteInterviewRubric = async (id: string): Promise<InterviewRubric | undefined> => {
	// api call
	const interviewRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteInterviewRubric, { input: { id: id } })
	)) as GraphQLResult<InterviewRubricQueries.DeleteInterviewRubricMutation>;

	// parse response
	const interviewRubric = parseGraphQLResponse(interviewRubricResponse).deleteInterviewRubric;

	return interviewRubric;
};
