import { ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import { InterviewSlot, User } from 'API';
import { format } from 'date-fns';
import { groupBy } from 'lodash';
import { useState } from 'react';
import { InterviewsListItem } from './interviews_list_item';

const getDateString = (slot: InterviewSlot) => {
	return format(new Date(slot.startTime ?? ''), 'P');
};

interface InterviewsListProps {
	interviews: InterviewSlot[];
	user?: User;
}

const InterviewsList: React.FC<InterviewsListProps> = ({ interviews, user }) => {
	const groupedSlots = groupBy(interviews, getDateString);
	const [expandedPanel, setExpandedPanel] = useState('');

	const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
		setExpandedPanel(isExpanded ? panel : '');
	};

	return (
		<>
			<Grid container justifyContent="center" direction="column">
				{interviews.length && interviews.length > 0 ? (
					<>
						{Object.entries(groupedSlots)
							.sort((a, b) => (a < b ? -1 : 1))
							.map(([key, slots]) => (
								<>
									<Accordion key={key} expanded={expandedPanel === key} onChange={handleChange(key)}>
										<AccordionSummary expandIcon={<ExpandMore />}>
											<Typography variant="subtitle1">{format(new Date(key), 'PPPP')}</Typography>
											<Typography variant="subtitle1" color="text.secondary" sx={{ ml: 2 }}>
												({slots.length})
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<TableContainer>
												<Table>
													<TableBody>
														{slots
															.sort((a, b) => (a.startTime! < b.startTime! ? -1 : 1))
															.map((slot) => (
																<>
																	<TableRow
																		key={slot.id}
																		sx={{
																			'&:last-child td, &:last-child th': {
																				border: 0,
																			},
																			'&:nth-of-type(odd)': {
																				backgroundColor: 'action.hover',
																			},
																		}}>
																		<TableCell>
																			<InterviewsListItem
																				interview={slot}
																				user={user}
																			/>
																		</TableCell>
																	</TableRow>
																</>
															))}
													</TableBody>
												</Table>
											</TableContainer>
										</AccordionDetails>
									</Accordion>
								</>
							))}
					</>
				) : (
					<>
						<Typography>No scheduled interviews</Typography>
						{Object.entries(groupedSlots)
							.sort((a, b) => (a < b ? -1 : 1))
							.map(([key, slots]) => (
								<>
									<Accordion
										key={key}
										// sx={{ mt: 2, mb: 2 }}
										expanded={expandedPanel === key}
										onChange={handleChange(key)}>
										<AccordionSummary expandIcon={<ExpandMore />}>
											<Typography variant="subtitle1">{format(new Date(key), 'PPPP')}</Typography>
											<Typography variant="subtitle1" color="text.secondary" sx={{ ml: 2 }}>
												({slots.length})
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<TableContainer>
												<Table>
													<TableBody>
														{slots
															.sort((a, b) => (a.startTime! < b.startTime! ? -1 : 1))
															.map((slot) => (
																<>
																	<TableRow key={slot.id}>
																		<TableCell>
																			<InterviewsListItem
																				interview={slot}
																				user={user}
																			/>
																		</TableCell>
																	</TableRow>
																</>
															))}
													</TableBody>
												</Table>
											</TableContainer>
										</AccordionDetails>
									</Accordion>
								</>
							))}
					</>
				)}
			</Grid>
		</>
	);
};

export { InterviewsList };
