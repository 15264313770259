import { Actions } from 'actions';
import { Organization, User } from 'API';
import { Auth } from 'aws-amplify';
import { Guid } from 'guid-typescript';
import { makeErrorAlert } from 'providers/alert_provider';
import history from 'routing/history';
import { Routes } from 'routing/routes';
import { store } from 'store';
import { CognitoUserInfo } from 'types/aws_types';
import { UserCategories } from 'types/user_types';
import { routeForUser } from 'utils/user_utility';
import { getApplicationRubrics } from './application_rubric_service';
import { getApplicationByUser } from './application_service';
import { getInterviewRubrics } from './interview_rubric_service';
import { getOrganization } from './organization_service';
import { getOrganizationSettingss } from './organization_settings_service';
import { getReviewRubrics } from './review_rubric_service';
import { getUserByEmail, updateUserCognitoId } from './user_service';

export const getUserData = async (user: User): Promise<boolean> => {
	switch (user.type) {
		case UserCategories.Applicant:
			if (!user.id) {
				store.dispatch(
					Actions.General.SetAlert(makeErrorAlert('Failed to retrieve user data'))
				);
				return false;
			}
			const application = await getApplicationByUser(user.id);
			if (application) store.dispatch(Actions.Application.SetApplication(application));
			break;
	}
	return new Promise((resolve) => {
		resolve(true);
	});
};

export const getOrganizationData = async (org: Organization): Promise<boolean> => {
	const appRubric = (await getApplicationRubrics()).filter((a) => a.Organization?.id === org.id);
	const intRubric = (await getInterviewRubrics()).filter((i) => i.Organization?.id === org.id);
	const revRubric = (await getReviewRubrics()).filter((r) => r.Organization?.id === org.id);
	const orgSettings = (await getOrganizationSettingss()).filter(
		(s) => s.Organization?.id === org.id
	);

	return new Promise((resolve) => {
		if (appRubric.length > 0)
			store.dispatch(Actions.Organization.SetApplicationRubric(appRubric[0]));
		if (intRubric.length > 0)
			store.dispatch(Actions.Organization.SetInterviewRubric(intRubric[0]));
		if (revRubric.length > 0)
			store.dispatch(Actions.Organization.SetReviewRubric(revRubric[0]));
		if (orgSettings.length > 0)
			store.dispatch(Actions.Organization.SetOrganizationSettings(orgSettings[0]));
		resolve(true);
	});
};

export const updateReduxData = async (email: string, shouldRoute: boolean = true) => {
	try {
		// grab auth user
		const cognitoUser: CognitoUserInfo = await Auth.currentUserInfo();
		if (!cognitoUser) throw new Error('Error authenticating - Please try again later.');

		// grab travult user for routing
		let user: User = await getUserByEmail(email);

		if (!user.id) return;

		// first login
		if (user.cognitoID === Guid.createEmpty().toString()) {
			const updatedUser = await updateUserCognitoId(user.id, cognitoUser.username);
			user = updatedUser;
		}

		// save user to redux
		store.dispatch(Actions.User.SetUser(user));

		// grab user related data
		await getUserData(user);

		if (!user.organizationID) return;

		// grab organization
		const org: Organization = await getOrganization(user.organizationID);
		store.dispatch(Actions.Organization.SetOrganization(org));
		// grab related items
		await getOrganizationData(org);

		// populate redux & route user
		store.dispatch(Actions.User.SetCognitoID(cognitoUser.username));
		store.dispatch(Actions.User.SetEmail(cognitoUser.attributes.email));
		if (shouldRoute) history.push(routeForUser(user));
	} catch (error) {
		console.log('Error: ', error);
		history.push(Routes.Public.LandingPage);
	}
};
