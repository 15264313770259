import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';

const MemberTeamPage: React.FC = () => {
	return (
		<>
			<TravultContainer>
				<TopToolbar title="My Team" />
			</TravultContainer>
		</>
	);
};

export { MemberTeamPage };
