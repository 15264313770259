import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { QuestionAnswer } from 'assets/faq-list';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

interface CollapsibleQuestionProps {
	questionAnswer: QuestionAnswer;
	extraInfo?: JSX.Element;
}

const CollapsibleQuestion: React.FC<CollapsibleQuestionProps> = ({ questionAnswer, extraInfo }) => {
	return (
		<>
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography variant="h5" color="textPrimary">
						{questionAnswer.question}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography variant="body1" color="textSecondary">
						{questionAnswer.answer}
					</Typography>
					{extraInfo ?? undefined}
				</AccordionDetails>
			</Accordion>
		</>
	);
};

export { CollapsibleQuestion };
