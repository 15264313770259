import { GraphQLResult } from '@aws-amplify/api';

export function parseGraphQLResponse<T>(response: GraphQLResult<T>) {
	// guards
	if (response.errors?.length ?? [].length > 0) throw new Error('Error: API call failed.');
	if (response.data === undefined) throw new Error("Error: Couldn't unwrap response.");

	// return the data from the response
	return response.data;
}
