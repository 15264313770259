export enum ValidationType {
	Email,
	ExactLength,
	HasCapital,
	HasSymbol,
	IsNumber,
	MaxLength,
	MinLength,
}

export interface Validation {
	type: ValidationType;
	data?: number;
}

export interface ValidationItem {
	valueToValidate: string;
	validations: Validation[];
	setErrorText: (error: string) => void;
}

export const validate = (form: ValidationItem[]) => {
	let validationSucceeded = true;

	form.forEach((item) => {
		const errors: string[] = [];

		item.validations.forEach((validation) => {
			const error = validateSingleType(item.valueToValidate, validation);
			if (error) errors.push(error);
		});

		if (errors.length > 0) {
			item.setErrorText(errors[0]);
			validationSucceeded = false;
		}
	});

	return validationSucceeded;
};

const validateSingleType = (value: string, validation: Validation): string | undefined => {
	switch (validation.type) {
		case ValidationType.Email:
			const hasAtSign = value.includes('@');
			const hasEndingDomainPeriod = value.length > 4 && value[value.length - 4] === '.';
			const sections = value.split('.');
			const hasDomainEnding =
				value.length > 4 &&
				sections.length > 1 &&
				sections[sections.length - 1].length === 3;

			if (hasAtSign && hasEndingDomainPeriod && hasDomainEnding) return undefined;
			else return 'Enter a valid email.';
		case ValidationType.ExactLength:
			const isCorrectLength = validation.data && value.length === validation.data;

			if (isCorrectLength) return undefined;
			else return `Incorrect input length. Should be ${validation.data} characters.`;
		case ValidationType.MinLength:
			const isMinLength = validation.data && value.length >= validation.data;

			if (isMinLength) return undefined;
			else return `Incorrect input length. Should be at least ${validation.data} characters.`;
		default:
			return undefined;
	}
};
