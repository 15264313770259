import { ActionStrings } from 'actions/action_strings';
import { DefaultAction } from 'actions/action_types';
import { InitialUserState } from 'store/initial_state';
import { UserState } from 'store/sub-states/user_state';

export const UserReducer = (state = InitialUserState, action: DefaultAction): UserState => {
	switch (action.type) {
		case ActionStrings.User.SetCognitoID:
			return { ...state, cognitoID: action.payload };
		case ActionStrings.User.SetEmail:
			return { ...state, email: action.payload };
		case ActionStrings.User.SetUser:
			return { ...state, ...action.payload };
		case ActionStrings.User.SetName:
			return { ...state, name: action.payload };
		case ActionStrings.User.SetType:
			return { ...state, type: action.payload };
		case ActionStrings.User.SetOrganization:
			return { ...state, organizationID: action.payload };
		default:
			return state;
	}
};
