import { Grid, Typography, TextField } from '@mui/material';
import React from 'react';

interface CustomTextFieldProps {
	label: string;
	value: string;
	onChangeCallback?: (value: string) => void;
	disabled?: boolean;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
	label,
	value,
	onChangeCallback,
	disabled,
}) => {
	return (
		<>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={3}
				sx={{ mb: 4 }}>
				<Grid item xs={6}>
					<Grid container direction="row" justifyContent="flex-end">
						<Typography variant="h5">{label}</Typography>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container direction="row" justifyContent="flex-start">
						<TextField
							variant="outlined"
							value={value}
							disabled={disabled}
							onChange={(event) =>
								onChangeCallback ? onChangeCallback(event.target.value) : undefined
							}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export { CustomTextField };
