import { GraphQLResult } from '@aws-amplify/api';
import { ApplicationRubric } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { ApplicationRubricQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/
export const createApplicationRubric = async (
	organizationId: string
): Promise<ApplicationRubric> => {
	// api call
	const applicationRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.createApplicationRubric, {
			input: { name: 'Default', applicationRubricOrganizationId: organizationId },
		})
	)) as GraphQLResult<ApplicationRubricQueries.CreateApplicationRubricMutation>;

	// parse response
	const applicationRubric =
		parseGraphQLResponse(applicationRubricResponse).createApplicationRubric;

	return applicationRubric;
};

/**************************** RETRIEVE ****************************/
export const getApplicationRubric = async (id: string): Promise<ApplicationRubric> => {
	// api call
	const applicationRubricResponse = (await API.graphql(
		graphqlOperation(Queries.getApplicationRubric, { id: id })
	)) as GraphQLResult<ApplicationRubricQueries.GetApplicationRubricQuery>;

	// parse response
	const applicationRubric = parseGraphQLResponse(applicationRubricResponse).getApplicationRubric;

	return applicationRubric;
};

export const getApplicationRubrics = async (): Promise<ApplicationRubric[]> => {
	// api call
	const applicationRubricResponse = (await API.graphql(
		graphqlOperation(Queries.listApplicationRubrics)
	)) as GraphQLResult<ApplicationRubricQueries.ListApplicationRubricsQuery>;

	// parse response
	const applicationRubrics =
		parseGraphQLResponse(applicationRubricResponse).listApplicationRubrics.items;

	return applicationRubrics;
};

/**************************** Update ****************************/
export const updateApplicationRubric = async (
	id: string,
	name: string,
	form: string
): Promise<ApplicationRubric> => {
	// api call
	const applicationRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.updateApplicationRubric, {
			input: { id: id, name: name, form: form },
		})
	)) as GraphQLResult<ApplicationRubricQueries.UpdateApplicationRubricMutation>;

	// parse response
	const applicationRubric =
		parseGraphQLResponse(applicationRubricResponse).updateApplicationRubric;

	return applicationRubric;
};

/**************************** DELETE ****************************/
export const deleteApplicationRubric = async (
	id: string
): Promise<ApplicationRubric | undefined> => {
	// api call
	const applicationRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteApplicationRubric, { input: { id: id } })
	)) as GraphQLResult<ApplicationRubricQueries.DeleteApplicationRubricMutation>;

	// parse response
	const applicationRubric =
		parseGraphQLResponse(applicationRubricResponse).deleteApplicationRubric;

	return applicationRubric;
};
