import { Button, Paper } from '@mui/material';
import { Actions } from 'actions';
import { CustomTextField } from 'components/forms/custom_text_field';
import { PageLayout } from 'components/layout/page_layout';
import { TravultTitle } from 'components/text/travult_title';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'services/user_service';
import { GlobalState } from 'store/global_state';
import { AlertState } from 'store/sub-states/general_state';
import { UserState } from 'store/sub-states/user_state';
import { getErrorSnackBar, getSuccessSnackBar } from 'utils/snackbar_utility';

const ProfilePage: React.FC = () => {
	const dispatch = useDispatch();
	const user = useSelector<GlobalState, UserState>((state) => state.user);
	const [name, setName] = useState(user.name);

	const handleSave = async () => {
		if (!user.id || !name || !user.confirmed) return;

		const updatedUser = await updateUser(user.id, name, user.confirmed);
		if (updatedUser) {
			dispatch(Actions.User.SetUser(updatedUser));
			const successAlert: AlertState = getSuccessSnackBar('Saved profile information');
			dispatch(Actions.General.SetAlert(successAlert));
		} else {
			const errorAlert: AlertState = getErrorSnackBar("Couldn't save profile information");
			dispatch(Actions.General.SetAlert(errorAlert));
		}
	};

	return (
		<>
			<TravultTitle>Profile</TravultTitle>
			<PageLayout>
				<Paper
					sx={{
						m: 3,
						p: 3,
						minWidth: '80%',
					}}>
					<CustomTextField label="Name" value={name ?? ''} onChangeCallback={setName} />
					<CustomTextField label="Email" value={user.email ?? ''} disabled={true} />
					<Button variant="contained" color="primary" onClick={handleSave}>
						Save
					</Button>
				</Paper>
			</PageLayout>
		</>
	);
};

export { ProfilePage };
