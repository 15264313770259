import { Email, Lock } from '@mui/icons-material';
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	Link,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { Actions } from 'actions';
import { Auth } from 'aws-amplify';
import { PageLayout } from 'components/layout/page_layout';
import { Loading } from 'components/loading/loading';
import { TravultTitle } from 'components/text/travult_title';
import { SeverityEnum } from 'enums/severity-enum';
import { makeErrorAlert } from 'providers/alert_provider';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routing/routes';
// import { createUser } from 'services/user_service';
import { GlobalState } from 'store/global_state';

const ConfirmSignUpPage: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const email = useSelector<GlobalState, string | undefined>((state) => state.user.email);
	const [code, setCode] = useState('');
	const [loading, setLoading] = useState(false);

	const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCode(event.target.value);
	};

	const handleConfirmCode = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		setLoading(true);
		try {
			if (!email) {
				dispatch(Actions.General.SetAlert(makeErrorAlert('Failed to retrieve email')));
				return;
			}
			await Auth.confirmSignUp(email, code);
			history.push(Routes.Public.Login);
		} catch (error) {
			dispatch(
				Actions.General.SetAlert({
					message: (error as Error).message,
					open: true,
					severity: SeverityEnum.Error,
				})
			);
		}
		setLoading(false);
	};

	const handleResendCode = async () => {
		setLoading(true);
		try {
			if (!email) {
				dispatch(Actions.General.SetAlert(makeErrorAlert('Failed to retrieve email')));
				return;
			}
			await Auth.resendSignUp(email);
		} catch (error) {
			dispatch(
				Actions.General.SetAlert({
					message: (error as Error).message,
					open: true,
					severity: SeverityEnum.Error,
				})
			);
		}
		setLoading(false);
	};

	return (
		<>
			<TravultTitle>Confirm Sign Up</TravultTitle>
			<PageLayout>
				<form>
					<Paper
						sx={{
							m: 1,
							p: 5,
							minWidth: 450,
						}}>
						{/* EMAIL */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Email"
									type="email"
									value={email}
									disabled
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Email />
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>

						{/* CODE */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Verification Code"
									type="text"
									value={code}
									onChange={handleCodeChange}
									required
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Lock />
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>

						{/* CREATE ACCOUNT / SIGN IN */}
						<Grid item sx={{ mt: 5 }}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center">
								<Grid item>
									<Typography variant="caption" color="textPrimary">
										Didn't receive a code?{' '}
										<Link onClick={handleResendCode}>Resend code</Link>
									</Typography>
								</Grid>
								<Grid item>
									<Loading loading={loading} size={40}>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											onClick={handleConfirmCode}>
											Confirm
										</Button>
									</Loading>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</form>
			</PageLayout>
		</>
	);
};

export { ConfirmSignUpPage };
