import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Application } from 'API';

interface ReviewsTableProps {
	completed: boolean;
	applications: Application[];
}

const ReviewsTable: React.FC<ReviewsTableProps> = ({ completed, applications }) => {
	return (
		<>
			{applications.length === 0 ? (
				<Typography sx={{ p: 3 }}>
					There are currently no {completed ? 'completed' : 'pending'} reviews
				</Typography>
			) : (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Applicant</TableCell>
								<TableCell align="center">Email</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{applications.map((app) => (
								<TableRow
									sx={{
										'&:last-child td, &:last-child th': {
											border: 0,
										},
										'&:nth-of-type(odd)': {
											backgroundColor: 'action.hover',
										},
									}}>
									<TableCell>{app.User?.name}</TableCell>
									<TableCell align="center">{app.User?.email}</TableCell>
									<TableCell align="right">
										<Button variant="contained" color="primary">
											{completed ? 'View' : 'Start Review'}
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</>
	);
};

export { ReviewsTable };
