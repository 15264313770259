import { useMemo } from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import Router from 'routing/router';
import { GlobalLoadingProvider } from 'providers/data_store/startup_provider';
import { Actions } from 'actions';
import { ThemeProvider, Theme, StyledEngineProvider, useMediaQuery, Box } from '@mui/material';
import { GlobalState } from 'store/global_state';
import { useDispatch, useSelector } from 'react-redux';
import { darkTheme, lightTheme } from 'assets/travult-theme';
import { useSingleton } from 'hooks/useSingleton';
import { RefreshProvider } from 'providers/data_store/refresh_provider';
import { AlertProvider } from 'providers/alert_provider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

Amplify.configure(awsconfig);

function App() {
	const dispatch = useDispatch();
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const isDark = useSelector<GlobalState, boolean>((state) => state.general.darkMode);

	useMemo(() => {
		if (prefersDarkMode) {
			dispatch(Actions.General.SetDarkMode(true));
		}
	}, [prefersDarkMode, dispatch]);

	useSingleton(() => {
		if (window.performance) {
			try {
				const navigationType = (
					performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming
				).type;
				if (navigationType === 'reload') {
					console.log('reloaded');
					dispatch(Actions.General.SetRefreshing(true));
				}
			} catch (error) {}
		}
	});

	// TODO: Add a DataStore configuration provider to sync only a subset of data to local
	// https://docs.amplify.aws/lib/datastore/sync/q/platform/js#selectively-syncing-a-subset-of-your-data

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={isDark ? darkTheme : lightTheme}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<RefreshProvider>
						<AlertProvider>
							<div style={{ textAlign: 'center' }}>
								<Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
									<GlobalLoadingProvider>
										<Router />
									</GlobalLoadingProvider>
								</Box>
							</div>
						</AlertProvider>
					</RefreshProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
