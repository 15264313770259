import { GraphQLResult } from '@aws-amplify/api';
import { Review } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { ReviewQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/

/**************************** RETRIEVE ****************************/
export const getReview = async (id: string): Promise<Review> => {
	// api call
	const reviewResponse = (await API.graphql(
		graphqlOperation(Queries.getReview, { id: id })
	)) as GraphQLResult<ReviewQueries.GetReviewQuery>;

	// parse response
	const review = parseGraphQLResponse(reviewResponse).getReview;

	return review;
};

export const getReviews = async (): Promise<Review[]> => {
	// api call
	const reviewResponse = (await API.graphql(
		graphqlOperation(Queries.listReviews)
	)) as GraphQLResult<ReviewQueries.ListReviewsQuery>;

	// parse response
	const reviews = parseGraphQLResponse(reviewResponse).listReviews.items;

	return reviews;
};
/**************************** Update ****************************/

/**************************** DELETE ****************************/
export const deleteReview = async (id: string): Promise<Review | undefined> => {
	// api call
	const reviewResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteReview, { input: { id: id } })
	)) as GraphQLResult<ReviewQueries.DeleteReviewMutation>;

	// parse response
	const review = parseGraphQLResponse(reviewResponse).deleteReview;

	return review;
};
