import { GraphQLResult } from '@aws-amplify/api';
import { InterviewSlot, ModelInterviewSlotFilterInput } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { InterviewSlotQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/
export const createInterviewSlot = async (
	organizationID: string,
	startTime: string,
	endTime: string,
	name: string = '',
	location: string = ''
): Promise<InterviewSlot> => {
	if (!name) {
		name = startTime + ' - ' + endTime;
	}

	const newInterviewSlot: Omit<InterviewSlot, '__typename'> = {
		organizationID,
		startTime,
		endTime,
		name,
		location,
	};
	// api call
	const interviewSlotResponse = (await API.graphql(
		graphqlOperation(Mutations.createInterviewSlot, { input: newInterviewSlot })
	)) as GraphQLResult<InterviewSlotQueries.CreateInterviewSlotMutation>;

	// parse response
	const interviewSlot = parseGraphQLResponse(interviewSlotResponse).createInterviewSlot;

	// return created interviewSlot
	return interviewSlot;
};

/**************************** RETRIEVE ****************************/
export const getInterviewSlot = async (id: string): Promise<InterviewSlot> => {
	// api call
	const interviewSlotResponse = (await API.graphql(
		graphqlOperation(Queries.getInterviewSlot, { id: id })
	)) as GraphQLResult<InterviewSlotQueries.GetInterviewSlotQuery>;

	// parse response
	const interviewSlot = parseGraphQLResponse(interviewSlotResponse).getInterviewSlot;

	return interviewSlot;
};

export const getInterviewSlots = async (): Promise<InterviewSlot[]> => {
	// api call
	const interviewSlotResponse = (await API.graphql(
		graphqlOperation(Queries.listInterviewSlots)
	)) as GraphQLResult<InterviewSlotQueries.ListInterviewSlotsQuery>;

	// parse response
	const interviewSlots = parseGraphQLResponse(interviewSlotResponse).listInterviewSlots.items;

	return interviewSlots;
};

export const getInterviewSlotsBetweenDates = async (
	start: Date,
	end: Date
): Promise<InterviewSlot[]> => {
	// filter
	const filter: ModelInterviewSlotFilterInput = {
		startTime: { between: [start.toISOString(), end.toISOString()] },
	};

	// api call
	const interviewSlotResponse = (await API.graphql(
		graphqlOperation(Queries.listInterviewSlots, { filter: filter })
	)) as GraphQLResult<InterviewSlotQueries.ListInterviewSlotsQuery>;

	// parse response
	const interviewSlots = parseGraphQLResponse(interviewSlotResponse).listInterviewSlots.items;

	return interviewSlots;
};

/**************************** UPDATE ****************************/
export const updateInterviewSlot = async (
	id: string,
	startTime: string,
	endTime: string,
	name: string,
	location: string
): Promise<InterviewSlot> => {
	// api call
	const interviewSlotResponse = (await API.graphql(
		graphqlOperation(Mutations.updateInterviewSlot, {
			input: { id, name, startTime, endTime, location },
		})
	)) as GraphQLResult<InterviewSlotQueries.UpdateInterviewSlotMutation>;

	// parse response
	const interviewSlot = parseGraphQLResponse(interviewSlotResponse).updateInterviewSlot;

	return interviewSlot;
};

/**************************** DELETE ****************************/
export const deleteInterviewSlot = async (id: string): Promise<InterviewSlot | undefined> => {
	// api call
	const interviewSlotResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteInterviewSlot, { input: { id: id } })
	)) as GraphQLResult<InterviewSlotQueries.DeleteInterviewSlotMutation>;

	// parse response
	const interviewSlot = parseGraphQLResponse(interviewSlotResponse).deleteInterviewSlot;

	return interviewSlot;
};
