import { WelcomeHeader } from '../../components/public/welcome_header';

function LandingPage() {
	return (
		<>
			<WelcomeHeader />
		</>
	);
}

export default LandingPage;
