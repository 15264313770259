import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from 'reducers';
import { Store } from '@reduxjs/toolkit';

const persistConfig = {
	// configuration object for redux-persist
	key: 'root',
	storage, // define which storage to use
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({
	trace: true,
	traceLimit: 10,
});

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware()));

const persistor = persistStore(store as Store<any, any>); // used to create the persisted store, persistor will be used in the next step

export { store, persistor };
