import { Theme, Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Strings } from 'assets/strings';
import { useWindowSize } from 'hooks/useWindowSize';
import { isMobile } from 'utils/general_utility';
import resumesImage from 'assets/images/resumes-image.jpeg';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.primary.main,
			height: '100%',
			width: '100%',
			alignItems: 'center',
			color: theme.palette.primary.contrastText,
		},
		container: {
			height: window.innerHeight - 64, //height of AppBar
		},
		title: {
			flexGrow: 1,
			textAlign: 'justify',
		},
		login: {
			boxShadow: '0',
		},
		image: {
			width: '90%',
			minWidth: 250,
		},
		mobileImage: {
			width: '100%',
			maxWidth: 600,
			minWidth: 250,
			marginBottom: 40,
		},
		welcome: {
			width: '60%',
			margin: '0 auto',
		},
	})
);

export const WelcomeHeader = () => {
	const classes = useStyles();
	const [width] = useWindowSize();

	return (
		<div className={classes.root}>
			{isMobile(width) ? (
				<>
					<Grid container className={classes.container} direction="column">
						<Grid item>
							<img
								className={classes.mobileImage}
								src={resumesImage}
								alt="Hands holding resumes coming out from a computer"
							/>
						</Grid>
						<Grid item>
							<Typography variant="h5">
								{Strings.Public.WelcomeHeaderMessage}
							</Typography>
						</Grid>
					</Grid>
				</>
			) : (
				<>
					<Grid
						container
						className={classes.container}
						direction="row"
						justifyContent="center"
						alignItems="center">
						<Grid item sm={6}>
							<Typography className={classes.welcome} variant="h3">
								{Strings.Public.WelcomeHeaderMessage}
							</Typography>
						</Grid>
						<Grid item sm={6}>
							<img
								className={classes.image}
								src={resumesImage}
								alt="Hands holding resumes coming out from a computer"
							/>
						</Grid>
					</Grid>
				</>
			)}
		</div>
	);
};
