import { Grid } from '@mui/material';
import React from 'react';
import { Loading, LoadingProps } from './loading';

const FullScreenLoader: React.FunctionComponent<LoadingProps> = ({ children, loading, size }) => {
	const loadingSize = !!size ? size : 150;
	return loading ? (
		<>
			<Grid container style={{ height: '100vh' }} justifyContent="center" alignItems="center">
				<Loading loading={loading} size={loadingSize} />
			</Grid>
		</>
	) : (
		<> {children} </>
	);
};

export { FullScreenLoader };
