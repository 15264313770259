import { AppBar, Toolbar, Typography } from '@mui/material';
import { DarkModeToggle } from 'components/general/dark_mode_toggle';

const DevOpsNavBar: React.FC = ({ children }) => {
	return (
		<>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						DevOps
					</Typography>
					<span>
						<DarkModeToggle />
					</span>
				</Toolbar>
			</AppBar>
			{children}
		</>
	);
};

export { DevOpsNavBar };
