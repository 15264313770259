import { GraphQLResult } from '@aws-amplify/api';
import { Actions } from 'actions';
import { Application, ModelApplicationFilterInput, User } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { store } from 'store';
import { ApplicationQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/
export const createApplication = async (organizationID: string, user: User) => {
	const newApplication: Omit<Application, '__typename'> = {
		submitted: false,
		User: user,
		organizationID: organizationID,
	};
	// api call
	const applicationResponse = (await API.graphql(
		graphqlOperation(Mutations.createApplication, { input: newApplication })
	)) as GraphQLResult<ApplicationQueries.CreateApplicationMutation>;

	// parse response
	const application = parseGraphQLResponse(applicationResponse).createApplication;

	// save to redux
	store.dispatch(Actions.Application.SetApplication(application));
};

/**************************** RETRIEVE ****************************/
export const getApplication = async (id: string): Promise<Application | undefined> => {
	// api call
	const applicationResponse = (await API.graphql(
		graphqlOperation(Queries.getApplication, { id: id })
	)) as GraphQLResult<ApplicationQueries.GetApplicationQuery>;

	// parse response
	const application = parseGraphQLResponse(applicationResponse).getApplication;

	return application;
};

export const getApplications = async (): Promise<Application[]> => {
	// api call
	const applicationResponse = (await API.graphql(
		graphqlOperation(Queries.listApplications)
	)) as GraphQLResult<ApplicationQueries.ListApplicationsQuery>;

	// parse response
	const applications = parseGraphQLResponse(applicationResponse).listApplications.items;

	return applications;
};

export const getApplicationByUser = async (userID: string): Promise<Application | undefined> => {
	// api call
	const applicationResponse = (await API.graphql(
		graphqlOperation(Queries.listApplications)
	)) as GraphQLResult<ApplicationQueries.ListApplicationsQuery>;

	// parse response
	const applications = parseGraphQLResponse(applicationResponse).listApplications.items.filter(
		(app) => app.User?.id === userID
	);

	if (applications.length > 0) return applications[0];
	return undefined;
};

export const getCompletedApplications = async (): Promise<Application[]> => {
	// filter
	const filter: ModelApplicationFilterInput = {
		submitted: {
			eq: true,
		},
	};

	// api call
	const applicationResponse = (await API.graphql(
		graphqlOperation(Queries.listApplications, { filter: filter })
	)) as GraphQLResult<ApplicationQueries.ListApplicationsQuery>;

	// parse response
	const applications = parseGraphQLResponse(applicationResponse).listApplications.items;

	return applications;
};

/**************************** Update ****************************/
export const updateApplicationResponse = async (
	id: string,
	form: string,
	submit?: boolean
): Promise<Application | undefined> => {
	// api call
	const applicationResponse =
		submit === undefined
			? ((await API.graphql(
					graphqlOperation(Mutations.updateApplication, { input: { id: id, form: form } })
			  )) as GraphQLResult<ApplicationQueries.UpdateApplicationMutation>)
			: ((await API.graphql(
					graphqlOperation(Mutations.updateApplication, {
						input: { id: id, form: form, submit: submit },
					})
			  )) as GraphQLResult<ApplicationQueries.UpdateApplicationMutation>);

	// parse response
	const application = parseGraphQLResponse(applicationResponse).updateApplication;

	// update redux
	store.dispatch(Actions.Application.SetApplication(application));
	return application;
};

/**************************** DELETE ****************************/
export const deleteApplication = async (id: string): Promise<Application | undefined> => {
	// api call
	const applicationResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteApplication, { input: { id: id } })
	)) as GraphQLResult<ApplicationQueries.DeleteApplicationMutation>;

	// parse response
	const application = parseGraphQLResponse(applicationResponse).deleteApplication;

	return application;
};
