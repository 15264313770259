import { Grid } from '@mui/material';
import React from 'react';

const PageLayout: React.FC = ({ children }) => {
	return (
		<Grid
			container
			style={{ height: '100%' }}
			direction="column"
			justifyContent="flex-start"
			alignItems="center">
			{children}
		</Grid>
	);
};

export { PageLayout };
