import { CustomAuth } from 'components/auth/custom_auth';
import { NavBar } from 'components/navbar/navbar';
import { PublicNavBar } from 'components/navbar/public_navbar/public_navbar';
import { AdminDashboard } from 'pages/admin/admin_dashboard';
import { AdminInterviewsPage } from 'pages/admin/interviews/admin_interviews_page';
import { MembersPage } from 'pages/admin/members/members';
import { EditApplicationRubricPage } from 'pages/admin/rubrics/application/edit_application_rubric';
import { EditInterviewRubricPage } from 'pages/admin/rubrics/interview/edit_interview_rubric';
import { EditDefaultReviewRubricPage } from 'pages/admin/rubrics/review/edit_default_review_rubric';
import { RubricsPage } from 'pages/admin/rubrics/rubrics';
import { TeamsPage } from 'pages/admin/teams_page';
import { ApplicantDashboard } from 'pages/applicants/applicants_dashboard';
import { ApplicationPage } from 'pages/applicants/application/application_page';
import { InterviewPage } from 'pages/applicants/interview/interview_page';
import { DevOpsDashboard } from 'pages/devops/devops-dashboard';
import { DevOpsNavBar } from 'pages/devops/devops-navbar';
import { ChatPage } from 'pages/general/chat_page';
import { ProfilePage } from 'pages/general/profile_page';
import { SettingsPage } from 'pages/general/settings_page';
import { LeaderDashboard } from 'pages/leaders/leader_dashboard';
import { LeaderInterviewsPage } from 'pages/leaders/leader_interviews_page';
import { LeaderTeamPage } from 'pages/leaders/leader_team_page';
import { LeaderReview } from 'pages/leaders/reviews/leader_review';
import { LeaderReviewsPage } from 'pages/leaders/reviews/leader_reviews_page';
import { MemberDashboard } from 'pages/members/member_dashboard';
import { MemberTeamPage } from 'pages/members/member_team_page';
import { ConfirmSignUpPage } from 'pages/public/authentication/confirm_signup_page';
import { ForgotPasswordPage } from 'pages/public/authentication/forgot_password_page';
import { LoginPage } from 'pages/public/authentication/login_page';
import { ResetPasswordPage } from 'pages/public/authentication/reset_password_page';
import { SignUpPage } from 'pages/public/authentication/signup_page';
import { FaqPage } from 'pages/public/faq_page';
import LandingPage from 'pages/public/landing_page';
import { PricingPage } from 'pages/public/pricing_page';
import { Route, Router, Switch } from 'react-router-dom';
import history from 'routing/history';
import { Routes } from './routes';

function TravultRouter() {
	return (
		<Router history={history}>
			<Switch>
				{/********************** PUBLIC ***********************/}
				<Route exact path={Routes.Public.LandingPage}>
					<PublicNavBar />
					<LandingPage />
				</Route>
				<Route exact path={Routes.Public.Pricing}>
					<PublicNavBar />
					<PricingPage />
				</Route>
				<Route exact path={Routes.Public.FaqPage}>
					<PublicNavBar />
					<FaqPage />
				</Route>
				<Route exact path={Routes.Public.SignUp}>
					<PublicNavBar />
					<SignUpPage />
				</Route>
				<Route exact path={Routes.Public.ConfirmSignUp}>
					<PublicNavBar />
					<ConfirmSignUpPage />
				</Route>
				<Route exact path={Routes.Public.Login}>
					<PublicNavBar />
					<LoginPage />
				</Route>
				<Route exact path={Routes.Public.ForgotPassword}>
					<PublicNavBar />
					<ForgotPasswordPage />
				</Route>
				<Route exact path={Routes.Public.ResetPassword}>
					<PublicNavBar />
					<ResetPasswordPage />
				</Route>
				{/********************** APPLICANT ***********************/}
				<Route exact path={Routes.Applicant.Dashboard}>
					<CustomAuth>
						<NavBar>
							<ApplicantDashboard />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Applicant.Application}>
					<CustomAuth>
						<NavBar>
							<ApplicationPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Applicant.Interview}>
					<CustomAuth>
						<NavBar>
							<InterviewPage />
						</NavBar>
					</CustomAuth>
				</Route>
				{/********************** MEMBER ***********************/}
				<Route exact path={Routes.Member.Dashboard}>
					<CustomAuth>
						<NavBar>
							<MemberDashboard />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Member.Team}>
					<CustomAuth>
						<NavBar>
							<MemberTeamPage />
						</NavBar>
					</CustomAuth>
				</Route>
				{/********************** LEADER ***********************/}
				<Route exact path={Routes.Leader.Dashboard}>
					<CustomAuth>
						<NavBar>
							<LeaderDashboard />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Leader.Interviews}>
					<CustomAuth>
						<NavBar>
							<LeaderInterviewsPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Leader.Reviews}>
					<CustomAuth>
						<NavBar>
							<LeaderReviewsPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Leader.Reviews + '/:id'}>
					<CustomAuth>
						<NavBar>
							<LeaderReview />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Leader.Team}>
					<CustomAuth>
						<NavBar>
							<LeaderTeamPage />
						</NavBar>
					</CustomAuth>
				</Route>
				{/********************** ADMIN ***********************/}
				<Route exact path={Routes.Admin.Dashboard}>
					<CustomAuth>
						<NavBar>
							<AdminDashboard />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Admin.EditApplicationRubric}>
					<CustomAuth>
						<NavBar>
							<EditApplicationRubricPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Admin.EditInterviewRubric}>
					<CustomAuth>
						<NavBar>
							<EditInterviewRubricPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Admin.EditReviewRubric}>
					<CustomAuth>
						<NavBar>
							<EditDefaultReviewRubricPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Admin.Members}>
					<CustomAuth>
						<NavBar>
							<MembersPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Admin.Rubrics}>
					<CustomAuth>
						<NavBar>
							<RubricsPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Admin.Interviews}>
					<CustomAuth>
						<NavBar>
							<AdminInterviewsPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.Admin.Teams}>
					<CustomAuth>
						<NavBar>
							<TeamsPage />
						</NavBar>
					</CustomAuth>
				</Route>
				{/********************** GENERAL ***********************/}
				<Route exact path={Routes.General.Settings}>
					<CustomAuth>
						<NavBar>
							<SettingsPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.General.Chat}>
					<CustomAuth>
						<NavBar>
							<ChatPage />
						</NavBar>
					</CustomAuth>
				</Route>
				<Route exact path={Routes.General.Profile}>
					<CustomAuth>
						<NavBar>
							<ProfilePage />
						</NavBar>
					</CustomAuth>
				</Route>
				{/********************** GENERAL ***********************/}
				<Route exact path={Routes.DevOps.Dashboard}>
					<DevOpsNavBar>
						<DevOpsDashboard />
					</DevOpsNavBar>
				</Route>
			</Switch>
		</Router>
	);
}

export default TravultRouter;
