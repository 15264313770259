export const ActionStrings = {
	Application: {
		SetApplication: 'APPLICATION_SET_APPLICATION',
	},
	General: {
		SetAlert: 'GENERAL_SET_ALERT',
		SetDarkMode: 'GENERAL_SET_DARK_MODE',
		SetGlobalLoading: 'GENERAL_SET_GLOBAL_LOADING',
		SetRefreshing: 'GENERAL_SET_REFRESHING',
	},
	Interviews: {
		AddInterviewSlot: 'INTERVIEWS_ADD_SLOT',
		RemoveInterviewSlot: 'INTERVIEWS_REMOVE_SLOT',
		SetInterviewSlots: 'INTERVIEWS_SET_SLOTS',
		ToggleHideOldInterviews: 'INTERVIEWS_TOGGLE_HIDE_OLD',
		UpdateInterviewSlot: 'INTERVIEWS_UPDATE_SLOT',
	},
	Organization: {
		SetApplicationRubric: 'ORG_SET_APPLICATION_RUBRIC',
		SetInterviewRubric: 'ORG_SET_INTERVIEW_RUBRIC',
		SetReviewRubric: 'ORG_SET_REVIEW_RUBRIC',
		SetOrganizationSettings: 'ORG_SET_ORGANIZATION_SETTINGS',
		SetOrganization: 'ORG_SET_ORGANIZATION',
	},
	User: {
		Logout: 'USER_LOGOUT',
		SetCognitoID: 'USER_SET_COGNITO_ID',
		SetEmail: 'USER_SET_EMAIL',
		SetName: 'USER_SET_NAME',
		SetOrganization: 'USER_SET_ORGANIZATION',
		SetType: 'USER_SET_TYPE',
		SetUser: 'USER_SET_USER',
	},
};
