import { CircularProgress } from '@mui/material';
import React from 'react';

export interface LoadingProps {
	loading: boolean;
	size?: number;
}

const Loading: React.FunctionComponent<LoadingProps> = ({ children, loading, size }) => {
	const loadingSize = !!size ? size : 150;
	return loading ? <CircularProgress size={loadingSize} /> : <> {children} </>;
};

export { Loading };
