import { Chip, Grid, Typography } from '@mui/material';
import { Application, ApplicationRubric } from 'API';
import { GenericForm } from 'components/forms/generic_form';
import { useSelector } from 'react-redux';
import { updateApplicationResponse } from 'services/application_service';
import { GlobalState } from 'store/global_state';

const ApplicationForm = () => {
	const applicationRubric = useSelector<GlobalState, ApplicationRubric | undefined>(
		(state) => state.organization.applicationRubric
	);
	const applicationTemplate = useSelector<GlobalState, string>(
		(state) => state.organization.applicationRubric?.form ?? ''
	);

	const application = useSelector<GlobalState, Application>((state) => state.application);

	const saveApplication = (shouldSubmit: boolean) => (form: string) => {
		if (!application.id) return;
		updateApplicationResponse(application.id, form, shouldSubmit);
	};

	return (
		<>
			<Typography variant="h3">
				{applicationRubric ? applicationRubric.name : 'Application'}
			</Typography>
			<Grid item sx={{ m: 3 }}>
				<Grid container direction="row" alignItems="center">
					<Typography variant="button" sx={{ m: 1 }}>
						Status:
					</Typography>
					<Chip label="In Progress" color="primary" sx={{ m: 1 }} />
				</Grid>
			</Grid>
			<GenericForm
				template={applicationTemplate}
				current={application.form ?? ''}
				handleSave={saveApplication(false)}
				handleSubmit={saveApplication(true)}
			/>
		</>
	);
};

export { ApplicationForm };
