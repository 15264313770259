import { AppBar, Grid, Link, Toolbar, Typography } from '@mui/material';
import { DarkModeToggle } from 'components/general/dark_mode_toggle';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routing/routes';
import { signOutUser } from 'services/auth_service';
import { GlobalState } from 'store/global_state';
import { UserState } from 'store/sub-states/user_state';
import { routeForUser } from 'utils/user_utility';

const isOnPage = (route: string) => {
	if (route === window.location.pathname) return true;
	return false;
};

const DesktopPublicNavBar = () => {
	const history = useHistory();
	const user = useSelector<GlobalState, UserState>((state) => state.user);

	return (
		<>
			<AppBar position="static" id="welcome-navbar">
				<Toolbar>
					<Grid container direction="row" alignItems="center">
						<Grid item sm={5}>
							<Grid container direction="column" alignItems="flex-start">
								<Grid item>
									<Link
										onClick={() => history.push(Routes.Public.LandingPage)}
										sx={{ cursor: 'pointer', mr: 2, ml: 2 }}
										underline="hover"
										color={isOnPage(Routes.Public.LandingPage) ? 'secondary' : 'inherit'}
										variant="h6">
										Home
									</Link>
									<Link
										onClick={() => history.push(Routes.Public.FaqPage)}
										sx={{ cursor: 'pointer', mr: 2, ml: 2 }}
										underline="hover"
										color={isOnPage(Routes.Public.FaqPage) ? 'secondary' : 'inherit'}
										variant="h6">
										FAQ
									</Link>
									<Link
										onClick={() => history.push(Routes.Public.Pricing)}
										sx={{ cursor: 'pointer', mr: 2, ml: 2 }}
										underline="hover"
										color={isOnPage(Routes.Public.Pricing) ? 'secondary' : 'inherit'}
										variant="h6">
										Pricing
									</Link>
								</Grid>
							</Grid>
						</Grid>

						<Grid item sm={2}>
							<Typography variant="h3">Travult</Typography>
						</Grid>
						<Grid item sm={5}>
							<Grid container direction="column" alignItems="flex-end">
								<Grid item>
									<Grid container direction="row" alignItems="center">
										{user.cognitoID !== '' ? (
											<>
												<Link
													onClick={() => history.push(routeForUser(user))}
													sx={{ cursor: 'pointer', mr: 2, ml: 2 }}
													underline="hover"
													color="inherit"
													variant="h6">
													Dashboard
												</Link>

												<Link
													onClick={() => signOutUser()}
													sx={{ cursor: 'pointer', mr: 2, ml: 2 }}
													underline="hover"
													color="inherit"
													variant="h6">
													Sign Out
												</Link>
											</>
										) : (
											<>
												<Link
													onClick={() => history.push(Routes.Public.Login)}
													sx={{ cursor: 'pointer', mr: 2, ml: 2 }}
													underline="hover"
													color="inherit"
													variant="h6">
													Login
												</Link>
											</>
										)}
										<Link
											sx={{ cursor: 'pointer', mr: 2, ml: 2 }}
											underline="hover"
											color="inherit"
											variant="h6">
											<DarkModeToggle />
										</Link>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</>
	);
};

export { DesktopPublicNavBar };
