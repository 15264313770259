import { FullScreenLoader } from 'components/loading/full_screen_loader';
import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/global_state';

const GlobalLoadingProvider: React.FC = ({ children }) => {
	const globalLoading = useSelector<GlobalState, boolean>((state) => state.general.globalLoading);

	return (
		<>
			<FullScreenLoader loading={globalLoading}>{children}</FullScreenLoader>
		</>
	);
};

export { GlobalLoadingProvider };
