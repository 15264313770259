import { Constants } from 'assets/constants';

export const firstLetterToUpper = (str: string) => {
	const words = str.split(' ');
	const capitalizedWords = words.map((word) => {
		const first = word.charAt(0);
		return first.toUpperCase().concat(word.substr(1));
	});

	return capitalizedWords.join(' ');
};

export const maskPhoneNumber = (number: string) => {
	if (number.length === 0) return number;
	if (number.length < 3) return '(' + number.slice(0, 3);
	if (number.length < 6) return '(' + number.slice(0, 3) + ') ' + number.slice(3, 6);
	return '(' + number.slice(0, 3) + ') ' + number.slice(3, 6) + ' - ' + number.slice(6);
};

export const removeNonNumbers = (text: string) => {
	return text.replace(/\D/g, '');
};

/**
 * Returns whether the current viewport is mobile.
 *
 * @param (Optional) The current width of the viewport.
 *
 * @returns boolean
 */
export const isMobile = (width?: number) => {
	const currentWidth = width ?? window.innerWidth;
	return currentWidth < Constants.Layout.MobileScreenWidth;
};

export const calculateAfterTaxIncome = (income: number) => {
	const TAX_BRACKETS = [
		[0.1, 19750],
		[0.12, 80250],
		[0.22, 171050],
		[0.24, 326600],
		[0.32, 414700],
		[0.35, 622050],
		[0.37, Number.MAX_SAFE_INTEGER],
	];
	const original = income;
	let sum = 0.0;
	let lastLimit = 0.0;

	// 24,800 deduction for MFJ
	if (income <= 24800) return income;
	else income -= 24800;

	for (let rate of TAX_BRACKETS) {
		let block = rate[1] - lastLimit;
		lastLimit = rate[1];

		if (income - block < 0) {
			block = income;
			sum += block * rate[0];
			break;
		} else {
			sum += block * rate[0];
			income -= block;
		}
	}

	return original - Math.round(sum);
};
