import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Team } from 'API';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTeam } from 'services/team_service';
import { GlobalState } from 'store/global_state';
import { UserState } from 'store/sub-states/user_state';

enum TeamView {
	Members = 'members',
	Rubric = 'rubric',
}

const LeaderTeamPage: React.FC = () => {
	const user = useSelector<GlobalState, UserState>((state) => state.user);
	const [currentView, setCurrentView] = useState(TeamView.Members);
	const [team, setTeam] = useState<Team | undefined>(undefined);

	useEffect(() => {
		(async () => {
			if (user.teamID) {
				const teamResponse = await getTeam(user.teamID);
				if (teamResponse) setTeam(teamResponse);
			}
		})();
	}, [user]);

	return (
		<>
			<TravultContainer>
				<TopToolbar
					title="My Team"
					buttons={[
						<ToggleButtonGroup exclusive value={currentView} onChange={(_, value) => setCurrentView(value)}>
							<ToggleButton value={TeamView.Members}>Members</ToggleButton>
							<ToggleButton value={TeamView.Rubric}>Review Rubric</ToggleButton>
						</ToggleButtonGroup>,
					]}
				/>
				{team ? <>{team.name}</> : <>You have not been assigned to a team</>}
			</TravultContainer>
		</>
	);
};

export { LeaderTeamPage };
