import { GraphQLResult } from '@aws-amplify/api';
import { Organization } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { OrganizationQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/

/**************************** RETRIEVE ****************************/
export const getOrganization = async (id: string): Promise<Organization> => {
	// api call
	const organizationResponse = (await API.graphql(
		graphqlOperation(Queries.getOrganization, { id: id })
	)) as GraphQLResult<OrganizationQueries.GetOrganizationQuery>;

	// parse response
	const organization = parseGraphQLResponse(organizationResponse).getOrganization;

	return organization;
};

export const getOrganizations = async (filter: {} | undefined): Promise<Organization[]> => {
	// api call
	const organizationResponse = filter
		? ((await API.graphql(
				graphqlOperation(Queries.listOrganizations, { filter: filter })
		  )) as GraphQLResult<OrganizationQueries.ListOrganizationsQuery>)
		: ((await API.graphql(
				graphqlOperation(Queries.listOrganizations)
		  )) as GraphQLResult<OrganizationQueries.ListOrganizationsQuery>);

	// parse response
	const organizations = parseGraphQLResponse(organizationResponse).listOrganizations.items;

	return organizations;
};

/**************************** UPDATE ****************************/

/**************************** DELETE ****************************/
export const deleteOrganization = async (id: string): Promise<Organization | undefined> => {
	// api call
	const organizationResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteOrganization, { input: { id: id } })
	)) as GraphQLResult<OrganizationQueries.DeleteOrganizationMutation>;

	// parse response
	const organization = parseGraphQLResponse(organizationResponse).deleteOrganization;

	return organization;
};
