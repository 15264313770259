import { Actions } from 'actions';
import { Auth } from 'aws-amplify';
import { store } from 'store';
import { updateReduxData } from './general_service';

export const signInUser = async (email: string, password: string) => {
	await Auth.signIn(email, password);

	// Fetch pertinent user information
	store.dispatch(Actions.General.SetGlobalLoading(true));
	await updateReduxData(email);
	store.dispatch(Actions.General.SetGlobalLoading(false));
};

export const signOutUser = () => {
	Auth.signOut();
	const themePreference = store.getState().general.darkMode;
	store.dispatch(Actions.User.Logout());
	store.dispatch(Actions.General.SetDarkMode(themePreference));
};
