import { Button, Grid } from '@mui/material';
import { Actions } from 'actions';
import { InterviewSlot } from 'API';
import { Calendar } from 'components/calendar/calendar';
import { CustomSwitch } from 'components/forms/custom_switch';
import { InterviewsList } from 'components/interviews/interviews_list';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { Loading } from 'components/loading/loading';
import { isAfter } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInterviewSlots } from 'services/interview_slot_service';
import { GlobalState } from 'store/global_state';
import { InterviewsState } from 'store/sub-states/interviews_state';
import { CreateInterviewDialog } from './create_interview_dialog';

const AdminInterviewsPage: React.FC = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [interviews, setInterviews] = useState<InterviewSlot[]>([]);
	const useCalendar = false;
	const interviewState = useSelector<GlobalState, InterviewsState>((state) => state.interviews);
	const [dialogOpen, setDialogOpen] = useState(false);

	useEffect(() => {
		const loadInterviewSlots = async () => {
			setLoading(true);
			const slots = await getInterviewSlots();
			dispatch(Actions.Interviews.SetInterviewSlots(slots));
			setLoading(false);
		};

		loadInterviewSlots();
	}, [dispatch]);

	useEffect(() => {
		if (interviewState.hideOld) {
			const currentTime = new Date();
			setInterviews(
				interviewState.slots.filter((slot) => isAfter(new Date(slot.startTime ?? new Date()), currentTime))
			);
		} else {
			setInterviews(interviewState.slots);
		}
	}, [interviewState]);

	return (
		<TravultContainer>
			<TopToolbar
				title="Interviews"
				subtitle="Schedule interview slots for your organization"
				buttons={[
					<CustomSwitch
						handleToggle={() =>
							dispatch(Actions.Interviews.ToggleHideOldInterviews(!interviewState.hideOld))
						}
						label="Hide Past"
						value={interviewState.hideOld}
					/>,
					<Button variant="contained" color="secondary" onClick={() => setDialogOpen(true)}>
						Add Time Slots
					</Button>,
				]}
			/>
			<Loading loading={loading}>
				{useCalendar ? (
					<Grid container direction="column">
						<Calendar />
					</Grid>
				) : (
					<Grid container direction="column">
						<InterviewsList interviews={interviews} />
					</Grid>
				)}
			</Loading>

			<CreateInterviewDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} />
		</TravultContainer>
	);
};

export { AdminInterviewsPage };
