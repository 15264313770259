export interface QuestionAnswer {
	question: string;
	answer: string;
}

export const FaqsList: QuestionAnswer[] = [
	{
		question: 'What does Travult do?',
		answer: 'Travult specializes in simplifying the application & review processes for organizations trying to bring on new members. We also aim to make organization management as simple as possible.',
	},
];
