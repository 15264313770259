import { Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Application, Team } from 'API';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { Loading } from 'components/loading/loading';
import { ReviewsTable } from 'components/reviews/reviews_table';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompletedApplications } from 'services/application_service';
import { getTeam } from 'services/team_service';
import { GlobalState } from 'store/global_state';
import { UserState } from 'store/sub-states/user_state';
import { UserCategories } from 'types/user_types';

enum ReviewStatusView {
	Pending = 'members',
	Completed = 'rubric',
}

const LeaderReviewsPage: React.FC = () => {
	const user = useSelector<GlobalState, UserState>((state) => state.user);
	const [loading, setLoading] = useState(false);
	const [statusView, setStatusView] = useState(ReviewStatusView.Pending);
	const [team, setTeam] = useState<Team | undefined>(undefined);
	const [appsPendingReview, setAppsPendingReview] = useState<Application[]>([]);
	const [reviewedApps, setReviewedApps] = useState<Application[]>([]);

	useEffect(() => {
		(async () => {
			if (user.teamID) {
				setLoading(true);
				const teamResponse = await getTeam(user.teamID);
				if (teamResponse) setTeam(teamResponse);
				setLoading(false);
			}
		})();
	}, [user]);

	useEffect(() => {
		const hasReviewFromLeader = (app: Application) =>
			app.User?.UserReviews?.items?.some((rev) => rev?.userID === user.id);

		const hasReviewFromTeam = (app: Application) =>
			app.User?.UserReviews?.items?.some((rev) =>
				team?.Users?.items
					?.filter((u) => u?.type === UserCategories.TeamLeader)
					.some((u) => u?.id === rev?.userID)
			);

		(async () => {
			setLoading(true);
			const submittedApplications = await getCompletedApplications();
			const groupedApps = groupBy(submittedApplications, team ? hasReviewFromTeam : hasReviewFromLeader);
			setAppsPendingReview(groupedApps['false'] ?? []);
			setReviewedApps(groupedApps['true'] ?? []);
			setLoading(false);
		})();
	}, [team, user.id]);

	return (
		<>
			<TravultContainer>
				<TopToolbar
					title="Reviews"
					buttons={[
						<ToggleButtonGroup exclusive value={statusView} onChange={(_, value) => setStatusView(value)}>
							<ToggleButton value={ReviewStatusView.Pending}>Pending</ToggleButton>
							<ToggleButton value={ReviewStatusView.Completed}>Completed</ToggleButton>
						</ToggleButtonGroup>,
					]}
				/>
				<Loading loading={loading}>
					<Paper>
						<ReviewsTable
							completed={statusView === ReviewStatusView.Completed}
							applications={statusView === ReviewStatusView.Completed ? reviewedApps : appsPendingReview}
						/>
					</Paper>
				</Loading>
			</TravultContainer>
		</>
	);
};

export { LeaderReviewsPage };
