import { Button, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useState } from 'react';
import { Loading } from '../loading/loading';

interface RefreshButtonProps {
	triggerLoading: () => void;
	customStyle?: React.CSSProperties;
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ triggerLoading, customStyle }) => {
	const [loading, setLoading] = useState(false);

	const syncDataStore = async () => {
		setLoading(false);
		triggerLoading();
	};

	return (
		<>
			<Tooltip title="Refresh">
				<Button
					style={customStyle}
					variant="contained"
					color="primary"
					onClick={syncDataStore}>
					<Loading loading={loading}>
						<RefreshIcon />
					</Loading>
				</Button>
			</Tooltip>
		</>
	);
};

export { RefreshButton };
