import { FormControl, Grid, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormBuilderAnswer, FormBuilderField, InputTypeEnum } from './form_builder';

interface DisplayFieldProps {
	field: FormBuilderField;
	current?: FormBuilderAnswer;
	saveAnswer?: (id: number, response: string) => void;
	disabled?: boolean;
}

const DisplayField: React.FC<DisplayFieldProps> = ({ field, current, saveAnswer, disabled }) => {
	const [fieldValue, setFieldValue] = useState(current?.response);

	const handleChange = (value: string) => {
		if (saveAnswer) saveAnswer(field.order, value);
		setFieldValue(value);
	};

	return (
		<>
			{field.inputType === InputTypeEnum.Field && (
				<>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={6}>
							<Grid container direction="row" justifyContent="flex-end">
								<Typography variant="h5" sx={{ mr: 4 }}>
									{field.label}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid container direction="row" justifyContent="flex-start">
								<TextField
									variant="outlined"
									disabled={disabled}
									value={fieldValue}
									onChange={
										saveAnswer
											? (event) => handleChange(event.target.value)
											: undefined
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
			{field.inputType === InputTypeEnum.TextArea && (
				<>
					<Grid container direction="column" justifyContent="center" alignItems="center">
						<Typography variant="h5" sx={{ mr: 4 }}>
							{field.label}
						</Typography>
						<TextField
							sx={{ mt: 3, width: '80%' }}
							variant="outlined"
							disabled={disabled}
							multiline
							rows={8}
							maxRows={Infinity}
							value={fieldValue}
							onChange={
								saveAnswer ? (event) => handleChange(event.target.value) : undefined
							}
						/>
					</Grid>
				</>
			)}
			{field.inputType === InputTypeEnum.Switch && (
				<>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={6}>
							<Grid container direction="row" justifyContent="flex-end">
								<Typography variant="h5" sx={{ mr: 4 }}>
									{field.label}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="center">
								<Grid item>
									<Typography
										variant="button"
										color={fieldValue !== 'true' ? 'primary' : undefined}>
										No
									</Typography>
								</Grid>
								<Switch
									color="primary"
									disabled={disabled}
									checked={fieldValue === 'true'}
									onChange={
										saveAnswer
											? (event) => handleChange(`${event.target.checked}`)
											: undefined
									}
								/>
								<Grid item>
									<Typography
										variant="button"
										color={fieldValue === 'true' ? 'primary' : undefined}>
										Yes
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
			{field.inputType === InputTypeEnum.Dropdown && (
				<>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={6}>
							<Grid container direction="row" justifyContent="flex-end">
								<Typography variant="h5" sx={{ mr: 4 }}>
									{field.label}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid container direction="row" justifyContent="flex-start">
								<FormControl style={{ minWidth: 200 }}>
									<Select
										id="demo-simple-select"
										disabled={disabled}
										variant="outlined"
										value={fieldValue}
										onChange={
											saveAnswer
												? (event) =>
														handleChange(event.target.value as string)
												: undefined
										}>
										<MenuItem value={undefined}>None</MenuItem>
										{field.dropdownOptions?.map((input, index) => (
											<MenuItem
												key={index}
												value={input.trim().toUpperCase()}>
												{input.trim().toUpperCase()}
											</MenuItem>
											// TODO: these inputs had a preceeding space - look at when adding the dropdown input type & specifying inputs
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export { DisplayField };
