import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { Actions } from 'actions';
import { SeverityEnum } from 'enums/severity-enum';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store/global_state';
import { AlertState } from 'store/sub-states/general_state';

const makeSuccessAlert = (message: string): AlertState => {
	return {
		open: true,
		message,
		severity: SeverityEnum.Success,
	};
};

const makeInfoAlert = (message: string): AlertState => {
	return {
		open: true,
		message,
		severity: SeverityEnum.Info,
	};
};

const makeErrorAlert = (message: string): AlertState => {
	return {
		open: true,
		message,
		severity: SeverityEnum.Error,
	};
};

const getSeverityString = (severity: SeverityEnum) => {
	switch (severity) {
		case SeverityEnum.Success:
			return 'success';
		case SeverityEnum.Error:
			return 'error';
		default:
			return 'info';
	}
};

const AlertProvider: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const alert = useSelector<GlobalState, AlertState>((state) => state.general.alert);

	const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		const defaultAlert: AlertState = {
			open: false,
			message: '',
			severity: SeverityEnum.Success,
		};
		dispatch(Actions.General.SetAlert(defaultAlert));
	};

	return (
		<>
			<>{children}</>
			<Snackbar
				open={alert?.open ?? false}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert
					onClose={handleClose}
					severity={getSeverityString(alert?.severity)}
					variant="filled">
					<AlertTitle>{alert?.severity}</AlertTitle>
					{alert?.message ?? ''}
				</Alert>
			</Snackbar>
		</>
	);
};

export { AlertProvider, makeErrorAlert, makeInfoAlert, makeSuccessAlert };
