import { Chat, Group, RateReview } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SettingsIcon from '@mui/icons-material/Settings';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Strings } from 'assets/strings';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routing/routes';
import { UserState } from 'store/sub-states/user_state';
import { UserCategories } from 'types/user_types';

interface NavbarSideTabsProps {
	user: UserState;
}

const NavbarSideTabs: React.FC<NavbarSideTabsProps> = ({ user }) => {
	const history = useHistory();

	return (
		<>
			<List>
				{/* Applicant tabs */}
				{user.type === UserCategories.Applicant && (
					<>
						<ListItem button onClick={() => history.push(Routes.Applicant.Dashboard)}>
							<ListItemIcon>
								<DashboardIcon
									sx={{
										color:
											history.location.pathname === Routes.Applicant.Dashboard
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Dashboard} />
						</ListItem>

						<ListItem button onClick={() => history.push(Routes.Applicant.Application)}>
							<ListItemIcon>
								<DescriptionIcon
									sx={{
										color:
											history.location.pathname === Routes.Applicant.Application
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Application} />
						</ListItem>

						<ListItem button onClick={() => history.push(Routes.Applicant.Interview)}>
							<ListItemIcon>
								<ScheduleIcon
									sx={{
										color:
											history.location.pathname === Routes.Applicant.Interview
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Interview} />
						</ListItem>
					</>
				)}

				{/* Member tabs */}
				{user.type === UserCategories.Member && (
					<>
						<ListItem button onClick={() => history.push(Routes.Member.Dashboard)}>
							<ListItemIcon>
								<DashboardIcon
									sx={{
										color:
											history.location.pathname === Routes.Member.Dashboard
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Dashboard} />
						</ListItem>
						<ListItem button onClick={() => history.push(Routes.Member.Team)}>
							<ListItemIcon>
								<Group
									sx={{
										color:
											history.location.pathname === Routes.Member.Team
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary="Team" />
						</ListItem>
					</>
				)}

				{/* Leader tabs */}
				{user.type === UserCategories.TeamLeader && (
					<>
						<ListItem button onClick={() => history.push(Routes.Leader.Dashboard)}>
							<ListItemIcon>
								<DashboardIcon
									sx={{
										color:
											history.location.pathname === Routes.Leader.Dashboard
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Dashboard} />
						</ListItem>
						<ListItem button onClick={() => history.push(Routes.Leader.Interviews)}>
							<ListItemIcon>
								<ScheduleIcon
									sx={{
										color:
											history.location.pathname === Routes.Leader.Interviews
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary="Interviews" />
						</ListItem>
						<ListItem button onClick={() => history.push(Routes.Leader.Reviews)}>
							<ListItemIcon>
								<RateReview
									sx={{
										color:
											history.location.pathname === Routes.Leader.Reviews
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary="Review Applicants" />
						</ListItem>
						<ListItem button onClick={() => history.push(Routes.Leader.Team)}>
							<ListItemIcon>
								<Group
									sx={{
										color:
											history.location.pathname === Routes.Leader.Team
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary="My Team" />
						</ListItem>
					</>
				)}

				{/* Admin tabs */}
				{user.type === UserCategories.Administrator && (
					<>
						<ListItem button onClick={() => history.push(Routes.Admin.Dashboard)}>
							<ListItemIcon>
								<DashboardIcon
									sx={{
										color:
											history.location.pathname === Routes.Admin.Dashboard
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Dashboard} />
						</ListItem>

						<ListItem button onClick={() => history.push(Routes.Admin.Members)}>
							<ListItemIcon>
								<PersonIcon
									sx={{
										color:
											history.location.pathname === Routes.Admin.Members
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Members} />
						</ListItem>

						<ListItem button onClick={() => history.push(Routes.Admin.Teams)}>
							<ListItemIcon>
								<Group
									sx={{
										color:
											history.location.pathname === Routes.Admin.Teams
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary="Teams" />
						</ListItem>

						<ListItem button onClick={() => history.push(Routes.Admin.Rubrics)}>
							<ListItemIcon>
								<DescriptionIcon
									sx={{
										color:
											history.location.pathname === Routes.Admin.Rubrics
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Rubrics} />
						</ListItem>

						<ListItem button onClick={() => history.push(Routes.Admin.Interviews)}>
							<ListItemIcon>
								<ScheduleIcon
									sx={{
										color:
											history.location.pathname === Routes.Admin.Interviews
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Interviews} />
						</ListItem>
					</>
				)}
			</List>
			{/* Extra features - TODO: complete these */}
			{false && (
				<>
					<Divider />
					<List>
						<ListItem button onClick={() => history.push(Routes.General.Chat)}>
							<ListItemIcon>
								<Chat
									sx={{
										color:
											history.location.pathname === Routes.General.Chat
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Chat} />
						</ListItem>

						<ListItem button onClick={() => history.push(Routes.General.Settings)}>
							<ListItemIcon>
								<SettingsIcon
									sx={{
										color:
											history.location.pathname === Routes.General.Settings
												? 'primary.main'
												: 'inherit',
									}}
								/>
							</ListItemIcon>
							<ListItemText primary={Strings.NavBar.Settings} />
						</ListItem>
					</List>
				</>
			)}
		</>
	);
};

export { NavbarSideTabs };
