import { User } from 'API';

export enum UserCategories {
	Applicant = 'applicant',
	Member = 'member',
	TeamLeader = 'leader',
	Administrator = 'administrator',
}

export interface OrganizationUsers {
	admins: User[];
	applicants: User[];
	leaders: User[];
	members: User[];
}
