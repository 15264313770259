import { ActionStrings } from 'actions/action_strings';
import { DefaultAction } from 'actions/action_types';
import { InitialGeneralState } from 'store/initial_state';
import { GeneralState } from 'store/sub-states/general_state';

export const GeneralReducer = (
	state = InitialGeneralState,
	action: DefaultAction
): GeneralState => {
	switch (action.type) {
		case ActionStrings.General.SetAlert:
			return { ...state, alert: action.payload };
		case ActionStrings.General.SetDarkMode:
			return { ...state, darkMode: action.payload };
		case ActionStrings.General.SetRefreshing:
			return { ...state, refreshing: action.payload };
		case ActionStrings.General.SetGlobalLoading:
			return { ...state, globalLoading: action.payload };
		default:
			return state;
	}
};
