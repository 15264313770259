import { Button } from '@mui/material';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/global_state';
import { UserState } from 'store/sub-states/user_state';

const AdminDashboard = () => {
	const user = useSelector<GlobalState, UserState>((state) => state.user);
	const welcomeText = 'Welcome ' + (user.name ? user.name : 'administrator') + '!';

	return (
		<TravultContainer>
			<TopToolbar
				title="Dashboard"
				subtitle={welcomeText}
				buttons={[
					<Button variant="contained" color="secondary">
						Add Widget
					</Button>,
				]}
			/>
		</TravultContainer>
	);
};

export { AdminDashboard };
