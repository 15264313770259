import { User } from 'API';
import { Routes } from 'routing/routes';
import { UserCategories } from '../types/user_types';

export const routeForUser = (user: User | undefined) => {
	if (user) {
		switch (user.type) {
			case UserCategories.Applicant:
				return Routes.Applicant.Dashboard;
			case UserCategories.Member:
				return Routes.Member.Dashboard;
			case UserCategories.TeamLeader:
				return Routes.Leader.Dashboard;
			case UserCategories.Administrator:
				return Routes.Admin.Dashboard;
			default:
				return Routes.Public.LandingPage;
		}
	} else {
		return Routes.General.NewUser;
	}
};
