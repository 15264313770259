import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { GlobalConfigs } from 'configs/global-configs';
import React from 'react';

export const CustomAuth: React.FunctionComponent = ({ children }) => {
	return (
		<React.Fragment>
			{GlobalConfigs.UseAuth ? (
				<AmplifyAuthenticator usernameAlias="email" style={{ width: '100%' }}>
					{children}
				</AmplifyAuthenticator>
			) : (
				<React.Fragment>{children}</React.Fragment>
			)}
		</React.Fragment>
	);
};
