import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Tooltip,
	IconButton,
	SelectChangeEvent,
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { Strings } from 'assets/strings';
import React, { useEffect, useState } from 'react';
import { FormBuilderField, InputTypeEnum } from './form_builder';

const mapInputToDisplayName = (type: InputTypeEnum) => {
	switch (type) {
		case InputTypeEnum.Dropdown:
			return 'Drop Down';
		case InputTypeEnum.Field:
			return 'Short Response';
		case InputTypeEnum.TextArea:
			return 'Long Response';
		case InputTypeEnum.Switch:
			return 'Yes/No';
		default:
			return type;
	}
};

interface NewFieldFormProps {
	addField: (field: FormBuilderField) => void;
}

const NewFieldForm: React.FC<NewFieldFormProps> = ({ addField }) => {
	const [disableAdd, setDisableAdd] = useState(true);
	const [type, setType] = useState<InputTypeEnum>();
	const [label, setLabel] = useState('');
	const [options, setOptions] = useState('');

	const handleTypeChange = (event: SelectChangeEvent<InputTypeEnum>) => {
		const newType = event.target.value as InputTypeEnum;
		setType(newType);
	};

	const handleLabelChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setLabel(event.target.value);
	};

	const handleOptionsChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setOptions(event.target.value);
	};

	const handleAddField = () => {
		// add field
		addField({
			order: 0,
			inputType: type,
			label: label,
			dropdownOptions: options !== '' ? options.split(',') : undefined,
		});

		// reset field values
		setType(undefined);
		setLabel('');
		setOptions('');
	};

	const handleEnterPress = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleAddField();
		}
	};

	useEffect(() => {
		if (type && label.trim() !== '') setDisableAdd(false);
		else setDisableAdd(true);
	}, [type, label]);

	return (
		<>
			<div>
				<Grid
					sx={{
						background: 'background.default',
						borderColor: 'secondary.light',
						borderRadius: 20,
						borderStyle: 'dashed',
						p: 2,
					}}
					container
					direction="row"
					justifyContent="center">
					<FormControl style={{ margin: '0 15px', minWidth: 200 }}>
						<InputLabel id="demo-simple-select-label">Input Type</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={type ?? undefined}
							onChange={handleTypeChange}>
							<MenuItem value={undefined}>None</MenuItem>
							{Object.values(InputTypeEnum).map((input) => (
								<MenuItem value={input}>{mapInputToDisplayName(input)}</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						style={{ margin: '0 15px', minWidth: 200 }}
						label="Field Label"
						value={label}
						onKeyDown={handleEnterPress}
						onChange={handleLabelChange}
						multiline
						rows={1}
						maxRows={Infinity}
					/>
					{type === InputTypeEnum.Dropdown && (
						<TextField
							style={{ margin: '0 15px', minWidth: 200 }}
							label="Dropdown options"
							placeholder="One, Two, Three, etc."
							value={options}
							onKeyDown={handleEnterPress}
							onChange={handleOptionsChange}
							multiline
							rows={1}
							maxRows={Infinity}
						/>
					)}

					<div>
						<Tooltip title={Strings.EditRubric.AddField}>
							<span>
								<IconButton
									disabled={disableAdd}
									onClick={handleAddField}
									size="large">
									<AddCircle color={disableAdd ? 'disabled' : 'secondary'} />
								</IconButton>
							</span>
						</Tooltip>
					</div>
				</Grid>
			</div>
		</>
	);
};

export { NewFieldForm };
