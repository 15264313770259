import { Grid } from '@mui/material';
import { TravultSubtitle } from 'components/text/travult_subtitle';
import { TravultTitle } from 'components/text/travult_title';
import React from 'react';

interface TopToolbarProps {
	title?: string;
	subtitle?: string;
	buttons?: JSX.Element[];
}

const TopToolbar: React.FC<TopToolbarProps> = ({ title, subtitle, buttons }) => {
	return <>
        <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: 20 }}>
            <Grid item>
                <Grid container direction="column" alignItems="flex-start">
                    {title && <TravultTitle>{title}</TravultTitle>}
                    {subtitle && <TravultSubtitle>{subtitle}</TravultSubtitle>}
                </Grid>
            </Grid>

            <Grid item>
                <Grid container direction="row">
                    {buttons?.map((button, index) => (
                        <Grid item key={index}>
                            {button}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </>;
};

export { TopToolbar };
