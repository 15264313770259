import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { Actions } from 'actions';
import { Auth } from 'aws-amplify';
import { PageLayout } from 'components/layout/page_layout';
import { Loading } from 'components/loading/loading';
import { TravultTitle } from 'components/text/travult_title';
import { makeErrorAlert } from 'providers/alert_provider';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routing/routes';
import { GlobalState } from 'store/global_state';
import { validate, ValidationItem, ValidationType } from 'utils/validation_utility';

const ResetPasswordPage: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordErrorText, setPasswordErrorText] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [code, setCode] = useState('');
	const email = useSelector<GlobalState, string | undefined>((state) => state.user.email);

	const validations: ValidationItem[] = [
		{
			valueToValidate: password,
			validations: [
				{
					type: ValidationType.MinLength,
					data: 8,
				},
			],
			setErrorText: setPasswordErrorText,
		},
	];

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		setPasswordErrorText('');
	};

	const handleClickShowPassword = () => setShowPassword(!showPassword);

	const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCode(event.target.value);
	};

	const handleResetPassword = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		setLoading(true);
		const passedValidations = validate(validations);
		if (passedValidations) {
			if (!email) {
				dispatch(Actions.General.SetAlert(makeErrorAlert('Failed to retrieve email')));
				return;
			}
			await Auth.forgotPasswordSubmit(email, code, password);
			history.push(Routes.Public.Login);
		}
		setLoading(false);
	};

	return (
		<>
			<TravultTitle>Reset Password</TravultTitle>
			<PageLayout>
				<form>
					<Paper
						sx={{
							m: 1,
							p: 5,
							minWidth: 450,
						}}>
						{/* CODE */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Verification Code"
									type="text"
									value={code}
									onChange={handleCodeChange}
									required
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Lock />
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>
						{/* PASSWORD */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Password"
									type={showPassword ? 'text' : 'password'}
									value={password}
									onChange={handlePasswordChange}
									required
									error={passwordErrorText !== ''}
									helperText={passwordErrorText}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Lock />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													// onMouseDown={handleMouseDownPassword}
													onClick={handleClickShowPassword}
													size="large">
													{showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>
						{/* CREATE ACCOUNT / SIGN IN */}
						<Grid item sx={{ mt: 5 }}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center">
								<Grid item>
									<Typography variant="caption" color="textPrimary">
										<Link onClick={() => history.push(Routes.Public.Login)}>
											Back to Login
										</Link>
									</Typography>
								</Grid>
								<Grid item>
									<Loading loading={loading} size={40}>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											onClick={handleResetPassword}>
											Change Password
										</Button>
									</Loading>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</form>
			</PageLayout>
		</>
	);
};

export { ResetPasswordPage };
