import { Divider, Paper } from '@mui/material';
import React, { useState } from 'react';
import * as dateFns from 'date-fns';
import { CalendarHeader } from './calendar_header';
import { CalendarMonth } from './calendar_month';
import { CalendarWeek } from './calendar_week';
import { CalendarDay } from './calendar_day';

export enum CalendarView {
	Month,
	Week,
	Day,
}

const Calendar: React.FC = () => {
	const [currentDate, setCurrentDate] = useState(new Date());
	const [currentView, setCurrentView] = useState(CalendarView.Month);

	const setToday = () => {
		setCurrentDate(new Date());
	};

	const nextMonth = () => {
		setCurrentDate(dateFns.addMonths(currentDate, 1));
	};

	const prevMonth = () => {
		setCurrentDate(dateFns.subMonths(currentDate, 1));
	};

	const nextWeek = () => {
		setCurrentDate(dateFns.addWeeks(currentDate, 1));
	};

	const prevWeek = () => {
		setCurrentDate(dateFns.subWeeks(currentDate, 1));
	};

	const nextDay = () => {
		setCurrentDate(dateFns.addDays(currentDate, 1));
	};

	const prevDay = () => {
		setCurrentDate(dateFns.subDays(currentDate, 1));
	};

	return (
		<>
			<Paper>
				<CalendarHeader
					currentDate={currentDate}
					currentView={currentView}
					goNext={
						currentView === CalendarView.Month
							? nextMonth
							: currentView === CalendarView.Week
							? nextWeek
							: nextDay
					}
					goPrevious={
						currentView === CalendarView.Month
							? prevMonth
							: currentView === CalendarView.Week
							? prevWeek
							: prevDay
					}
					goToday={setToday}
					handleViewChange={(newView: CalendarView) => setCurrentView(newView)}
				/>
				<Divider />
				{currentView === CalendarView.Month && <CalendarMonth currentDate={currentDate} />}
				{currentView === CalendarView.Week && <CalendarWeek currentDate={currentDate} />}
				{currentView === CalendarView.Day && <CalendarDay currentDate={currentDate} />}
			</Paper>
		</>
	);
};

export { Calendar };
