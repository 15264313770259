import { Email } from '@mui/icons-material';
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	Link,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { Actions } from 'actions';
import { Auth } from 'aws-amplify';
import { PageLayout } from 'components/layout/page_layout';
import { Loading } from 'components/loading/loading';
import { TravultTitle } from 'components/text/travult_title';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routing/routes';
import { validate, ValidationItem, ValidationType } from 'utils/validation_utility';

const ForgotPasswordPage: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [emailErrorText, setEmailErrorText] = useState('');

	const validations: ValidationItem[] = [
		{
			valueToValidate: email,
			validations: [
				{
					type: ValidationType.Email,
				},
			],
			setErrorText: setEmailErrorText,
		},
	];

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setEmailErrorText('');
	};

	const handleResetPassword = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		setLoading(true);
		const passedValidations = validate(validations);
		if (passedValidations) {
			await Auth.forgotPassword(email);
			dispatch(Actions.User.SetEmail(email));
			history.push(Routes.Public.ResetPassword);
		}
		setLoading(false);
	};

	return (
		<>
			<TravultTitle>Reset Password</TravultTitle>
			<PageLayout>
				<form>
					<Paper
						sx={{
							m: 1,
							p: 5,
							minWidth: 450,
						}}>
						{/* EMAIL */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Email"
									type="email"
									value={email}
									onChange={handleEmailChange}
									required
									error={emailErrorText !== ''}
									helperText={emailErrorText}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Email />
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>
						{/* CREATE ACCOUNT / SIGN IN */}
						<Grid item sx={{ mt: 5 }}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center">
								<Grid item>
									<Typography variant="caption" color="textPrimary">
										<Link onClick={() => history.push(Routes.Public.Login)}>
											Back to Login
										</Link>
									</Typography>
								</Grid>
								<Grid item>
									<Loading loading={loading} size={40}>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											onClick={handleResetPassword}>
											Send Code
										</Button>
									</Loading>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</form>
			</PageLayout>
		</>
	);
};

export { ForgotPasswordPage };
