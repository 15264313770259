import { Actions } from 'actions';
import { InterviewSlot } from 'API';
import { CustomSwitch } from 'components/forms/custom_switch';
import { InterviewsList } from 'components/interviews/interviews_list';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { Loading } from 'components/loading/loading';
import { isAfter } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInterviewSlots } from 'services/interview_slot_service';
import { GlobalState } from 'store/global_state';
import { InterviewsState } from 'store/sub-states/interviews_state';
import { UserState } from 'store/sub-states/user_state';

const LeaderInterviewsPage: React.FC = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [interviews, setInterviews] = useState<InterviewSlot[]>([]);
	const interviewState = useSelector<GlobalState, InterviewsState>((state) => state.interviews);
	const user = useSelector<GlobalState, UserState>((state) => state.user);

	useEffect(() => {
		const loadInterviewSlots = async () => {
			setLoading(true);
			const slots = await getInterviewSlots();
			dispatch(Actions.Interviews.SetInterviewSlots(slots));
			setLoading(false);
		};

		loadInterviewSlots();
	}, [dispatch, user]);

	useEffect(() => {
		if (interviewState.hideOld) {
			const currentTime = new Date();
			setInterviews(
				interviewState.slots.filter((slot) => isAfter(new Date(slot.startTime ?? new Date()), currentTime))
			);
		} else {
			setInterviews(interviewState.slots);
		}
	}, [interviewState]);

	return (
		<>
			<TravultContainer>
				<TopToolbar
					title="Interviews"
					subtitle="Sign up for interviews"
					buttons={[
						<CustomSwitch
							handleToggle={() =>
								dispatch(Actions.Interviews.ToggleHideOldInterviews(!interviewState.hideOld))
							}
							label="Hide Past"
							value={interviewState.hideOld}
						/>,
					]}
				/>
				<Loading loading={loading}>
					<InterviewsList interviews={interviews} user={user} />
				</Loading>
			</TravultContainer>
		</>
	);
};

export { LeaderInterviewsPage };
