import { GraphQLResult } from '@aws-amplify/api';
import { InterviewSlotUser } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import { InterviewSlotUserQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/
export const createInterviewSlotUser = async (interviewId: string, userId: string) => {
	// api call
	const interviewSlotUserResponse = (await API.graphql(
		graphqlOperation(Mutations.createInterviewSlotUser, {
			input: { interviewslotID: interviewId, userID: userId },
		})
	)) as GraphQLResult<InterviewSlotUserQueries.CreateInterviewSlotUserMutation>;

	// parse response
	const interviewSlotUser =
		parseGraphQLResponse(interviewSlotUserResponse).createInterviewSlotUser;

	return interviewSlotUser;
};

/**************************** RETRIEVE ****************************/

/**************************** Update ****************************/

/**************************** DELETE ****************************/
export const deleteInterviewSlotUser = async (
	id: string
): Promise<InterviewSlotUser | undefined> => {
	// api call
	const interviewSlotResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteInterviewSlotUser, { input: { id: id } })
	)) as GraphQLResult<InterviewSlotUserQueries.DeleteInterviewSlotUserMutation>;

	// parse response
	const interviewSlot = parseGraphQLResponse(interviewSlotResponse).deleteInterviewSlotUser;

	return interviewSlot;
};
