import { Create } from '@mui/icons-material';
import { Button, Grid, InputAdornment, Paper, TextField, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Actions } from 'actions';
import { ApplicationRubric } from 'API';
import { FormBuilder } from 'components/form_builder/form_builder';
import { Loading } from 'components/loading/loading';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Routes } from 'routing/routes';
import {
	createApplicationRubric,
	updateApplicationRubric,
} from 'services/application_rubric_service';
import { GlobalState } from 'store/global_state';
import { OrganizationState } from 'store/sub-states/org_state';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			padding: theme.spacing(3),
		},
		buffer: {
			margin: theme.spacing(3),
		},
		nameInput: {
			minWidth: '20%',
		},
		inputOverride: {
			color: theme.palette.primary.contrastText,
			borderColor: theme.palette.primary.contrastText,
		},
		header: {
			background: theme.palette.primary.main,
			margin: 0,
			padding: theme.spacing(1),
		},
	})
);

const EditApplicationRubricPage: React.FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const [rubric, setRubric] = useState<ApplicationRubric | undefined>();
	const dispatch = useDispatch();
	const organization = useSelector<GlobalState, OrganizationState>((state) => state.organization);
	const [rubricName, setRubricName] = useState(organization.applicationRubric?.name ?? '');
	const [form, setForm] = useState(organization.applicationRubric?.form ?? '');
	const [error, setError] = useState('');
	const [changed, setChanged] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async () => {
		setLoading(true);

		// error checks
		if (!rubric) {
			setError('Could not retrieve rubric');
			setLoading(false);
			return;
		}

		if (rubricName === '') {
			setError('Rubric Name is required');
			setLoading(false);
			return;
		}

		if (!rubric.id) {
			setLoading(false);
			return;
		}

		const updatedRubric = await updateApplicationRubric(rubric.id, rubricName, form);

		dispatch(Actions.Organization.SetApplicationRubric(updatedRubric));

		setLoading(false);
		history.push(Routes.Admin.Rubrics);
	};

	const getForm = (newForm: string) => {
		setChanged(true);
		setForm(newForm);
	};

	useEffect(() => {
		const createApplicationRubricMethod = async () => {
			if (!organization.id) return;

			const newRubric = await createApplicationRubric(organization.id);
			dispatch(Actions.Organization.SetApplicationRubric(newRubric));
			setLoading(false);
		};

		setLoading(true);
		if (organization.applicationRubric) {
			setRubric(organization.applicationRubric);
			setLoading(false);
		} else {
			createApplicationRubricMethod();
		}
	}, [setRubric, dispatch, organization]);

	return (
		<>
			<Loading loading={loading}>
				{rubric && (
					<>
						<Typography className={classes.buffer} variant="h3">
							Edit Application Rubric
						</Typography>

						<div className={classes.header}>
							<TextField
								className={classes.nameInput}
								placeholder="Rubric Name"
								value={rubricName}
								variant="outlined"
								InputProps={{
									className: classes.inputOverride,
									startAdornment: (
										<InputAdornment position="start">
											<Create />
										</InputAdornment>
									),
								}}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setRubricName(event.target.value);
									setChanged(true);
								}}
							/>
						</div>

						<Paper className={classes.content}>
							<Grid container direction="column">
								{error !== '' && (
									<Typography variant="h6" color="error">
										{error}
									</Typography>
								)}
								<FormBuilder currentForm={form} populateForm={getForm} />
								<div>
									<Button
										variant="contained"
										color="primary"
										onClick={handleSubmit}
										disabled={!changed}>
										Save Rubric
									</Button>
								</div>
							</Grid>
						</Paper>
					</>
				)}
			</Loading>
		</>
	);
};

export { EditApplicationRubricPage };
