import { Business, Email, Lock, Phone, Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Autocomplete,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { Actions } from 'actions';
import { Organization } from 'API';
import { Auth } from 'aws-amplify';
import { PageLayout } from 'components/layout/page_layout';
import { Loading } from 'components/loading/loading';
import { TravultTitle } from 'components/text/travult_title';
import { SeverityEnum } from 'enums/severity-enum';
import { Guid } from 'guid-typescript';
import { makeErrorAlert } from 'providers/alert_provider';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routing/routes';
import { getOrganizations } from 'services/organization_service';
import { createUser } from 'services/user_service';
import { maskPhoneNumber, removeNonNumbers } from 'utils/general_utility';
import { validate, ValidationItem, ValidationType } from 'utils/validation_utility';

const SignUpPage: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [emailErrorText, setEmailErrorText] = useState('');
	const [password, setPassword] = useState('');
	const [passwordErrorText, setPasswordErrorText] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneErrorText, setPhoneErrorText] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	// Autocomplete org search
	const [open, setOpen] = useState(false);
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>(undefined);
	const loadingOrgs = open && organizations.length === 0;

	const validations: ValidationItem[] = [
		{
			valueToValidate: email,
			validations: [
				{
					type: ValidationType.Email,
				},
			],
			setErrorText: setEmailErrorText,
		},
		{
			valueToValidate: password,
			validations: [
				{
					type: ValidationType.MinLength,
					data: 8,
				},
			],
			setErrorText: setPasswordErrorText,
		},
		{
			valueToValidate: phone,
			validations: [
				{
					type: ValidationType.ExactLength,
					data: 10,
				},
			],
			setErrorText: setPhoneErrorText,
		},
	];

	React.useEffect(() => {
		if (!loadingOrgs) {
			return undefined;
		}

		(async () => {
			const response = await getOrganizations({ confirmed: { eq: true } });
			setOrganizations(response);
		})();
	}, [loadingOrgs]);

	/****************
	 * HANDLERS
	 ****************/
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setEmailErrorText('');
	};

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		setPasswordErrorText('');
	};

	const handleClickShowPassword = () => setShowPassword(!showPassword);

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (removeNonNumbers(event.target.value).length > 10) return;
		setPhone(removeNonNumbers(event.target.value));
		setPhoneErrorText('');
	};

	const handlePhoneKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Backspace' && (phone.length === 3 || phone.length === 6))
			setPhone(phone.slice(0, phone.length - 1));
	};

	const handleSignUp = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		setLoading(true);
		const passedValidations = validate(validations);
		if (passedValidations && selectedOrg) {
			try {
				const username = email;
				const phone_number = '+1' + phone;
				const { user } = await Auth.signUp({
					username,
					password,
					attributes: {
						email,
						phone_number,
					},
				});

				if (!user) throw new Error('Error authenticating - Please try again later.');
				dispatch(Actions.User.SetEmail(user.getUsername()));

				if (!selectedOrg.id) {
					dispatch(
						Actions.General.SetAlert(makeErrorAlert('Failed to set organization'))
					);
					return;
				}

				// FIXME: Create new user
				const newUser = await createUser(
					selectedOrg.id,
					Guid.createEmpty().toString(),
					email
				);
				dispatch(Actions.User.SetUser(newUser));
				history.push(Routes.Public.ConfirmSignUp);
			} catch (error) {
				dispatch(
					Actions.General.SetAlert({
						message: (error as Error).message,
						open: true,
						severity: SeverityEnum.Error,
					})
				);
			}
		}
		setLoading(false);
	};

	return (
		<>
			<PageLayout>
				<TravultTitle>Sign Up</TravultTitle>
				<form>
					<Paper
						sx={{
							margin: 1,
							padding: 5,
							minWidth: 450,
						}}>
						{/* EMAIL */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Email"
									type="email"
									value={email}
									onChange={handleEmailChange}
									required
									error={emailErrorText !== ''}
									helperText={emailErrorText}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Email />
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>

						{/* PASSWORD */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Password"
									type={showPassword ? 'text' : 'password'}
									value={password}
									onChange={handlePasswordChange}
									required
									error={passwordErrorText !== ''}
									helperText={passwordErrorText}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Lock />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													// onMouseDown={handleMouseDownPassword}
													onClick={handleClickShowPassword}
													size="large">
													{showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>

						{/* PHONE NUMBER */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Phone Number"
									type="tel"
									value={maskPhoneNumber(phone)}
									onChange={handlePhoneChange}
									onKeyDown={handlePhoneKeyDown}
									required
									error={phoneErrorText !== ''}
									helperText={phoneErrorText}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Phone />
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>

						{/* ORGANIZATION LOOK UP */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<Autocomplete
									open={open}
									onOpen={() => {
										setOpen(true);
									}}
									onClose={() => {
										setOpen(false);
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									getOptionLabel={(option) => option.name ?? 'Unknown'}
									options={organizations}
									loading={loadingOrgs}
									onChange={(_, newValue) => {
										setSelectedOrg(newValue ?? undefined);
										if (newValue)
											dispatch(
												Actions.Organization.SetOrganization(newValue)
											);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Organization"
											required
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<InputAdornment position="start">
														<Business />
													</InputAdornment>
												),
												endAdornment: (
													<React.Fragment>
														{loadingOrgs ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>

						{/* CREATE ACCOUNT / SIGN IN */}
						<Grid item sx={{ mt: 5 }}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center">
								<Grid item>
									<Typography variant="caption" color="textPrimary">
										Have an account?{' '}
										<Link onClick={() => history.push(Routes.Public.Login)}>
											Login
										</Link>
									</Typography>
								</Grid>
								<Grid item>
									<Loading loading={loading} size={40}>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											onClick={handleSignUp}>
											Create Account
										</Button>
									</Loading>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</form>
			</PageLayout>
		</>
	);
};

export { SignUpPage };
