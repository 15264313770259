import { DatePicker, TimePicker } from '@mui/lab';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { Actions } from 'actions';
import { InterviewSlot } from 'API';
import * as dateFns from 'date-fns';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { makeErrorAlert, makeSuccessAlert } from 'providers/alert_provider';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as intSlotService from 'services/interview_slot_service';
import { GlobalState } from 'store/global_state';
import { OrganizationState } from 'store/sub-states/org_state';
import { getRoundedDate } from 'utils/date_utility';

interface CreateInterviewDialogProps {
	open: boolean;
	handleClose: () => void;
}

const CreateInterviewDialog: React.FC<CreateInterviewDialogProps> = ({ open, handleClose }) => {
	const dispatch = useDispatch();
	const organization = useSelector<GlobalState, OrganizationState>((state) => state.organization);
	const [interviewTitle, setinterviewTitle] = useState('');
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [startTime, setStartTime] = useState(getRoundedDate(15, new Date(), 'ceil'));
	const [endTime, setEndTime] = useState(dateFns.addMinutes(startTime, 30));
	const [isBulk, setIsBulk] = useState(false);
	const [interval, setInterval] = useState(30);

	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setinterviewTitle(event.target.value);

	const handleDateChange = (newDate: Date | null) => {
		setSelectedDate(newDate ?? new Date());
	};

	const handleStartTimeChange = (newDate: Date | null) => {
		setStartTime(newDate ?? new Date());
		if (!dateFns.isAfter(endTime, newDate ?? new Date()))
			setEndTime(dateFns.addMinutes(startTime, 30));
	};

	const handleEndTimeChange = (newDate: Date | null) => setEndTime(newDate ?? new Date());

	const handleCancel = () => {
		const currentTime = getRoundedDate(15, new Date(), 'ceil');
		setSelectedDate(currentTime);
		setStartTime(currentTime);
		setEndTime(dateFns.addMinutes(currentTime, 30));
	};

	const handleAdd = async () => {
		const title = interviewTitle ? interviewTitle : 'New Interview';

		const start = new Date(
			selectedDate.getFullYear(),
			selectedDate.getMonth(),
			selectedDate.getDate(),
			startTime.getHours(),
			startTime.getMinutes()
		);

		const end = new Date(
			selectedDate.getFullYear(),
			selectedDate.getMonth(),
			selectedDate.getDate(),
			endTime.getHours(),
			endTime.getMinutes()
		);

		if (!organization.id) return;

		if (isBulk) {
			// divide into multiple slots
			const difference = differenceInMinutes(end, start);
			if (difference % interval !== 0) {
				dispatch(
					Actions.General.SetAlert(
						makeErrorAlert(
							"Selected interval length isn't compatible with time selection"
						)
					)
				);
				return;
			}

			const interviewsCount = difference / interval;
			const responses: Promise<InterviewSlot>[] = [];
			for (let index = 0; index < interviewsCount; index++) {
				responses.push(
					intSlotService.createInterviewSlot(
						organization.id,
						addMinutes(start, index * interval).toISOString(),
						addMinutes(start, (index + 1) * interval).toISOString(),
						title + ` - ${index + 1}`
					)
				);
			}

			const results = await Promise.all(responses);

			results.forEach((slot) => {
				dispatch(Actions.Interviews.AddInterviewSlot(slot));
			});
			dispatch(
				Actions.General.SetAlert(makeSuccessAlert(`Created ${results.length} interviews`))
			);
		} else {
			// single insert
			const slot = await intSlotService.createInterviewSlot(
				organization.id,
				start.toISOString(),
				end.toISOString(),
				title
			);
			dispatch(Actions.Interviews.AddInterviewSlot(slot));
			dispatch(
				Actions.General.SetAlert(makeSuccessAlert(`Created interview '${slot.name}'`))
			);
		}
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>New Interviews</DialogTitle>
				<DialogContent>
					<Grid container direction="column" spacing={3}>
						<Grid item>
							{/* Title Input */}
							<Grid container direction="row" spacing={3} alignItems="center">
								<Grid item>
									<TextField
										label="Title"
										value={interviewTitle}
										onChange={handleTitleChange}
										sx={{ mt: 2, minWidth: 246 }}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							{/* Date Input */}
							<Grid container direction="row" spacing={3} alignItems="center">
								<Grid item>
									<DatePicker
										value={selectedDate}
										disablePast
										onChange={handleDateChange}
										label="Date"
										showTodayButton
										renderInput={(props) => <TextField {...props} />}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							{/* Time Input */}
							<Grid container direction="row" spacing={3} alignItems="center">
								<Grid item>
									<TimePicker
										value={startTime}
										onChange={handleStartTimeChange}
										label="Start"
										minutesStep={15}
										renderInput={(props) => <TextField {...props} />}
									/>
								</Grid>
								<Grid item>
									<TimePicker
										value={endTime}
										onChange={handleEndTimeChange}
										label="End"
										minutesStep={15}
										renderInput={(props) => <TextField {...props} />}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							{/* Bulk Add Option */}
							<FormControlLabel
								control={
									<Checkbox
										checked={isBulk}
										onChange={(_, checked) => setIsBulk(checked)}
									/>
								}
								label="Add multiple slots"
							/>
						</Grid>
						{isBulk && (
							<Grid item>
								{/* Interval Input */}
								<Grid container direction="row" spacing={3} alignItems="center">
									<Grid item>
										<FormControl sx={{ minWidth: 246 }}>
											<InputLabel id="bulk-interval-select-label">
												Interval Length (Minutes)
											</InputLabel>
											<Select
												labelId="bulk-interval-select-label"
												id="bulk-interval-select"
												value={interval}
												label="Interval Length (Minutes)"
												onChange={(event) =>
													setInterval(event.target.value as number)
												}>
												<MenuItem value={15}>15</MenuItem>
												<MenuItem value={30}>30</MenuItem>
												<MenuItem value={45}>45</MenuItem>
												<MenuItem value={60}>60</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							handleClose();
							handleCancel();
						}}
						color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => {
							handleClose();
							handleAdd();
						}}
						color="secondary">
						Add
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export { CreateInterviewDialog };
