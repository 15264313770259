import { Button, Chip, Grid, Typography } from '@mui/material';
import { Actions } from 'actions';
import { InterviewSlot, User } from 'API';
import { format, isBefore } from 'date-fns';
import { makeErrorAlert, makeSuccessAlert } from 'providers/alert_provider';
import { useDispatch } from 'react-redux';
import { deleteInterviewSlot, getInterviewSlot } from 'services/interview_slot_service';
import { createInterviewSlotUser, deleteInterviewSlotUser } from 'services/interview_slot_user_service';
import { getUser } from 'services/user_service';
import { UserCategories } from 'types/user_types';
import { AddUserSearch } from './add_user_search';

interface InterviewsListItemProps {
	interview?: InterviewSlot;
	user?: User;
}

const InterviewsListItem: React.FC<InterviewsListItemProps> = ({ interview, user }) => {
	const dispatch = useDispatch();
	const isAdmin = user?.type === UserCategories.Administrator;

	const hasPast = isBefore(new Date(interview?.startTime ?? ''), new Date());

	const updateUser = async () => {
		if (!isAdmin) {
			const userResponse = await getUser(user?.id ?? '');
			if (userResponse) dispatch(Actions.User.SetUser(userResponse));
		}
	};

	const deleteInterview = async () => {
		if (!interview?.id) return;

		const slot = await deleteInterviewSlot(interview.id);
		if (slot) {
			dispatch(Actions.Interviews.RemoveInterviewSlot(slot));
			dispatch(Actions.General.SetAlert(makeSuccessAlert('Deleted interview')));
		} else {
			dispatch(Actions.General.SetAlert(makeErrorAlert('Failed to delete interview')));
		}
	};

	const handleAddUser = async (value: User | null) => {
		if (!value || !value.id) return;
		if (!interview?.id) return;

		if (interview.InterviewSlotUsers?.items?.some((slotUser) => slotUser?.user?.id === value.id)) {
			dispatch(Actions.General.SetAlert(makeErrorAlert('User already assigned to interview')));
			return;
		}

		const connectionEntry = await createInterviewSlotUser(interview.id, value.id);
		if (connectionEntry && connectionEntry.interviewslotID) {
			const slot = await getInterviewSlot(connectionEntry.interviewslotID);
			dispatch(Actions.Interviews.UpdateInterviewSlot(slot));
			if (!isAdmin) await updateUser();
			dispatch(
				Actions.General.SetAlert(makeSuccessAlert(isAdmin ? 'Added ' + value.name : 'Signed up for interview'))
			);
		} else dispatch(Actions.General.SetAlert(makeErrorAlert('Failed to add user')));
	};

	const handleRemoveUser = async (connectionID: string | undefined) => {
		if (!connectionID || !interview?.id) {
			dispatch(Actions.General.SetAlert(makeErrorAlert('Failed to remove user')));
			return;
		}

		await deleteInterviewSlotUser(connectionID);
		const updatedSlot = await getInterviewSlot(interview.id);
		dispatch(Actions.Interviews.UpdateInterviewSlot(updatedSlot));
		dispatch(Actions.General.SetAlert(makeSuccessAlert('Removed user from interview')));
	};

	const removeSlotUser = async (value: User) => {
		const slotUser = value.interviewslots?.items?.find((slotUser) => slotUser?.interviewslotID === interview?.id);

		if (!slotUser || !slotUser.id) return;

		const slotUserResponse = await deleteInterviewSlotUser(slotUser.id);
		if (slotUserResponse) {
			const updatedInterview = await getInterviewSlot(slotUserResponse.interviewslotID ?? '');
			dispatch(Actions.Interviews.UpdateInterviewSlot(updatedInterview));
			if (!isAdmin) await updateUser();
			dispatch(Actions.General.SetAlert(makeSuccessAlert('Withdrew from the interview time.')));
		} else {
			dispatch(Actions.General.SetAlert(makeErrorAlert('Failed to withdraw from interview')));
		}
	};

	return (
		<>
			<Grid container direction="row" alignItems="center" justifyContent="space-between">
				<Grid item>
					<Grid container direction="column">
						<Grid item>
							<Typography variant="h5">{interview?.name ?? 'Interview'}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2" color="text.secondary">
								{format(new Date(interview?.startTime ?? ''), 'p') +
									' - ' +
									format(new Date(interview?.endTime ?? ''), 'p')}
							</Typography>
						</Grid>
						<Grid item></Grid>
					</Grid>
				</Grid>
				{!user && (
					<Grid item>
						<Grid container direction="column">
							<Grid item>
								{interview?.InterviewSlotUsers?.items?.map((slotUser) => (
									<>
										<Chip
											sx={{ m: 1 }}
											label={slotUser?.user?.name ?? 'Unknown'}
											color={
												slotUser?.user?.type === UserCategories.Applicant
													? 'primary'
													: undefined
											}
											onDelete={() => handleRemoveUser(slotUser?.id)}
										/>
									</>
								))}
							</Grid>
							<Grid item>
								<AddUserSearch handleSelectUser={handleAddUser} />
							</Grid>
						</Grid>
					</Grid>
				)}
				<Grid item>
					{user ? (
						<>
							{interview?.InterviewSlotUsers?.items?.some((slotUser) => slotUser?.userID === user.id) ? (
								<Button
									variant="contained"
									color="error"
									disabled={hasPast}
									onClick={() => removeSlotUser(user)}>
									Withdraw
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={hasPast}
									onClick={() => handleAddUser(user)}>
									Sign Up
								</Button>
							)}
						</>
					) : (
						<Button variant="contained" color="error" onClick={() => deleteInterview()}>
							Delete
						</Button>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export { InterviewsListItem };
