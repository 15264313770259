import { Grid } from '@mui/material';
import React from 'react';
import { ApplicationRubricPage } from './application/application_rubric';
import { DefaultReviewRubricPage } from './review/default_review_rubric';
import { InterviewRubricPage } from './interview/interview_rubric';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';

const RubricsPage: React.FC = () => {
	return (
		<TravultContainer>
			<TopToolbar
				title="Rubrics"
				subtitle="Build the rubrics used to join your organization"
			/>
			<Grid container direction="column" spacing={5}>
				<Grid item>
					<Grid container direction="row" spacing={5}>
						<Grid item xs={12} md={6}>
							<ApplicationRubricPage />
						</Grid>
						<Grid item xs={12} md={6}>
							<InterviewRubricPage />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<DefaultReviewRubricPage />
				</Grid>
			</Grid>
		</TravultContainer>
	);
};

export { RubricsPage };
