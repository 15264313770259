import { GraphQLResult } from '@aws-amplify/api';
import { Interview } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { InterviewQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/

/**************************** RETRIEVE ****************************/
export const getInterview = async (id: string): Promise<Interview> => {
	// api call
	const interviewResponse = (await API.graphql(
		graphqlOperation(Queries.getInterview, { id: id })
	)) as GraphQLResult<InterviewQueries.GetInterviewQuery>;

	// parse response
	const interview = parseGraphQLResponse(interviewResponse).getInterview;

	return interview;
};

export const getInterviews = async (): Promise<Interview[]> => {
	// api call
	const interviewResponse = (await API.graphql(
		graphqlOperation(Queries.listInterviews)
	)) as GraphQLResult<InterviewQueries.ListInterviewsQuery>;

	// parse response
	const interviews = parseGraphQLResponse(interviewResponse).listInterviews.items;

	return interviews;
};
/**************************** Update ****************************/

/**************************** DELETE ****************************/
export const deleteInterview = async (id: string): Promise<Interview | undefined> => {
	// api call
	const interviewResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteInterview, { input: { id: id } })
	)) as GraphQLResult<InterviewQueries.DeleteInterviewMutation>;

	// parse response
	const interview = parseGraphQLResponse(interviewResponse).deleteInterview;

	return interview;
};
