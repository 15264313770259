import { Interview, User } from 'API';
import { UserCategories } from 'types/user_types';

export abstract class InterviewUtility {
	public static getApplicant(interview: Interview): User | undefined {
		const applicants = interview.users?.items?.filter(
			(connection) => connection?.user?.type === UserCategories.Applicant
		);

		return applicants && applicants.length > 0 ? applicants[0]?.user : undefined;
	}

	public static getApplicantName(interview: Interview): string {
		const applicant = this.getApplicant(interview);
		return applicant ? applicant.name ?? '' : '';
	}
}
