import { GraphQLResult } from '@aws-amplify/api';
import { User } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { UserQueries } from 'types/api_types';
import { UserCategories } from 'types/user_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/
export const createUser = async (
	organizationID: string,
	cognitoID: string,
	email: string,
	name: string = ''
) => {
	const newUser: Omit<User, '__typename'> = {
		cognitoID,
		name,
		email,
		organizationID,
		type: UserCategories.Applicant,
		confirmed: false,
	};
	// api call
	const userResponse = (await API.graphql(
		graphqlOperation(Mutations.createUser, { input: newUser })
	)) as GraphQLResult<UserQueries.CreateUserMutation>;

	// parse response
	const user = parseGraphQLResponse(userResponse).createUser;

	// return created user
	return user;
};

/**************************** RETRIEVE ****************************/
export const getUser = async (id: string): Promise<User> => {
	// api call
	const userResponse = (await API.graphql(
		graphqlOperation(Queries.getUser, { id: id })
	)) as GraphQLResult<UserQueries.GetUserQuery>;

	// parse response
	const user = parseGraphQLResponse(userResponse).getUser;

	return user;
};

export const getUsers = async (): Promise<User[]> => {
	// api call
	const userResponse = (await API.graphql(
		graphqlOperation(Queries.listUsers)
	)) as GraphQLResult<UserQueries.ListUsersQuery>;

	// parse response
	const users = parseGraphQLResponse(userResponse).listUsers.items;

	return users;
};

export const getUserByEmail = async (email: string): Promise<User> => {
	const users = (await getUsers()).filter((user) => user.email === email);

	if (users.length === 0) throw new Error("Couldn't find user with matching email.");

	return users[0];
};

export const getUserByCognitoId = async (cognitoId: string): Promise<User> => {
	const users = (await getUsers()).filter((user) => user.cognitoID === cognitoId);

	if (users.length === 0) throw new Error("Couldn't find user with matching cognito ID.");

	return users[0];
};

export const getUsersByOrganization = async (organizationId: string): Promise<User[]> => {
	return (await getUsers()).filter((user) => user.organizationID === organizationId);
};

/**************************** Update ****************************/
export const updateUser = async (id: string, name: string, confirmed: boolean): Promise<User> => {
	// api call
	const userResponse = (await API.graphql(
		graphqlOperation(Mutations.updateUser, { input: { id, name, confirmed } })
	)) as GraphQLResult<UserQueries.UpdateUserMutation>;

	// parse response
	const user = parseGraphQLResponse(userResponse).updateUser;

	return user;
};

export const updateUserCategory = async (id: string, category: UserCategories): Promise<User> => {
	// api call
	const userResponse = (await API.graphql(
		graphqlOperation(Mutations.updateUser, { input: { id: id, type: category } })
	)) as GraphQLResult<UserQueries.UpdateUserMutation>;

	// parse response
	const user = parseGraphQLResponse(userResponse).updateUser;

	return user;
};

export const updateUserCognitoId = async (id: string, cognitoId: string): Promise<User> => {
	// api call
	const userResponse = (await API.graphql(
		graphqlOperation(Mutations.updateUser, { input: { id: id, cognitoID: cognitoId } })
	)) as GraphQLResult<UserQueries.UpdateUserMutation>;

	// parse response
	const user = parseGraphQLResponse(userResponse).updateUser;

	return user;
};

export const setUsersCategory =
	(category: UserCategories) =>
	(ids: string[]): Promise<User[]> => {
		const promises: Promise<User>[] = [];
		ids.forEach((id) => {
			const promise = updateUserCategory(id, category);
			promises.push(promise);
		});

		return Promise.all(promises);
	};

/**************************** DELETE ****************************/
export const deleteUser = async (id: string): Promise<User> => {
	// api call
	const userResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteUser, { input: { id: id } })
	)) as GraphQLResult<UserQueries.DeleteUserMutation>;

	// parse response
	const user = parseGraphQLResponse(userResponse).deleteUser;

	return user;
};

export const deleteUsers = (ids: string[]): Promise<User[]> => {
	const promises: Promise<User>[] = [];
	ids.forEach((id) => {
		const promise = deleteUser(id);
		promises.push(promise);
	});

	return Promise.all(promises);
};
