import { ActionStrings } from 'actions/action_strings';
import { DefaultAction } from 'actions/action_types';
import { combineReducers } from 'redux';
import { GlobalState } from 'store/global_state';
import { ApplicationReducer } from './application_reducer';
import { GeneralReducer } from './general_reducer';
import { InterviewsReducer } from './interviews_reducer';
import { OrganizationReducer } from './org_reducer';
import { UserReducer } from './user_reducer';

const appReducer = combineReducers({
	application: ApplicationReducer,
	general: GeneralReducer,
	interviews: InterviewsReducer,
	user: UserReducer,
	organization: OrganizationReducer,
});

const rootReducer = (state: GlobalState | undefined, action: DefaultAction) => {
	if (action.type === ActionStrings.User.Logout) state = undefined;

	return appReducer(state, action);
};
export default rootReducer;
