export const Routes = {
	Admin: {
		Dashboard: '/admin',
		EditApplicationRubric: '/admin/applicationRubric/edit',
		EditInterviewRubric: '/admin/interviewRubric/edit',
		EditReviewRubric: '/admin/reviewRubric/edit',
		Interviews: '/admin/interviews',
		Members: '/admin/members',
		Rubrics: '/admin/rubrics',
		Teams: '/admin/teams',
	},
	Applicant: {
		Application: '/applicant/application',
		Dashboard: '/applicant',
		Interview: '/applicant/interview',
		Settings: '/applicant/settings',
	},
	General: {
		Chat: '/chat',
		NewUser: '/new-user',
		Profile: '/profile',
		Settings: '/account/settings',
	},
	Leader: {
		Dashboard: '/leader',
		Interviews: '/leader/interviews',
		Reviews: '/leader/reviews',
		Team: '/leader/team',
	},
	Member: {
		Dashboard: '/member',
		Team: '/member/team',
	},
	Public: {
		ConfirmSignUp: '/confirm',
		FaqPage: '/faq',
		LandingPage: '/',
		Login: '/login',
		Pricing: '/pricing',
		ForgotPassword: '/forgot-password',
		ResetPassword: '/reset',
		SignUp: '/signup',
	},
	// SUPER USER
	DevOps: {
		Dashboard: '/devops',
	},
};
