import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import { Grid, Switch } from '@mui/material';
import { Actions } from 'actions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store/global_state';

const DarkModeToggle: React.FC = () => {
	const dispatch = useDispatch();
	const isDarkMode = useSelector<GlobalState, boolean>((state) => state.general.darkMode);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const handleToggle = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		dispatch(Actions.General.SetDarkMode(checked));
	};

	window.addEventListener('resize', () => setWindowWidth(window.innerWidth));

	return (
		<>
			<Grid container direction="row" justifyContent="center" alignItems="center">
				<Grid item>{windowWidth < 1200 ? <BrightnessMediumIcon /> : 'Dark Mode'}</Grid>
				<Grid item>
					<Switch color="secondary" checked={isDarkMode} onChange={handleToggle} />
				</Grid>
			</Grid>
		</>
	);
};

export { DarkModeToggle };
