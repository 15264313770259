import { Typography } from '@mui/material';
import React from 'react';

const TravultSubtitle: React.FC = ({ children }) => {
	return (
		<>
			<Typography variant="subtitle1" color="textSecondary">
				{children}
			</Typography>
		</>
	);
};

export { TravultSubtitle };
