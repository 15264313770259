import { Typography } from '@mui/material';
import React from 'react';

const TravultTitle: React.FC = ({ children }) => {
	return (
		<>
			<Typography variant="h2" color="textPrimary">
				{children}
			</Typography>
		</>
	);
};

export { TravultTitle };
