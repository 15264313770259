import { SeverityEnum } from 'enums/severity-enum';
import { AlertState } from 'store/sub-states/general_state';

export const getSuccessSnackBar = (message: string): AlertState => ({
	open: true,
	message,
	severity: SeverityEnum.Success,
});

export const getInfoSnackBar = (message: string): AlertState => ({
	open: true,
	message,
	severity: SeverityEnum.Info,
});

export const getErrorSnackBar = (message: string): AlertState => ({
	open: true,
	message,
	severity: SeverityEnum.Error,
});
