import Auth from '@aws-amplify/auth';
import { Email, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { Actions } from 'actions';
import { PageLayout } from 'components/layout/page_layout';
import { Loading } from 'components/loading/loading';
import { TravultTitle } from 'components/text/travult_title';
import { SeverityEnum } from 'enums/severity-enum';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routing/routes';
import { signInUser } from 'services/auth_service';
import { CognitoUserInfo } from 'types/aws_types';
import { validate, ValidationItem, ValidationType } from 'utils/validation_utility';

const LoginPage: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [emailErrorText, setEmailErrorText] = useState('');
	const [password, setPassword] = useState('');
	const [passwordErrorText, setPasswordErrorText] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const validations: ValidationItem[] = [
		{
			valueToValidate: email,
			validations: [
				{
					type: ValidationType.Email,
				},
			],
			setErrorText: setEmailErrorText,
		},
		{
			valueToValidate: password,
			validations: [
				{
					type: ValidationType.MinLength,
					data: 8,
				},
			],
			setErrorText: setPasswordErrorText,
		},
	];

	/****************
	 * HANDLERS
	 ****************/
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setEmailErrorText('');
	};

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		setPasswordErrorText('');
	};

	const handleClickShowPassword = () => setShowPassword(!showPassword);

	const handleLogin = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		setLoading(true);
		const passedValidations = validate(validations);
		if (passedValidations) {
			try {
				await signInUser(email, password);
				const cognitoUser: CognitoUserInfo = await Auth.currentUserInfo();
				if (!cognitoUser) throw new Error("Error: Couldn't retrieve your account.");
			} catch (error) {
				dispatch(
					Actions.General.SetAlert({
						message: (error as Error).message,
						open: true,
						severity: SeverityEnum.Error,
					})
				);
			}
		}
		setLoading(false);
	};

	return (
		<>
			<TravultTitle>Login</TravultTitle>
			<PageLayout>
				<form>
					<Paper
						sx={{
							m: 1,
							p: 5,
							minWidth: 450,
						}}>
						{/* EMAIL */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Email"
									type="email"
									value={email}
									onChange={handleEmailChange}
									required
									error={emailErrorText !== ''}
									helperText={emailErrorText}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Email />
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>

						{/* PASSWORD */}
						<Grid item>
							<FormControl
								sx={{
									width: '100%',
									mt: 2,
									mb: 2,
								}}>
								<TextField
									label="Password"
									type={showPassword ? 'text' : 'password'}
									value={password}
									onChange={handlePasswordChange}
									required
									error={passwordErrorText !== ''}
									helperText={passwordErrorText}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Lock />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													// onMouseDown={handleMouseDownPassword}
													onClick={handleClickShowPassword}
													size="large">
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</FormControl>
						</Grid>

						{/* FORGOT PASSWORD */}
						<Grid container justifyContent="flex-start">
							<Typography variant="caption" color="textPrimary">
								Forgot your password?{' '}
								<Link onClick={() => history.push(Routes.Public.ForgotPassword)}>Reset Password</Link>
							</Typography>
						</Grid>

						{/* CREATE ACCOUNT / SIGN IN */}
						<Grid item sx={{ mt: 5 }}>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item>
									<Typography variant="caption" color="textPrimary">
										No account?{' '}
										<Link onClick={() => history.push(Routes.Public.SignUp)}>Create account</Link>
									</Typography>
								</Grid>
								<Grid item>
									<Loading loading={loading} size={40}>
										<Button type="submit" variant="contained" color="primary" onClick={handleLogin}>
											Sign In
										</Button>
									</Loading>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</form>
			</PageLayout>
		</>
	);
};

export { LoginPage };
