import { Grid, Typography } from '@mui/material';
// import { usePrevious } from 'hooks/usePrevious';
import React, { useEffect, useState } from 'react';
import { DisplayField } from './display_field';
import { FieldActions } from './field_actions';
import { NewFieldForm } from './new_field_form';

export enum InputTypeEnum {
	Dropdown = 'drop down',
	Field = 'field',
	Switch = 'switch',
	TextArea = 'text area',
}

export interface FormBuilderField {
	order: number;
	inputType: InputTypeEnum | undefined;
	label: string;
	dropdownOptions?: string[];
}

export interface FormBuilderAnswer {
	id: number;
	field: FormBuilderField;
	response: string;
}

interface FormBuilderProps {
	currentForm: string;
	populateForm: (form: string) => void;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ populateForm, currentForm }) => {
	// const prevForm = usePrevious(currentForm);
	const [form, setForm] = useState<FormBuilderField[]>(
		currentForm !== '' ? JSON.parse(currentForm) : []
	);

	const addField = (field: FormBuilderField) => {
		if (field) {
			field.order = form.length;
			setForm([...form, { ...field }]);
		}
	};

	const deleteField = (field: FormBuilderField) => {
		const sorted = form.sort((field) => field.order);
		sorted.splice(field.order, 1);
		sorted.forEach((item, index) => {
			item.order = index;
		});
		setForm([...sorted]);
	};

	const moveUp = (field: FormBuilderField) => {
		const sorted = form.sort((field) => field.order);

		[sorted[field.order - 1], sorted[field.order]] = [
			sorted[field.order],
			sorted[field.order - 1],
		];

		sorted.forEach((item, index) => {
			item.order = index;
		});
		setForm([...sorted]);
	};

	const moveDown = (field: FormBuilderField) => {
		const sorted = form.sort((field) => field.order);

		[sorted[field.order + 1], sorted[field.order]] = [
			sorted[field.order],
			sorted[field.order + 1],
		];

		sorted.forEach((item, index) => {
			item.order = index;
		});
		setForm([...sorted]);
	};

	useEffect(() => {
		populateForm(JSON.stringify(form));
	}, [form, populateForm]);

	return (
		<>
			<Grid container direction="column">
				{form.length === 0 && (
					<Typography variant="h4" style={{ margin: '30px' }}>
						Create your custom form by adding fields below!
					</Typography>
				)}
				{form
					.sort((field) => field.order)
					.map((field, i) => {
						return (
							<React.Fragment key={i}>
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
									sx={{
										mt: 2,
										mb: 2,
									}}>
									<Grid item xs={8}>
										<DisplayField field={field} />
									</Grid>

									<Grid item xs={4}>
										<FieldActions
											field={field}
											deleteAction={deleteField}
											moveDownAction={
												field.order < form.length - 1 ? moveDown : undefined
											}
											moveUpAction={field.order > 0 ? moveUp : undefined}
										/>
									</Grid>
								</Grid>
								<hr style={{ width: '95%' }} />
							</React.Fragment>
						);
					})}
				<Grid
					sx={{
						mt: 2,
						mb: 2,
					}}>
					<NewFieldForm addField={addField} />
				</Grid>
				<br />
			</Grid>
		</>
	);
};

export { FormBuilder };
