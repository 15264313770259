import { UnderConstruction } from 'components/general/under_construction';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import React from 'react';

const SettingsPage: React.FC = () => {
	return (
		<TravultContainer>
			<TopToolbar title="Settings" subtitle="Customize preferences for your organization" />
			<UnderConstruction pageName="Settings Page" />
		</TravultContainer>
	);
};

export { SettingsPage };
