import { IconButton, Tooltip } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, Delete } from '@mui/icons-material';
import { Strings } from 'assets/strings';
import React from 'react';
import { FormBuilderField } from './form_builder';

interface FieldActionsProps {
	field: FormBuilderField;
	deleteAction?: (field: FormBuilderField) => void;
	moveDownAction?: (field: FormBuilderField) => void;
	moveUpAction?: (field: FormBuilderField) => void;
}

const FieldActions: React.FC<FieldActionsProps> = ({
	field,
	deleteAction,
	moveDownAction,
	moveUpAction,
}) => {
	return <>
        {moveUpAction && (
            <IconButton onClick={() => moveUpAction(field)} size="large">
                <KeyboardArrowUp />
            </IconButton>
        )}
        {moveDownAction && (
            <IconButton onClick={() => moveDownAction(field)} size="large">
                <KeyboardArrowDown />
            </IconButton>
        )}
        {deleteAction && (
            <Tooltip title={Strings.EditRubric.DeleteField}>
                <IconButton onClick={() => deleteAction(field)} size="large">
                    <Delete color="error" />
                </IconButton>
            </Tooltip>
        )}
    </>;
};

export { FieldActions };
