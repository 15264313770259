import { Button, Chip, Grid, Typography } from '@mui/material';
import { Actions } from 'actions';
import { Application } from 'API';
import { Strings } from 'assets/strings';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { Loading } from 'components/loading/loading';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createApplication, getApplicationByUser } from 'services/application_service';
import { GlobalState } from 'store/global_state';
import { ApplicationState } from 'store/sub-states/application_state';
import { OrganizationState } from 'store/sub-states/org_state';
import { UserState } from 'store/sub-states/user_state';
import { ApplicationForm } from './application_form';
import { ApplicationReview } from './application_review';

const ApplicationPage: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [fetchedApplication, setFetchedApplication] = useState<Application>();
	const dispatch = useDispatch();
	const user = useSelector<GlobalState, UserState>((state) => state.user);
	const organization = useSelector<GlobalState, OrganizationState>((state) => state.organization);
	const application = useSelector<GlobalState, ApplicationState>((state) => state.application);

	const hasApplication = fetchedApplication || application.id !== '';

	useEffect(() => {
		const fetchApplication = async () => {
			if (!user.id) return;

			const application = await getApplicationByUser(user.id);
			if (application) setFetchedApplication(application);
			setLoading(false);
		};

		if (application.id === '') {
			if (fetchedApplication) {
				dispatch(Actions.Application.SetApplication(fetchedApplication));
			} else {
				setLoading(true);
				fetchApplication();
			}
		}
	}, [application, fetchedApplication, user, dispatch]);

	return (
		<>
			<Loading loading={loading}>
				<TravultContainer>
					<TopToolbar
						title="Application"
						buttons={[
							<Grid container direction="row" alignItems="center">
								<Typography variant="button" sx={{ mr: 1 }}>
									Status:
								</Typography>
								{hasApplication ? (
									<>
										{application.submitted ? (
											<Chip label="Submitted" color="secondary" />
										) : (
											<Chip label="In Progress" color="warning" />
										)}
									</>
								) : (
									<Chip label="Not Started" color="default" />
								)}
							</Grid>,
						]}
					/>
					{hasApplication ? (
						<>
							{organization.applicationRubric ? (
								<>
									{application.submitted ? (
										<ApplicationReview />
									) : (
										<ApplicationForm />
									)}
								</>
							) : (
								<Typography>{Strings.Application.NoRubric}</Typography>
							)}
						</>
					) : (
						// Doesn't have application
						<>
							<Typography variant="h2">{Strings.Application.Application}</Typography>
							<Typography variant="body1" sx={{ m: 3 }}>
								{Strings.Application.CouldntFindApplication}
							</Typography>
							<Button
								variant="contained"
								color="primary"
								sx={{ m: 3 }}
								onClick={() => createApplication(organization.id ?? '', user)}>
								{Strings.Application.StartApplication}
							</Button>
						</>
					)}
				</TravultContainer>
			</Loading>
		</>
	);
};

export { ApplicationPage };
