import { AddCircle } from '@mui/icons-material';
import {
	Button,
	Grid,
	IconButton,
	Paper,
	Theme,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { ApplicationRubric } from 'API';
import { Strings } from 'assets/strings';
import { FormBuilderField } from 'components/form_builder/form_builder';
import { Loading } from 'components/loading/loading';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Routes } from 'routing/routes';
import { getApplicationRubric } from 'services/application_rubric_service';
import { GlobalState } from 'store/global_state';
import { OrganizationState } from 'store/sub-states/org_state';

const ApplicationRubricPage: React.FC = () => {
	const history = useHistory();
	const theme = useTheme();
	const organization = useSelector<GlobalState, OrganizationState>((state) => state.organization);
	const [loading, setLoading] = React.useState(false);
	const [rubric, setRubric] = useState<ApplicationRubric>();
	const [form, setForm] = useState<FormBuilderField[]>();

	useEffect(() => {
		const fetchApplicationRubric = async () => {
			if (organization.applicationRubric && organization.applicationRubric.id) {
				setLoading(true);
				const rubric = await getApplicationRubric(organization.applicationRubric.id);
				setRubric(rubric);
				setForm(JSON.parse(rubric?.form ?? ''));
				setLoading(false);
			}
		};
		fetchApplicationRubric();
	}, [organization]);

	return (
		<>
			<Loading loading={loading}>
				<Paper sx={{ p: 3 }}>
					<Grid container direction="column">
						{rubric ? (
							<>
								<Typography variant="h5">{rubric.name}</Typography>
								<div style={{ margin: '20px 0' }}>
									{form
										?.sort((field) => field.order)
										.map((field, i) => {
											return (
												<React.Fragment key={i}>
													{i !== 0 && <hr style={{ width: '100%' }} />}
													<Grid
														container
														direction="row"
														alignItems="center">
														<Grid item xs={9}>
															<Typography variant="subtitle1">
																{field.label}
															</Typography>
														</Grid>
														<Grid
															item
															xs={3}
															sx={(theme: Theme) => ({
																color: 'primary.main',
																borderLeft: `2px solid ${theme.palette.primary.main}`,
															})}>
															<Typography variant="body2">
																{field.inputType?.toUpperCase()}
															</Typography>
														</Grid>
													</Grid>
												</React.Fragment>
											);
										})}
								</div>

								<div>
									<Button
										variant="outlined"
										color="primary"
										onClick={() =>
											history.push(Routes.Admin.EditApplicationRubric)
										}>
										Edit
									</Button>
								</div>
							</>
						) : (
							<>
								<Typography variant="h5">
									{Strings.RubricsPage.ApplicationRubric}
								</Typography>
								<Grid item>
									<Tooltip title={Strings.RubricsPage.CreateNewApplicationRubric}>
										<IconButton
											onClick={() =>
												history.push(Routes.Admin.EditApplicationRubric)
											}
											size="large">
											<AddCircle
												style={{ color: theme.palette.secondary.main }}
											/>
										</IconButton>
									</Tooltip>
								</Grid>
							</>
						)}
					</Grid>
				</Paper>
			</Loading>
		</>
	);
};

export { ApplicationRubricPage };
