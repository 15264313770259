import { Actions } from 'actions';
import { FullScreenLoader } from 'components/loading/full_screen_loader';
import { makeErrorAlert } from 'providers/alert_provider';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateReduxData } from 'services/general_service';
import { GlobalState } from 'store/global_state';

const RefreshProvider: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const refreshing = useSelector<GlobalState, boolean>((state) => state.general.refreshing);
	const email = useSelector<GlobalState, string | undefined>((state) => state.user.email);

	useEffect(() => {
		const refreshRedux = async () => {
			if (!email) {
				dispatch(Actions.General.SetAlert(makeErrorAlert('Failed to retrieve email')));
				dispatch(Actions.General.SetRefreshing(false));
				return;
			}
			await updateReduxData(email, false);
			dispatch(Actions.General.SetRefreshing(false));
		};

		if (!refreshing) return;
		refreshRedux();
	}, [refreshing]);

	return (
		<>
			<FullScreenLoader loading={refreshing}>{children}</FullScreenLoader>
		</>
	);
};

export { RefreshProvider };
