import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	useTheme,
} from '@mui/material';
import { Loading } from 'components/loading/loading';
import React, { useEffect, useState } from 'react';
import { DbEntity } from 'types/db_entity';

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page">
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page">
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

interface ListAllWidgetProps {
	title: string;
	getRequest: () => Promise<DbEntity[]>;
}

const ListAllWidget: React.FC<ListAllWidgetProps> = ({ title, getRequest }) => {
	const [loading, setLoading] = useState(false);
	const [entities, setEntities] = useState<DbEntity[]>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = Math.max(0, (1 + page) * rowsPerPage - entities.length);

	const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		(async () => {
			setLoading(true);
			const results = await getRequest();
			setEntities(results);
			setLoading(false);
		})();
	}, [getRequest]);

	return (
		<>
			<Card sx={{ maxWidth: 600 }}>
				<Loading loading={loading}>
					<CardHeader
						title={`${title} (${entities.length})`}
						action={
							<IconButton aria-label="settings">
								<MoreVertIcon />
							</IconButton>
						}
					/>
					<CardContent>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 100 }} aria-label="simple table">
								<TableHead sx={{ bgcolor: 'primary.main' }}>
									<TableRow>
										<TableCell sx={{ color: 'primary.contrastText' }}>ID</TableCell>
										<TableCell sx={{ color: 'primary.contrastText' }}>Name</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(rowsPerPage > 0
										? entities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										: entities
									).map((entity) => (
										<StyledTableRow
											key={entity.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell component="th" scope="row">
												{entity.id}
											</TableCell>
											<TableCell>{entity.name}</TableCell>
										</StyledTableRow>
									))}
									{emptyRows > 0 && (
										<TableRow style={{ height: 53 * emptyRows }}>
											<TableCell colSpan={2} />
										</TableRow>
									)}
								</TableBody>
								<TableFooter>
									<TableRow sx={{ bgcolor: 'action.hover' }}>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
											colSpan={2}
											count={entities.length}
											rowsPerPage={rowsPerPage}
											page={page}
											SelectProps={{
												inputProps: {
													'aria-label': 'rows per page',
												},
												native: true,
											}}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</CardContent>
				</Loading>
			</Card>
		</>
	);
};

export { ListAllWidget };
