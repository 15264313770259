import { Review, User } from 'API';
import { UserCategories } from 'types/user_types';

export abstract class ReviewUtility {
	public static getApplicant(review: Review): User | undefined {
		const applicants = review.users?.items?.filter(
			(connection) => connection?.user?.type === UserCategories.Applicant
		);

		return applicants && applicants.length > 0 ? applicants[0]?.user : undefined;
	}

	public static getApplicantName(review: Review): string {
		const applicant = this.getApplicant(review);
		return applicant ? applicant.name ?? '' : '';
	}
}
