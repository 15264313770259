import {
	Box,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { PageLayout } from 'components/layout/page_layout';
import React from 'react';

const PricingPage: React.FC = () => {
	return (
		<>
			<Box>
				<PageLayout>
					<Typography variant="h3" color="textPrimary" sx={{ mt: 3 }}>
						Pricing Tiers
					</Typography>
					<Typography variant="body1" color="textPrimary" sx={{ mb: 3 }}>
						We have a simple and transparent pricing model that is proportional to the
						size of your organization.
					</Typography>
					<TableContainer sx={{ width: '30%' }} component={Paper}>
						<Table sx={{ minWidth: 300 }}>
							<TableHead>
								<TableRow>
									<TableCell>Number of Active Users</TableCell>
									<TableCell align="right">Cost</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow sx={{ border: 0 }}>
									<TableCell>1 - 100</TableCell>
									<TableCell align="right">
										<Grid container direction="column">
											<Typography
												variant="h3"
												sx={{ color: 'secondary.main' }}>
												<strong>$50</strong>
											</Typography>
											<Typography
												variant="caption"
												sx={{ color: 'text.secondary' }}>
												<strong>per month</strong>
											</Typography>
										</Grid>
									</TableCell>
								</TableRow>
								<TableRow sx={{ border: 0 }}>
									<TableCell>101 - 500</TableCell>
									<TableCell align="right">
										<Grid container direction="column">
											<Typography
												variant="h3"
												sx={{ color: 'secondary.main' }}>
												<strong>$100</strong>
											</Typography>
											<Typography
												variant="caption"
												sx={{ color: 'text.secondary' }}>
												<strong>per month</strong>
											</Typography>
										</Grid>
									</TableCell>
								</TableRow>
								<TableRow sx={{ border: 0 }}>
									<TableCell>501 - 1,000</TableCell>
									<TableCell align="right">
										<Grid container direction="column">
											<Typography
												variant="h3"
												sx={{ color: 'secondary.main' }}>
												<strong>$200</strong>
											</Typography>
											<Typography
												variant="caption"
												sx={{ color: 'text.secondary' }}>
												<strong>per month</strong>
											</Typography>
										</Grid>
									</TableCell>
								</TableRow>
								<TableRow sx={{ border: 0 }}>
									<TableCell>1,001 - 5,000</TableCell>
									<TableCell align="right">
										<Grid container direction="column">
											<Typography
												variant="h3"
												sx={{ color: 'secondary.main' }}>
												<strong>$400</strong>
											</Typography>
											<Typography
												variant="caption"
												sx={{ color: 'text.secondary' }}>
												<strong>per month</strong>
											</Typography>
										</Grid>
									</TableCell>
								</TableRow>
								<TableRow sx={{ border: 0 }}>
									<TableCell>5,001 - 10,000</TableCell>
									<TableCell align="right">
										<Grid container direction="column">
											<Typography
												variant="h3"
												sx={{ color: 'secondary.main' }}>
												<strong>$800</strong>
											</Typography>
											<Typography
												variant="caption"
												sx={{ color: 'text.secondary' }}>
												<strong>per month</strong>
											</Typography>
										</Grid>
									</TableCell>
								</TableRow>
								<TableRow sx={{ border: 0 }}>
									<TableCell>10,000+</TableCell>
									<TableCell align="right">
										<Grid container direction="column">
											<Typography
												variant="h3"
												sx={{ color: 'secondary.main' }}>
												<strong>$0.10</strong>
											</Typography>
											<Typography
												variant="caption"
												sx={{ color: 'text.secondary' }}>
												<strong>per user per month</strong>
											</Typography>
										</Grid>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</PageLayout>
			</Box>
		</>
	);
};

export { PricingPage };
