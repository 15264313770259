import React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

export interface TabData {
	title: string;
	content: JSX.Element;
}

interface FullWidthTabsProps {
	data: TabData[];
}

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

export const DefaultTabsData: TabData[] = [
	{
		title: 'Default Tab',
		content: <div>Default Content</div>,
	},
];

const FullWidthTabs: React.FC<FullWidthTabsProps> = ({ data = DefaultTabsData }) => {
	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Grid sx={{ backgroundColor: 'background.paper', width: '100%' }}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
					centered>
					{data.length > 0 &&
						data.map((tab, index) => (
							<Tab key={index} label={tab.title} {...a11yProps(index)} />
						))}
				</Tabs>
			</AppBar>
			{data.length > 0 &&
				data.map((tab, index) => (
					<TabPanel key={index} value={value} index={index} dir={theme.direction}>
						{tab.content}
					</TabPanel>
				))}
		</Grid>
	);
};

export { FullWidthTabs };
