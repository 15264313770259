import { Grid, Typography } from '@mui/material';
import React from 'react';
import warningSign from 'assets/images/warning-sign.png';
import { Box } from '@mui/system';

interface UnderConstructionProps {
	pageName?: string;
}

const UnderConstruction: React.FC<UnderConstructionProps> = ({ pageName }) => {
	return (
		<>
			<Grid container direction="column" alignItems="center">
				<Typography variant="h2">Sorry...</Typography>
				<Typography variant="h5">
					{pageName ? pageName : 'This page'} is currently under construction. Please try
					again later!
				</Typography>
				<Box sx={{ mt: 5, width: '30%', minWidth: 250 }}>
					<img
						style={{ marginTop: '20px', width: '60%', minWidth: 250 }}
						src={warningSign}
						alt="Construction sign"
					/>
				</Box>
			</Grid>
		</>
	);
};

export { UnderConstruction };
