import { ActionStrings } from 'actions/action_strings';
import { DefaultAction } from 'actions/action_types';
import { InitialApplicationState } from 'store/initial_state';
import { ApplicationState } from 'store/sub-states/application_state';

export const ApplicationReducer = (
	state = InitialApplicationState,
	action: DefaultAction
): ApplicationState => {
	switch (action.type) {
		case ActionStrings.Application.SetApplication:
			return { ...action.payload };
		default:
			return state;
	}
};
