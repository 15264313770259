import { Button } from '@mui/material';
import { UnderConstruction } from 'components/general/under_construction';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import React from 'react';

const ChatPage: React.FC = () => {
	return (
		<TravultContainer>
			<TopToolbar
				title="Chat"
				subtitle="Message with those in your organization"
				buttons={[
					<Button variant="contained" color="secondary">
						New Message
					</Button>,
				]}
			/>
			<UnderConstruction pageName="Chat Page" />
		</TravultContainer>
	);
};

export { ChatPage };
