import { GraphQLResult } from '@aws-amplify/api';
import { ReviewRubric } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { ReviewRubricQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/
export const createReviewRubric = async (organizationId: string): Promise<ReviewRubric> => {
	// api call
	const reviewRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.createReviewRubric, {
			input: { name: 'Default', reviewRubricOrganizationId: organizationId },
		})
	)) as GraphQLResult<ReviewRubricQueries.CreateReviewRubricMutation>;

	// parse response
	const reviewRubric = parseGraphQLResponse(reviewRubricResponse).createReviewRubric;

	return reviewRubric;
};

/**************************** RETRIEVE ****************************/
export const getReviewRubric = async (id: string): Promise<ReviewRubric> => {
	// api call
	const reviewRubricResponse = (await API.graphql(
		graphqlOperation(Queries.getReviewRubric, { id: id })
	)) as GraphQLResult<ReviewRubricQueries.GetReviewRubricQuery>;

	// parse response
	const reviewRubric = parseGraphQLResponse(reviewRubricResponse).getReviewRubric;

	return reviewRubric;
};

export const getReviewRubrics = async (): Promise<ReviewRubric[]> => {
	// api call
	const reviewRubricResponse = (await API.graphql(
		graphqlOperation(Queries.listReviewRubrics)
	)) as GraphQLResult<ReviewRubricQueries.ListReviewRubricsQuery>;

	// parse response
	const reviewRubrics = parseGraphQLResponse(reviewRubricResponse).listReviewRubrics.items;

	return reviewRubrics;
};
/**************************** Update ****************************/
export const updateReviewRubric = async (
	id: string,
	name: string,
	form: string
): Promise<ReviewRubric> => {
	// api call
	const reviewRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.updateReviewRubric, {
			input: { id: id, name: name, form: form },
		})
	)) as GraphQLResult<ReviewRubricQueries.UpdateReviewRubricMutation>;

	// parse response
	const reviewRubric = parseGraphQLResponse(reviewRubricResponse).updateReviewRubric;

	return reviewRubric;
};

/**************************** DELETE ****************************/
export const deleteReviewRubric = async (id: string): Promise<ReviewRubric | undefined> => {
	// api call
	const reviewRubricResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteReviewRubric, { input: { id: id } })
	)) as GraphQLResult<ReviewRubricQueries.DeleteReviewRubricMutation>;

	// parse response
	const reviewRubric = parseGraphQLResponse(reviewRubricResponse).deleteReviewRubric;

	return reviewRubric;
};
