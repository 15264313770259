import { Container, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Application, ApplicationRubric } from 'API';
import { FormBuilderAnswer } from 'components/form_builder/form_builder';
import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/global_state';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		divider: {
			width: '100%',
		},
		typeSegment: {
			color: theme.palette.primary.main,
			borderLeft: `2px solid ${theme.palette.primary.main}`,
		},
	})
);

const ApplicationReview: React.FC = () => {
	const classes = useStyles();
	const applicationRubric = useSelector<GlobalState, ApplicationRubric | undefined>(
		(state) => state.organization.applicationRubric
	);
	const application = useSelector<GlobalState, Application>((state) => state.application);

	return (
		<>
			<Typography variant="h3" color="textPrimary" sx={{ mb: 1 }}>
				{applicationRubric?.name}
			</Typography>
			<Container>
				<Paper sx={{ p: 3 }}>
					{(JSON.parse(application.form ?? '') as FormBuilderAnswer[])
						?.sort((answer) => answer.field.order)
						.map((answer, i) => {
							return (
								<React.Fragment key={i}>
									{i !== 0 && <hr style={{ width: '100%' }} />}
									<Grid container direction="row" alignItems="center">
										<Grid item sm={6}>
											<Typography variant="subtitle1">
												{answer.field.label}
											</Typography>
										</Grid>
										<Grid item sm={6} className={classes.typeSegment}>
											<Typography variant="body2">
												{answer.response ?? 'Unanswered'}
											</Typography>
										</Grid>
									</Grid>
								</React.Fragment>
							);
						})}
				</Paper>
			</Container>
		</>
	);
};

export { ApplicationReview };
