import { AccountCircle } from '@mui/icons-material';
import { Avatar, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { DarkModeToggle } from 'components/general/dark_mode_toggle';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routing/routes';
import { signOutUser } from 'services/auth_service';
import { GlobalState } from 'store/global_state';
import { UserState } from 'store/sub-states/user_state';

const ProfileMenu: React.FC = () => {
	const history = useHistory();
	const user = useSelector<GlobalState, UserState>((state) => state.user);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorEl);

	const initials =
		user.name ??
		''
			.split(' ')
			.map((name) => name[0])
			.join('');

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		if (anchorEl === null) setAnchorEl(event.currentTarget);
		else setAnchorEl(null);
	};
	return (
		<>
			<IconButton
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleMenu}
				color="inherit"
				size="large">
				{initials !== '' ? (
					<Avatar sx={{ bgcolor: 'white', color: (theme) => theme.palette.primary.main }}>
						{initials.slice(0, initials.length > 3 ? 2 : initials.length)}
					</Avatar>
				) : (
					<AccountCircle />
				)}
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				keepMounted
				open={menuOpen}
				onClose={() => setAnchorEl(null)}>
				<Grid container direction="column" alignItems="center">
					<MenuItem>
						<DarkModeToggle />
					</MenuItem>
					<MenuItem onClick={() => history.push(Routes.General.Profile)}>
						Profile
					</MenuItem>
					<MenuItem
						onClick={() => {
							signOutUser();
							history.push(Routes.Public.LandingPage);
						}}>
						Sign Out
					</MenuItem>
				</Grid>
			</Menu>
		</>
	);
};

export { ProfileMenu };
