import { ActionStrings } from 'actions/action_strings';
import { DefaultAction } from 'actions/action_types';
import { InitialInterviewsState } from 'store/initial_state';
import { InterviewsState } from 'store/sub-states/interviews_state';

export const InterviewsReducer = (state = InitialInterviewsState, action: DefaultAction): InterviewsState => {
	switch (action.type) {
		case ActionStrings.Interviews.AddInterviewSlot:
			return { ...state, slots: [...state.slots, action.payload] };
		case ActionStrings.Interviews.RemoveInterviewSlot:
			const newSlots = state.slots.filter((slot) => slot.id !== action.payload.id);
			return { ...state, slots: newSlots };
		case ActionStrings.Interviews.SetInterviewSlots:
			return { ...state, slots: action.payload };
		case ActionStrings.Interviews.ToggleHideOldInterviews:
			return { ...state, hideOld: action.payload };
		case ActionStrings.Interviews.UpdateInterviewSlot:
			const otherSlots = state.slots.filter((slot) => slot.id !== action.payload.id);
			return { ...state, slots: [...otherSlots, action.payload] };
		default:
			return state;
	}
};
