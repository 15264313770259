export const Strings = {
	Application: {
		Application: 'Application',
		CouldntFindApplication: "Couldn't find your application. Click below to start a new one!",
		NoRubric:
			"Your organization has not setup their application. Please check back later or contact the organization's admininstrator if you have any questions.",
		StartApplication: 'Start Application',
	},
	EditRubric: {
		AddField: 'Add New Field',
		DeleteField: 'Delete Field',
		MoveDown: 'Move Field Down',
		MoveUp: 'Move Field Up',
	},
	Errors: {
		NoNull: 'Field cannot be empty.',
		OrgExists:
			'Sorry an organization with that name already exists in our system. Please choose a different name.',
	},
	General: {
		ApplicationName: 'Travult',
		Back: 'Back',
		Email: 'Email',
		Finish: 'Finish',
		Name: 'Name',
		Next: 'Next',
	},
	MembersPage: {
		DemoteToLeader: 'Demote Administrators To Leaders',
		DemoteToMember: 'Demote Leaders To Members',
		PromoteToAdmin: 'Promote Leaders To Administrators',
		PromoteToLeader: 'Promote Members To Leaders',
	},
	NavBar: {
		Application: 'Application',
		Chat: 'Chat',
		DarkMode: 'Dark Mode',
		Dashboard: 'Dashboard',
		Interview: 'Interview',
		Interviews: 'Interviews',
		Members: 'Members',
		Rubrics: 'Rubrics',
		Settings: 'Settings',
	},
	NewUser: {
		AreYouApplyingToOrg: (org: string) => `Are you applying to ${org}?`,
		BeforeCreation: '*Before your organization can be created you will need to be approved.',
		Confirm: 'Confirm',
		CreateOrg: 'Want to use Travult for your organization? Apply here!',
		CreateOrgWarning: (orgName: string, email: string) =>
			`This will submit a request to create the organization "${orgName}" and register ${email} as the administrator. Are you sure you wish to continue with this action?`,
		EverythingLookCorrect: 'Does this look correct?',
		IsApplyingTo: ' is applying to ',
		IsRequestingAccess: ' is requesting to access to ',
		JoinOrg: 'Does your organization already have an account with Travult? Search for it here!',
		MemberJoinWarning: (orgName: string) =>
			`Before you can join ${orgName}, an administrator will need to approve your request. Do you still want to continue with this action?`,
		NeedMoreInfo: 'We need a little more information to get started!',
		NoAlreadyMember: 'No, I am already a member of this organization',
		OnlyOnceAnswerQuestions:
			'You will only need to go through this account setup process once. Help us setup your profile by answering a few questions...',
		OrgTitle: 'Choose your organization:',
		RequestAccess: 'Request Access',
		ThisOrganization: 'this organization',
		WhatIsYourName: 'What is your name?',
		YesIAmApplying: 'Yes, I want to apply to this organization',
	},
	Public: {
		WelcomeHeaderMessage: 'Managing your organization has never been easier.',
	},
	RubricsPage: {
		ApplicationRubric: 'Application Rubric',
		CreateNewApplicationRubric: 'Create New Application Rubric',
		CreateNewInterveiwRubric: 'Create New Interveiw Rubric',
		CreateNewReviewRubric: 'Create New Review Rubric',
		DefaultReviewRubric: 'Default Review Rubric',
		InterviewRubric: 'Interview Rubric',
	},
};
