import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { User } from 'API';
import { Strings } from 'assets/strings';
import { BasicTable, HeadCell } from 'components/general/basic_table';
import { DefaultTabsData, FullWidthTabs, TabData } from 'components/general/full_width_tabs';
import { RefreshButton } from 'components/general/refresh_button';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { Loading } from 'components/loading/loading';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteUsers, getUsersByOrganization, setUsersCategory } from 'services/user_service';
import { GlobalState } from 'store/global_state';
import { OrganizationState } from 'store/sub-states/org_state';
import { OrganizationUsers, UserCategories } from 'types/user_types';

const createUserTable = (
	users: User[],
	type: UserCategories,
	reloadCallback?: () => void
): JSX.Element => {
	const columns: HeadCell<User>[] = [
		{ field: 'name', disablePadding: true, label: Strings.General.Name, numeric: false },
		{ field: 'email', disablePadding: false, label: Strings.General.Email, numeric: false },
	];

	const additionalActions = [];
	switch (type) {
		case UserCategories.Applicant:
			break;
		case UserCategories.Member:
			additionalActions.push({
				icon: <ArrowUpward />,
				tooltip: Strings.MembersPage.PromoteToLeader,
				handleClick: setUsersCategory(UserCategories.TeamLeader),
			});
			break;
		case UserCategories.TeamLeader:
			additionalActions.push({
				icon: <ArrowUpward />,
				tooltip: Strings.MembersPage.PromoteToAdmin,
				handleClick: setUsersCategory(UserCategories.Administrator),
			});
			additionalActions.push({
				icon: <ArrowDownward />,
				tooltip: Strings.MembersPage.DemoteToMember,
				handleClick: setUsersCategory(UserCategories.Member),
			});
			break;
		case UserCategories.Administrator:
			additionalActions.push({
				icon: <ArrowDownward />,
				tooltip: Strings.MembersPage.DemoteToLeader,
				handleClick: setUsersCategory(UserCategories.TeamLeader),
			});
			break;

		default:
			break;
	}

	return (
		<div style={{ width: '100%' }}>
			<BasicTable
				title={type.toUpperCase()}
				rows={users}
				headers={columns}
				deleteSelected={deleteUsers}
				callback={reloadCallback}
				additionalSelectActions={additionalActions}
			/>
		</div>
	);
};

const MembersPage: React.FC = () => {
	const organization = useSelector<GlobalState, OrganizationState>((state) => state.organization);
	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [tabsData, setTabsData] = useState<TabData[]>();
	const [users, setUsers] = useState<OrganizationUsers>({
		admins: [],
		applicants: [],
		leaders: [],
		members: [],
	});

	const triggerReload = () => {
		setReload(!reload);
	};

	const getTabData = async (): Promise<TabData[]> => {
		if (!organization.id) return [];

		const usersResponse = await getUsersByOrganization(organization.id);
		const usersResult: OrganizationUsers = {
			admins: usersResponse.filter((user) => user.type === UserCategories.Administrator),
			leaders: usersResponse.filter((user) => user.type === UserCategories.TeamLeader),
			members: usersResponse.filter((user) => user.type === UserCategories.Member),
			applicants: usersResponse.filter((user) => user.type === UserCategories.Applicant),
		};
		setUsers(usersResult);

		const adminTab: TabData = {
			title: `Administrators (${usersResult.admins.length})`,
			content: createUserTable(
				usersResult.admins,
				UserCategories.Administrator,
				triggerReload
			),
		};
		const leaderTab: TabData = {
			title: `Leaders (${usersResult.leaders.length})`,
			content: createUserTable(usersResult.leaders, UserCategories.TeamLeader, triggerReload),
		};
		const memberTab: TabData = {
			title: `Members (${usersResult.members.length})`,
			content: createUserTable(usersResult.members, UserCategories.Member, triggerReload),
		};
		const applicantTab: TabData = {
			title: `Applicants (${usersResult.applicants.length})`,
			content: createUserTable(
				usersResult.applicants,
				UserCategories.Applicant,
				triggerReload
			),
		};

		return await [adminTab, leaderTab, memberTab, applicantTab];
	};

	useEffect(() => {
		setLoading(true);
		const getPageData = async () => {
			const result = await getTabData();
			setTabsData(result);
			setLoading(false);
		};
		getPageData();
	}, [organization, reload]);

	return (
		<>
			<Loading loading={loading}>
				<TravultContainer>
					<TopToolbar
						title={Strings.NavBar.Members}
						subtitle={
							'Manage the ' +
							(users.admins.length +
								users.applicants.length +
								users.leaders.length +
								users.members.length) +
							' people in your organization'
						}
						buttons={[<RefreshButton triggerLoading={triggerReload} />]}
					/>
					<Grid container direction="column" justifyContent="center" alignItems="center">
						<FullWidthTabs data={tabsData ?? DefaultTabsData} />
					</Grid>
				</TravultContainer>
			</Loading>
		</>
	);
};

export { MembersPage };
