import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Review } from 'API';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getReview } from 'services/review_service';
import { GlobalState } from 'store/global_state';
import { UserState } from 'store/sub-states/user_state';

enum ReviewView {
	Application = 'application',
	Interview = 'interview',
	Review = 'review',
}

type ReviewParams = {
	id: string;
};

const LeaderReview: React.FC = () => {
	const { id } = useParams<ReviewParams>();
	const user = useSelector<GlobalState, UserState>((state) => state.user);
	const [currentView, setCurrentView] = useState(ReviewView.Application);
	const [review, setReview] = useState<Review | undefined>(undefined);

	useEffect(() => {
		(async () => {
			const reviewResponse = await getReview(id);
			if (reviewResponse) setReview(reviewResponse);
		})();
	}, [id, user]);

	return (
		<>
			<TravultContainer>
				<TopToolbar
					title="My Team"
					buttons={[
						<ToggleButtonGroup exclusive value={currentView} onChange={(_, value) => setCurrentView(value)}>
							<ToggleButton value={ReviewView.Application}>Application</ToggleButton>
							<ToggleButton value={ReviewView.Interview}>Interviews</ToggleButton>
							<ToggleButton value={ReviewView.Review}>Review</ToggleButton>
						</ToggleButtonGroup>,
					]}
				/>
				{review ? <>{review.submitted}</> : <>Couldn't find review</>}
			</TravultContainer>
		</>
	);
};

export { LeaderReview };
