import { Button, Grid } from '@mui/material';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { ListAllWidget } from 'components/widgets/list-all';
import { getApplicationRubrics } from 'services/application_rubric_service';
import { getApplications } from 'services/application_service';
import { getInterviewRubrics } from 'services/interview_rubric_service';
import { getInterviews } from 'services/interview_service';
import { getInterviewSlots } from 'services/interview_slot_service';
import { getOrganizations } from 'services/organization_service';
import { getOrganizationSettingss } from 'services/organization_settings_service';
import { getReviewRubrics } from 'services/review_rubric_service';
import { getReviews } from 'services/review_service';
import { getTeams } from 'services/team_service';
import { getUsers } from 'services/user_service';
import { DbEntity } from 'types/db_entity';
import { InterviewUtility } from 'utils/interview_utility';
import { ReviewUtility } from 'utils/review_utility';

const tablesList: { title: string; request: () => Promise<DbEntity[]> }[] = [
	{
		title: 'Application Rubrics',
		request: async () => {
			const results = await getApplicationRubrics();
			return results.map((r) => new DbEntity(r.id ?? '', r.name ?? ''));
		},
	},
	{
		title: 'Applications',
		request: async () => {
			const results = await getApplications();
			return results.map((r) => new DbEntity(r.id ?? '', r.User?.name ?? ''));
		},
	},
	{
		title: 'Interview Rubrics',
		request: async () => {
			const results = await getInterviewRubrics();
			return results.map((r) => new DbEntity(r.id ?? '', r.name ?? ''));
		},
	},
	{
		title: 'Interviews',
		request: async () => {
			const results = await getInterviews();
			return results.map((r) => new DbEntity(r.id ?? '', InterviewUtility.getApplicantName(r)));
		},
	},
	{
		title: 'Interview Slots',
		request: async () => {
			const results = await getInterviewSlots();
			return results.map((r) => new DbEntity(r.id ?? '', r.name ?? ''));
		},
	},
	{
		title: 'Organizations',
		request: async () => {
			const results = await getOrganizations(undefined);
			return results.map((r) => new DbEntity(r.id ?? '', r.name ?? ''));
		},
	},
	{
		title: 'Organization Settings',
		request: async () => {
			const results = await getOrganizationSettingss();
			return results.map((r) => new DbEntity(r.id ?? '', r.Organization?.name ?? ''));
		},
	},
	{
		title: 'Review Rubrics',
		request: async () => {
			const results = await getReviewRubrics();
			return results.map((r) => new DbEntity(r.id ?? '', r.name ?? ''));
		},
	},
	{
		title: 'Reviews',
		request: async () => {
			const results = await getReviews();
			return results.map((r) => new DbEntity(r.id ?? '', ReviewUtility.getApplicantName(r)));
		},
	},
	{
		title: 'Teams',
		request: async () => {
			const results = await getTeams();
			return results.map((r) => new DbEntity(r.id ?? '', r.name ?? ''));
		},
	},
	{
		title: 'Users',
		request: async () => {
			const results = await getUsers();
			return results.map((r) => new DbEntity(r.id ?? '', r.name ?? ''));
		},
	},
];

const DevOpsDashboard: React.FC = () => {
	return (
		<>
			<TravultContainer>
				<TopToolbar
					title="Dashboard"
					subtitle="Collection of widgets for high level status"
					buttons={[
						<Button variant="contained" color="secondary">
							Add Time Slots
						</Button>,
					]}
				/>
				<Grid container spacing={2}>
					{tablesList
						.sort((a, b) => a.title.localeCompare(b.title))
						.map((item) => (
							<Grid item sm={12} md={6} lg={3} key={item.title}>
								<ListAllWidget title={item.title} getRequest={item.request} />
							</Grid>
						))}
				</Grid>
			</TravultContainer>
		</>
	);
};

export { DevOpsDashboard };
