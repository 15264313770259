import { AddCircle } from '@mui/icons-material';
import {
	Autocomplete,
	CircularProgress,
	Grid,
	IconButton,
	Popover,
	TextField,
	Typography,
} from '@mui/material';
import { User } from 'API';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUsersByOrganization } from 'services/user_service';
import { GlobalState } from 'store/global_state';
import { OrganizationState } from 'store/sub-states/org_state';

interface AddUserSearchProps {
	handleSelectUser: (value: User | null) => void;
}

const AddUserSearch: React.FC<AddUserSearchProps> = ({ handleSelectUser }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [users, setUsers] = useState<User[]>([]);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const organization = useSelector<GlobalState, OrganizationState>((state) => state.organization);

	const handleClickAdd = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseAdd = () => {
		setAnchorEl(null);
	};

	const handleGetUsers = async () => {
		if (!organization.id) return;

		setLoadingUsers(true);
		const usersResponse = await getUsersByOrganization(organization.id);
		setUsers(usersResponse);
		setLoadingUsers(false);
	};

	return (
		<>
			<Grid container direction="row" alignItems="center">
				<Grid item>
					<IconButton onClick={handleClickAdd}>
						<AddCircle color="secondary" />
					</IconButton>
					<Popover
						open={Boolean(anchorEl)}
						onClose={handleCloseAdd}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}>
						<Autocomplete
							sx={{ width: 300, m: 2 }}
							loading={loadingUsers}
							options={users}
							getOptionLabel={(option) => option.name ?? 'Unknown'}
							onOpen={handleGetUsers}
							onChange={(_, value) => {
								handleSelectUser(value);
								handleCloseAdd();
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Users"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loadingUsers ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</Popover>
				</Grid>
				<Grid item>
					<Typography variant="subtitle1" color="text.secondary">
						Add people to this interview
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export { AddUserSearch };
