import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/global_state';
import { UserState } from 'store/sub-states/user_state';

const ApplicantDashboard = () => {
	const user = useSelector<GlobalState, UserState>((state) => state.user);

	return (
		<TravultContainer>
			<TopToolbar title="Dashboard" subtitle={`Welcome ${user.name ?? 'applicant'}!`} />
		</TravultContainer>
	);
};

export { ApplicantDashboard };
