export const getRoundedDate = (
  minutes: number, 
  date: Date, 
  roundingMethod: 'round' | 'floor' | 'ceil' = 'round') => {

  let ms = 1000 * 60 * minutes; // convert minutes to ms

  switch (roundingMethod) {
    case 'ceil':
      return new Date(Math.ceil(date.getTime() / ms) * ms);
    case 'floor':
      return new Date(Math.floor(date.getTime() / ms) * ms);
    default:
      return new Date(Math.round(date.getTime() / ms) * ms);
  }
}