import { GraphQLResult } from '@aws-amplify/api';
import { Team } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { TeamQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/

/**************************** RETRIEVE ****************************/
export const getTeam = async (id: string): Promise<Team> => {
	// api call
	const teamResponse = (await API.graphql(
		graphqlOperation(Queries.getTeam, { id: id })
	)) as GraphQLResult<TeamQueries.GetTeamQuery>;

	// parse response
	const team = parseGraphQLResponse(teamResponse).getTeam;

	return team;
};

export const getTeams = async (): Promise<Team[]> => {
	// api call
	const teamResponse = (await API.graphql(
		graphqlOperation(Queries.listTeams)
	)) as GraphQLResult<TeamQueries.ListTeamsQuery>;

	// parse response
	const teams = parseGraphQLResponse(teamResponse).listTeams.items;

	return teams;
};
/**************************** Update ****************************/

/**************************** DELETE ****************************/
export const deleteTeam = async (id: string): Promise<Team | undefined> => {
	// api call
	const teamResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteTeam, { input: { id: id } })
	)) as GraphQLResult<TeamQueries.DeleteTeamMutation>;

	// parse response
	const team = parseGraphQLResponse(teamResponse).deleteTeam;

	return team;
};
