import { Container, Typography } from '@mui/material';
import { FaqsList } from 'assets/faq-list';
import { CollapsibleQuestion } from 'components/general/collapsible_question';
import { PageLayout } from 'components/layout/page_layout';
import React from 'react';

const FaqPage: React.FC = () => {
	return (
		<>
			<PageLayout>
				<Typography variant="h3" color="textPrimary" sx={{ m: 5 }}>
					Frequently Asked Questions
				</Typography>
				<Container>
					{FaqsList.map((qa) => (
						<CollapsibleQuestion key={qa.question} questionAnswer={qa} />
					))}
				</Container>
			</PageLayout>
		</>
	);
};

export { FaqPage };
