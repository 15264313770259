import { ActionStrings } from 'actions/action_strings';
import { DefaultAction } from 'actions/action_types';
import { InitialOrganizationState } from 'store/initial_state';
import { OrganizationState } from 'store/sub-states/org_state';

export const OrganizationReducer = (
	state = InitialOrganizationState,
	action: DefaultAction
): OrganizationState => {
	switch (action.type) {
		case ActionStrings.Organization.SetApplicationRubric:
			return { ...state, applicationRubric: action.payload };
		case ActionStrings.Organization.SetInterviewRubric:
			return { ...state, interviewRubric: action.payload };
		case ActionStrings.Organization.SetReviewRubric:
			return { ...state, reviewRubric: action.payload };
		case ActionStrings.Organization.SetOrganizationSettings:
			return { ...state, organizationSettings: action.payload };
		case ActionStrings.Organization.SetOrganization:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};
