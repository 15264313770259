import { GraphQLResult } from '@aws-amplify/api';
import { OrganizationSettings } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import * as Mutations from 'graphql/mutations';
import * as Queries from 'graphql/queries';
import { OrganizationSettingsQueries } from 'types/api_types';
import { parseGraphQLResponse } from 'utils/api_utility';

/**************************** CREATE ****************************/

/**************************** RETRIEVE ****************************/
export const getOrganizationSettings = async (id: string): Promise<OrganizationSettings> => {
	// api call
	const organizationSettingsResponse = (await API.graphql(
		graphqlOperation(Queries.getOrganizationSettings, { id: id })
	)) as GraphQLResult<OrganizationSettingsQueries.GetOrganizationSettingsQuery>;

	// parse response
	const organizationSettings = parseGraphQLResponse(
		organizationSettingsResponse
	).getOrganizationSettings;

	return organizationSettings;
};

export const getOrganizationSettingss = async (): Promise<OrganizationSettings[]> => {
	// api call
	const organizationSettingsResponse = (await API.graphql(
		graphqlOperation(Queries.listOrganizationSettingss)
	)) as GraphQLResult<OrganizationSettingsQueries.ListOrganizationSettingssQuery>;

	// parse response
	const organizationSettingss = parseGraphQLResponse(organizationSettingsResponse)
		.listOrganizationSettingss.items;

	return organizationSettingss;
};
/**************************** Update ****************************/

/**************************** DELETE ****************************/
export const deleteOrganizationSettings = async (
	id: string
): Promise<OrganizationSettings | undefined> => {
	// api call
	const organizationSettingsResponse = (await API.graphql(
		graphqlOperation(Mutations.deleteOrganizationSettings, { input: { id: id } })
	)) as GraphQLResult<OrganizationSettingsQueries.DeleteOrganizationSettingsMutation>;

	// parse response
	const organizationSettings = parseGraphQLResponse(
		organizationSettingsResponse
	).deleteOrganizationSettings;

	return organizationSettings;
};
