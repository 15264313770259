import { Paper, Typography } from '@mui/material';
import { Actions } from 'actions';
import { InterviewsList } from 'components/interviews/interviews_list';
import { InterviewsListItem } from 'components/interviews/interviews_list_item';
import { TopToolbar } from 'components/layout/top_toolbar';
import { TravultContainer } from 'components/layout/travult_container';
import { Loading } from 'components/loading/loading';
import { isAfter } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInterviewSlots } from 'services/interview_slot_service';
import { GlobalState } from 'store/global_state';
import { ApplicationState } from 'store/sub-states/application_state';
import { InterviewsState } from 'store/sub-states/interviews_state';
import { UserState } from 'store/sub-states/user_state';

const InterviewPage: React.FC = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const interviewState = useSelector<GlobalState, InterviewsState>((state) => state.interviews);
	const application = useSelector<GlobalState, ApplicationState>((state) => state.application);
	const user = useSelector<GlobalState, UserState>((state) => state.user);

	useEffect(() => {
		const loadInterviewSlots = async () => {
			setLoading(true);
			const currentTime = new Date();
			const slots = (await getInterviewSlots()).filter((slot) =>
				isAfter(new Date(slot.startTime ?? new Date()), currentTime)
			);
			dispatch(Actions.Interviews.SetInterviewSlots(slots));
			setLoading(false);
		};

		loadInterviewSlots();
	}, [dispatch, user]);

	return (
		<>
			<TravultContainer>
				<TopToolbar title="Interviews" subtitle="Manage your interviews" />
				{application.id && application.submitted ? (
					<Loading loading={loading}>
						{(user.interviewslots?.items?.length ?? 0) > 0 ? (
							<>
								<Typography variant="h3" color="textPrimary" sx={{ mb: 1 }}>
									Upcoming Interview
								</Typography>
								<Paper sx={{ p: 2 }}>
									<InterviewsListItem
										interview={interviewState.slots.find(
											(int) => int.id === user.interviewslots?.items![0]?.interviewslotID
										)}
										user={user}
									/>
								</Paper>
							</>
						) : (
							<InterviewsList interviews={interviewState.slots} user={user} />
						)}
					</Loading>
				) : (
					<Typography variant="h5" color="error">
						You must submit your application before you can sign up for an interview time slot.
					</Typography>
				)}
			</TravultContainer>
		</>
	);
};

export { InterviewPage };
