import { Grid } from '@mui/material';
import React from 'react';

const TravultContainer: React.FC = ({ children }) => {
	return (
		<Grid container direction="column" alignItems="center">
			<Grid item style={{ width: '95%' }}>
				{children}
			</Grid>
		</Grid>
	);
};

export { TravultContainer };
